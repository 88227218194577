import store from 'store/index'
import { setPageModal, setPageModalData } from "store/modules/page"

const generateUiButtonUrl = (props) => {
    const {to, id, subId} = props ?? {}
    if (to === "EXTERNAL_URL") 
        return id
    if (to === "EVENT_TICKETS") 
        return `/events/${id}/tickets`
    if (to === "EVENT_DETAILS")
        return `/events/${id}/details`
    if (to === "SPEAKER_DETAILS")
        return `/speaker/${id}/details`
    if (to === "SESSION_DETAILS" || to === "SESSION_LIVE")
        return `/events/${id}/stream/${subId}`
    if (to === "SESSION_RECORDING")
        return `/events/${id}/replay/${subId}` 
    if (to === "EVENT_AGENDA")
    return `/events/${id}/agenda`
    if (to === "MY_TYCKETS")
        return `/bm/tickets/list`
    if (to === "CHECKIN")
        return `/dashboard`
    if (to === "SUMMARY_REEL")
        return `/events/${id}/reel`
    if (to === "ORDER_DETAILS")
        return `/bm/orders/form?id=${id}`
}

const generateUiButtonOpenModal = (type, props) => {
    const {id, subId, upgradeOptions} = props ?? {}
    
    let modalName = ""
    let modalData;
    if (type === "VIEW_TICKET_BADGE") {
        modalName = "ticketBadgeModal"
        let ticketDetails = store.getState()?.bm?.ticketDetails;
        modalData = ticketDetails
    }
    if (type === "TICKET_CONSUME") {
        modalName = "useTicketModal"; // ticket CTA
        modalData = {eventId: id, sessionId: subId}
    }
    if (type === "TICKET_CHECKIN") {
        modalName = "ticketCheckInModal"; // ticket CTA
        modalData = [id]
    }
    if (type === "TICKET_CHECIN") {
        modalName = "selectTicketCheckInModal"; // event CTA
        modalData = [id]
    }
    if (type === "TICKET_UPGRADE") {
        modalName = "ticketUpgradeModal" 
        modalData = {id: [id], upgradeOptions: upgradeOptions}
    }
    if (type === "TICKET_TRANSFER") {
        modalName = "ticketAssignModal"
        modalData = [id]
    }
    if (type === "TICKET_REVOKE") {
        modalName = "ticketRevokeModal"
        modalData = [id]
    }

    store.dispatch(setPageModalData(modalData))
    store.dispatch(setPageModal(modalName))
}

export const buttonProps = (action) => {
    let response = {
        low: action?.button?.imporance === "LOW",
        high: action?.button?.imporance === "HIGH",
        secondary: action?.button?.type === "SECONDARY",
        
        label: action?.button?.label,
        disabled: action?.button?.disabled,
        coins: action?.button?.coins
    }

    //todo left icon

    if (action?.button?.type ===  "WARNING") response.special = "warning" 
    if (action?.button?.type ===  "SUCCESS") response.special = "success" 
    if (action?.button?.type ===  "DANGER") response.special = "danger" 
    if (action?.button?.type ===  "YELLOW") response.special = "yellow" 

    if (action?.type === "NAVIGATE_NEW_TAB")
        response.dldUrl = generateUiButtonUrl(action?.data)
    if (action?.type === "NAVIGATE")
        response.href = generateUiButtonUrl(action?.data)
    else response.onClick = () => generateUiButtonOpenModal(action?.type, action?.data)

    if (action?.type === "TICKET_TRANSFER")
        response.leftIcon = "sendemail"
    return response

}