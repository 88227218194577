// modules/websocket.js 

export const wsConnect = host => ({ type: 'WS_CONNECT', host });
export const wsConnecting = host => ({ type: 'WS_CONNECTING', host });
export const wsConnected = host => ({ type: 'WS_CONNECTED', host });
export const wsDisconnect = host => ({ type: 'WS_DISCONNECT', host });
export const wsDisconnected = host => ({ type: 'WS_DISCONNECTED', host });
export const wsNewMessage = payload => ({ type: 'NEW_MESSAGE', payload });
export const wsSendData = payload => ({ type: 'WS_SEND_DATA', payload });
export const sendLiveMessage = payload => ({ type: 'WS_SEND_DATA', payload });
export const setInteraction = payload => ({ type: 'WS_SET_INTERACTION', payload });
export const setViolation = payload => ({ type: 'WS_SET_VIOLATION', payload });

// export const joinRoom = (roomId, interaction, violation) => {
//     wsSendData({ action: 'JOIN_ROOM', data: { roomId } })
//     setInteraction({ action: 'ws/SET_INTERACTION', data: { interaction } })
//     setViolation({ action: 'ws/SET_VIOLATION', data: { violation } })
// };

//PK, SK >> from interaction
export const wsRespondToInteractoin = ({PK, SK, response})=> ({ type: 'WS_SEND_DATA', payload: {
    action: "SET_INTERACTION_RESPONSE",
    data: {PK, SK, response}
}});

const sessionId = "SES-session10001"

const initialState = {
    connectionId: null,
    isConnected: false,
    sessions: {
        [sessionId]: {
            interaction: {},
            violation: null
        },
    },
    pendingRequests: 0,
    userAlerts: [],
    newAlertCount: 0

    // rooms: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    // debugger
    switch (action.type) {
        case 'ws/SET_WS_IS_CONNECTED':
            return {
                ...state,
                isConnected: true
            }
        case 'ws/SET_WS_CONNECTION':
            return {
                ...state,
                connectionId: action.payload.connectionId
            }
            //the following were moved to chat.js in a new logic
        // case 'ws/SET_CHAT_ROOM_MESSAGES':
        //     return {
        //         ...state,
        //         rooms : {...state.rooms,
        //             [action.payload?.roomId] : {
        //                 ...(state.rooms?.[action.payload?.roomId] ?? {}),
        //                 messages: action.payload?.messages ?? [],
        //         }}
        //     };
        // case 'ws/UPDATE_CHAT_ROOMS':
        //     const rooms = state.rooms ?? {}
        //     action.payload.rooms.forEach(item => {
        //         if (rooms?.[item.PK]) rooms[item.PK] = {
        //             ...rooms[item.PK],
        //             ...item,
        //         } 
        //         else rooms[item.PK] = item
        //     });
        //     return {
        //         ...state,
        //         rooms,
        //     };
        // case 'ws/ADD_CHAT_ROOM':
        //     const item =action.payload
        //     if (rooms[item.PK]) rooms[item.PK] = {
        //         ...rooms[item.PK],
        //         ...item,
        //     } 
        //     else rooms.push(item)
        //     return {
        //         ...state,
        //         rooms,
        //     };
        // case 'ws/REMOVE_FROM_ROOM':
        //     return {
        //         ...state,
        //         rooms : state.rooms.map(item => item.PK !== action.payload.PK 
        //             ? item
        //             : {
        //                 ...item,
        //                 active: false
        //             }
        //             ),
        //     };
        case 'ws/SET_INTERACTION':
            return {
                ...state,
                sessions : {...state.sessions,
                    [action.payload?.PK] : {
                        ...(state.sessions?.[action.payload?.PK] ?? {}),
                        interaction: Object.keys(action.payload).length > 1 ? action.payload: undefined
                }}
            };
        case 'ws/ADD_MESSAGE':
            let messages = [...(state.sessions?.[action.payload?.roomId]?.messages ?? []), action.payload]
            // let messages = state.sessions?.[action.payload?.roomId]?.messages ?? []
            // messages.push(action.payload)
            return {
                ...state,
                sessions : {...state.sessions,
                    [action.payload?.roomId] : {
                        ...(state.sessions?.[action.payload?.roomId] ?? {}),
                        messages,
                }}
            };
        case 'ws/SET_MESSAGES':
            return {
                ...state,
                sessions : {...state.sessions,
                    [action.payload?.roomId] : {
                        ...(state.sessions?.[action.payload?.roomId] ?? {}),
                        messages: action.payload?.messages ?? [],
                }}
            };
        case 'ws/SET_ROOM_CONNECTIONS':
            return {
                ...state,
                sessions : {...state.sessions,
                    [action.payload?.roomId] : {
                        ...(state.sessions?.[action.payload?.roomId] ?? {}),
                        connections: action.payload?.connections ?? [],
                }}
            };
        case 'ws/SET_SESION_STATE':
            return {
                ...state,
                sessions : {...state.sessions,
                    [action.payload?.roomId] : {
                        ...(state.sessions?.[action.payload?.roomId] ?? {}),
                        state: action.payload?.state ?? {},
                }}
            };
        case 'ws/SET_PENDING_REQUESTS':
            return {
                ...state,
                pendingRequests: action.payload.pendingRequests
            };
        case 'ws/SET_USER_ALERTS':
            return {
                ...state,
                newAlertCount: action?.payload?.userAlerts?.newAlertCount ?? 0,
                userAlerts: action?.payload?.userAlerts?.alerts
            };
        case 'ws/UPDATE_ALERT': 
            // debugger
            let updatedCount = --state.newAlertCount;
            let updatedUserAlers = state.userAlerts.map(alert => {if(alert.SK === action?.payload.userNotif) { alert.status = "READ"} return alert});
            return {
                ...state,
                newAlertCount: updatedCount,
                userAlerts: updatedUserAlers
            }
        default:
            return state;
    }
}

export const localUpdateUserAlert = (data) => ({
    type: "ws/UPDATE_ALERT",
    payload: data
})
