import { apiAction } from "store/middleware/api"
import { setUserProfile, setUserSection, setUserStatsPendingRequests, SET_USER_INTERESTS } from 'store/modules/user'
import store from 'store/index'
import { setSpeakers, setSpeakerProfile, setConnections, setMembers, setRequests } from "store/modules/bm"
import { setPageModal } from "store/modules/page"
import { setSelectedRoom } from "store/modules/chat"
import { resetMemberProfile, setMemberProfile, SET_MEMBER_INTERESTS } from "store/modules/selectedMember"
import { SET_ITEMS } from "store/modules/dataView"
import { getUserSection } from "./apiActions"
import { loadViewData } from "store/modules/dataView/dataView-dataLoader"
import { history } from "utils/history"

export const getMySpeakers = () => {
    return apiAction({
        endPoint: "/speaker/myspeakers",
        method: "POST",
        label: "followedSpeakers",
        onSuccess: (data, dispatch) => {
            console.log("getMySpeakers", data)

            return setSpeakers(data?.profile)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getAllSpeakers = () => {
    return apiAction({
        endPoint: "/general/search",
        method: "POST",
        data: { "iType": "SPEAKER", "from": 0, "size": 25, "searchTerm": "" },
        label: "allSpeakers",
        onSuccess: (data, dispatch) => {
            console.log("getAllSpeakers", data)

            return setSpeakers(data?.Items)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getSpeaker = (speakerId) => {
    return apiAction({
        endPoint: `/speaker/profile?id=${speakerId}`,
        method: "GET",
        onSuccess: (data, dispatch) => {
            console.log(`getSpeaker: ${speakerId}`, data)

            return setSpeakerProfile(data?.profile)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const followSpeaker = (speakerId) => {
    return apiAction({
        endPoint: `/speaker/follow`,
        method: "POST",
        data: { id: speakerId, follow: true },
        onSuccess: (data, dispatch) => {
            console.log(`followSpeaker: ${speakerId}`, data)

            return getSpeaker(speakerId)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const unfollowSpeaker = (speakerId) => {
    return apiAction({
        endPoint: `/speaker/follow`,
        method: "POST",
        data: { id: speakerId, follow: false },
        onSuccess: (data, dispatch) => {
            console.log(`unfollowSpeaker: ${speakerId}`, data)

            return getSpeaker(speakerId)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getMyConnections = (props) => {
    const {dataView=false} = props ?? {}

    return apiAction({
        endPoint: "/user/network/connections",
        method: "POST",
        onSuccess: (data, dispatch) => {
            console.log("getMyConnections", data)

            if (!dataView) return setConnections(data?.profile?.connections)
            return {
                type: SET_ITEMS,
                payload: {
                  items: data?.profile?.connections,
                },
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getMyRequests = () => {
    return apiAction({
        endPoint: "/user/network/requests",
        method: "POST",
        label: "myRequests",
        onSuccess: (data, dispatch) => {
            console.log("getMyRequests", data)
            dispatch(setUserStatsPendingRequests(
                (data?.items?.incomingPendingRequests?.length ?? 0) + 
                (data?.items?.pendingRequests?.length ?? 0)
                ))
            return setRequests({
                deniedRequests: data?.items?.deniedRequests,
                pendingRequests: data?.items?.pendingRequests,
                incomingDeniedRequests: data?.items?.incomingDeniedRequests,
                incomingPendingRequests: data?.items?.incomingPendingRequests
            })
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getAllMembers = () => {
    const searchTerm = store.getState().dataView?.searchString ?? ""

    return apiAction({
        endPoint: "/general/search",
        method: "POST",
        data: { "iType": "USER_PROFILE", "from": 0, "size": 400, searchTerm},
        onSuccess: (data, dispatch) => {
            console.log("getAllMembers", data)

            return setMembers(data?.Items)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getMember = (pk) => {
    return apiAction({
        endPoint: `/user/profile?userId=${pk}&asMember=true&fullProfile=true`,
        method: "GET",
        onSuccess: (data, dispatch) => {
            console.log(`getMember: ${pk}`, data)

            dispatch({
                type: SET_MEMBER_INTERESTS,
                payload: data?.profile?.interests_v3,
            })
            const profile = { ...data?.profile, quote: data?.quote }
            return setMemberProfile(profile)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const saveNetworkRequestActionFromMemberCard = (action, memberPk, currentPage) => {
    return apiAction({
        endPoint: "/user/network/request",
        method: "POST",
        data: { action: action, contact: memberPk },
        label: `networkRequestAction_${action}_${memberPk}`,
        onSuccess: (data, dispatch) => {
            console.log(`saveNetworkRequestActionFromMemberCard: ${action} to: ${memberPk}`, data)

            switch (currentPage) {
                case "MEMBERS_PAGE":
                    return loadViewData({reload:true})
                case "MY_CONNECTIONS_PAGE":
                    return getMyConnections()
                case "REQUESTS_PAGE":
                    return getMyRequests()
                default:
                    return
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const saveNetworkRequestActionFromMemberProfile = (action, memberPk) => {
    return apiAction({
        endPoint: "/user/network/request",
        method: "POST",
        data: { action: action, contact: memberPk },
        label: `networkRequestAction_${memberPk}`,
        onSuccess: (data, dispatch) => {
            console.log(`saveNetworkRequestActionFromMemberProfile: ${action} to: ${memberPk}`, data)

            return getMember(memberPk)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const leaveChat = () => {
    const roomPk = store.getState().chat?.selectedRoom
    if (!roomPk) return { type: "a" }

    return apiAction({
        endPoint: "/chat/room/update",
        method: "POST",
        data: {
            roomPk,
            action: "LEAVE",
        },
        label: `leaveChat`,
        onSuccess: (data, dispatch) => {
            console.log(`leaveChat: ${roomPk}`, data)
            // const rooms = store.getState().chat?.rooms
            // dispatch(setSelectedRoom(rooms?.length > 0 ? rooms[0] : {}))

            return setPageModal()
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const addParticipant = (userPK) => {
    const roomPk = store.getState().chat?.selectedRoom
    if (!roomPk) return { type: "a" }

    return apiAction({
        endPoint: "/chat/room/update",
        method: "POST",
        data: {
            roomPk,
            PK: userPK,
            action: "ADD_PARTICIPANT"
        },
        onSuccess: (data, dispatch) => {
            console.log(`addParticipant: ${userPK} to room: ${roomPk}`, data)

            return { type: "a" }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const removeParticipant = (userPK) => {
    const roomPk = store.getState().chat?.selectedRoom
    if (!roomPk) return { type: "a" }

    return apiAction({
        endPoint: "/chat/room/update",
        method: "POST",
        data: {
            roomPk,
            PK: userPK,
            action: "REMOVE_PARTICIPANT"
        },
        onSuccess: (data, dispatch) => {
            console.log(`removeParticipant: ${userPK} from room: ${roomPk}`, data)

            return { type: "a" }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const addAdmin = (userPK) => {
    const roomPk = store.getState().chat?.selectedRoom
    if (!roomPk) return { type: "a" }

    return apiAction({
        endPoint: "/chat/room/update",
        method: "POST",
        data: {
            roomPk,
            PK: userPK,
            action: "ADD_ADMIN"
        },
        onSuccess: (data, dispatch) => {
            console.log(`addAdmin: ${userPK} to room: ${roomPk}`, data)

            return { type: "a" }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}


export const removeAdmin = (userPK) => {
    const roomPk = store.getState().chat?.selectedRoom
    if (!roomPk) return { type: "a" }

    return apiAction({
        endPoint: "/chat/room/update",
        method: "POST",
        data: {
            roomPk,
            PK: userPK,
            action: "REMOVE_ADMIN"
        },
        onSuccess: (data, dispatch) => {
            console.log(`removeAdmin: ${userPK} from room: ${roomPk}`, data)

            return { type: "a" }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const updateChatName = (name) => genericRoomUpdate({
    params: { name },
    action: "UPDATE_NAME",
    onSuccess: (data, dispatch) => {
        console.log(`updateChatName: with: ${name}`, data)
        dispatch(setSelectedRoom({...{name: name}}))

        return dispatch(setPageModal())
    },
    onFailure: (e, dispatch) => {
        console.log("error occured custom")
        console.log(e)
        return {
            type: "a"
        }
    }
})


// {
//     const roomPk = store.getState().chat?.selectedRoom
//     if(!roomPk) return {type:"a"}

//     return apiAction({
//         endPoint: "/chat/room/update",
//         method: "POST",
//         data: {
//                 roomPk: roomPk,
//                 name: name,
//                 action: "UPDATE_NAME"},
//         onSuccess: (data, dispatch) => {
//             console.log(`updateChatName: ${roomPk} with: ${name}`, data)

//             // return dispatch(setPageModal())
//             return setPageModal()
//         },
//         onFailure: (e, dispatch) => {
//             console.log("error occured custom")
//             console.log(e)
//             return {
//                 type: "a"
//             }
//         }
//     })
// }

const genericRoomUpdate = ({ onSuccess, action, params }) => {
    const roomPk = store.getState().chat?.selectedRoom
    if (!roomPk) return { type: "a" }

    return apiAction({
        endPoint: "/chat/room/update",
        method: "POST",
        data: {
            roomPk,
            action,
            ...params,
        },
        onSuccess: (data, dispatch) => {
            if (onSuccess) onSuccess(data, dispatch)
            return {
                type: "a"
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const chatWithUser = PK => {
    const userPk = store.getState()?.user?.profile?.PK
    let participants = [
        { PK},
        { PK: userPk},
    ]

    return apiAction({
        endPoint: `/chat/room/create`,
        method: "POST",
        data: {
            participants,
        },
        onSuccess: (data, dispatch) => {
            history.push('/network/messages')
            return setSelectedRoom(data?.roomPk)
        },
        onFailure: () => {
            console.log("error in processing the order");
            return { type: "a" };
        }
    });
}
export const createChatRoom = (inputParticipants, name) => {
    let participants = inputParticipants.map(item => ({
        PK: item.PK
    }))
    const userPk = store.getState()?.user?.profile?.PK
    participants.push({   // add yourself to chat
        PK: userPk
    })
    return apiAction({
        endPoint: `/chat/room/create`,
        method: "POST",
        data: {
            participants,
            name,
        },
        onSuccess: (data, dispatch) => {
            console.log(`createChatRoom with inputParticipants: ${inputParticipants}, name: ${name}`, data)
            dispatch(setSelectedRoom(data?.roomPk))
            return setPageModal();
        },
        onFailure: () => {
            console.log("error in processing the order");
            return { type: "a" };
        }
    });
}

export const saveClap = (receiverPk, receiverItemSk, itemsToReload) => {
    console.log(`saveClap receiverPk: ${receiverPk}, receiverItemSk: ${receiverItemSk}, itemsToReload: ${itemsToReload}`)
    let id
	try {
		id = new URLSearchParams(window.location.search)?.get("id")
	} catch { }
    return apiAction({
        endPoint: `/user/network/clap`,
        method: "POST",
        data: {
            receiverPk,
            receiverItemSk,
        },
        onSuccess: (data, dispatch) => {
            switch(itemsToReload)
            {
                case "MEMBER_QUOTE":
                    return getMember(id)
                case "MEMBER_EXPERIENCE":
                    return getUserSection("achievement")
                case "MEMBER_EDUCATION":
                    return getUserSection("education")
                case "MEMBER_BOOKS":
                    return getUserSection("books")
                case "SPEAKER":
                    return getSpeaker(id)
                default:
                   return { type: "a" }
            }
        },
        onFailure: () => {
            console.log("error in processing the order");
            return { type: "a" };
        }
    });
}


export const setAlertStatus = (data) =>
    apiAction({
        endPoint: "/user/network/alert/status",
        method: "POST",
        data: data,
        onSuccess: (data, dispatch) => {
            console.log("checkUser", data)
            // const status = data?.status

            // if (status === "success") {
            //     toastr.error(["User already exists!"]);
            //     dispatch(setAllFieldErrors({ email: "User already exists" }))
            // }
            // else {
            //     dispatch(setCrtFormItem(userData))
            //     dispatch(setWizzardStep("comapnyDetails"))
            //     dispatch(setAllFieldErrors({}))
            // }
            return {
                type: "a",
                payload: data
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return { type: "a" }
        }
    })