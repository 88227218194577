import SvgIcon from 'componentsBM/SvgIcon'
import Select from 'react-select'
import { Col } from "./Grid";
import { useController } from "react-hook-form";


const CustomSelect = (props) => {
  const {control} = props;
  if (control) return <CustomSelectIntegrated {...props}/>
  return <CustomSelectBase {...props}/>
}

const CustomSelectIntegrated = (props) => {
  const {control, name} = props;
  const error = control?.formStateRef?.current?.dirtyFields?.country === false
  const {
    field: {value, onChange, onBlur},
    fieldState, //{ invalid, isTouched, isDirty },
    // formState, // { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    defaultValue: '',
  });
  
  return <CustomSelectBase {...{...props, value, onChange, onBlur,error, ...fieldState}}/>
}
export default CustomSelect;


const CustomSelectBase = props => {
  const { mainLabel = "label", dark, data, error, className, label, required, onChange, selected, iconLeft, placeholder = "Selecteaza...", disabled, setOption, invalid, isTouched, isDirty, selectError, setSelectError, errorText, ...rest } = props
  const options = data
    ? data.map(o => { return { value: o.id, label: o.label } })
    : []
  let mainClass = "custom-select-brand";
  if (className) mainClass += ` ${className}`;
  if (disabled) mainClass += " disabled";
  if (dark) mainClass += " dark";
  if (selectError) mainClass += " error";
  if (error) mainClass += " error";

  const isCol = rest.xs || rest.sm || rest.md || rest.lg || rest.xl

  const _onChange = e => {
    onChange()
    if (setOption !== undefined && setSelectError !== undefined) {
      setOption(e[mainLabel])
      setSelectError(false)
    }
  }

  const nakedSelect =
  <div className={mainClass}>
      {label && <div className={'label'}>{`${selectError ? errorText : label}${required ? ' *' : ''}`}</div>}
      {error && <div className={'label'}>{error}</div>}
    <div className={`wrapper ${iconLeft ? 'with-icon' : ''}`}>
      {iconLeft && <SvgIcon name={iconLeft} />}
      <Select className='custom-select-bm'
        classNamePrefix="crs"
        components={{
          IndicatorSeparator: undefined,
          // DropdownIndicator: <SvgIcon name="down"/>
        }}
        {...rest}
          defaultValue={data?.find(item => item.id === selected)}
        options={options}
        // onChange={e => onChange ? _onChange(e.value) : setCountry(e.label)}
        onChange={e => _onChange(e)}
        placeholder={placeholder}
      />
    </div>
  </div>
  return isCol ? <Col {...rest}>{nakedSelect}</Col> : nakedSelect
}