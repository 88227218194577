import SideMenuBM from 'componentsBM/SideMenuBM';
import { isIOS }        from 'react-device-detect';
import { useSelector } from 'react-redux';
import HeaderV2           from 'views/dev/gyula/BrandMinds/components/HeaderV2';
import LeftMenu from 'views/dev/gyula/BrandMinds/components/LeftMenu';


// Data

export default function BrandMindsLayoutUnifiedwMenu({ children }) {
  const menuHidden = false
  const pageModal = useSelector((state) => state?.page?.modal)
  return (
    <div className={`brand-minds-app grid-special ${isIOS ? 'ios' : ''}`}>
      <div className="full-screen-modal-placeholder"/> 
      <HeaderV2 />
      <main className={pageModal ? 'modal-opened' : ''}>
        {/* used to create a modal portal (BmBigModal) */}
        <div className="main-modal-placeholder"/> 
        {children}
      </main>
      {/* <LeftMenu notFixed active hidden={menuHidden} /> */}
      <SideMenuBM />
    </div>
  );
}
