import { Link } from 'react-router-dom';
import SvgIcon from 'componentsBM/SvgIcon';
import Pill from './Pill';
import { useDispatch } from 'react-redux';
import { setPageModal } from 'store/modules/page';

export const InnerBtn = props => {
  
  const {pill,icoName,icoRight,label,extraText, children} = props;

  return(
    <div className={`${icoRight?'right':'left'}`}>
      { icoName && <span className={'ico'}>
        <SvgIcon name={icoName} />
      </span> }
      <span className={'label'}>{`${label} ${extraText ?? ''}`}</span>
      {pill && <Pill pill={pill} />}
      {props.children}
    </div>
  )
}

export const Btn = props => {
  const { variant,label,href,onClick,className,disabled, noMargins, dispatch:dispatchProp,
    w100, showModal} = props;
  const btnClassName = `btn btn-${variant??'plain'} ${className??''} ${disabled?'disabled':''} ${noMargins?'no-margins':''} ${w100?'w-100':''}`
  const dispatch = useDispatch()
  // const btnClassName = `btn btn-${variant?variant:'plain'} ${className??''} ${disabled?'disabled':''}`
  let computedOnClick = undefined;
  if (onClick) computedOnClick = onClick;
  if (showModal) computedOnClick = () => dispatch(setPageModal(showModal));
  if (dispatchProp) computedOnClick = () => dispatch(dispatchProp);

  let buttonProps = {disabled,
    className : btnClassName,
    onClick: !disabled
      ? e => {computedOnClick(); e.stopPropagation()}
      : undefined
    };

  return (
    <>
      {  href && 
        <Link className={btnClassName} to={href}>
          <InnerBtn {...props} />
        </Link>
      }
      { !href && 
        <button  {...buttonProps}>
          <InnerBtn {...props} />
        </button>
      }
    </>
  )
}
export default Btn;