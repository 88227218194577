import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import SvgIcon from "componentsBM/SvgIcon";

// Data
import { leftMenu } from "staticData/leftmenu";

const LeftMenu = props => {

  const { className,active, notFixed, hidden } = props
  const history = useHistory()
  const { topItems,bottomItems } = leftMenu

  if (hidden) return null

  const backButton = <button className={'navlink'} onClick={() => history.goBack()} >
  <SvgIcon name={'arrow-left'} />
  Back
</button>


  return (
    <nav className={`leftmenu ${className??''} ${!active?'closed':''} ${notFixed?'not-fixed':''}`}>
      <div className="top">

        {topItems?.map( (item, i) => linkButton(item, i))}
      </div>
      <div className="bottom">
        {bottomItems?.map( (item, i) => linkButton(item, i))}
      </div>
    </nav>
  )
}
export default LeftMenu;

const linkButton = (item, i) => <NavLink exact key={i} to={item.url} className={'navlink'}>
    <SvgIcon name={item.svg} />
    {item.name}
  </NavLink>

// [
//   /order/home      Orders
//     [order/details     orderDetails]
//   /event      Events    
//     [event/detials     Details]
//     [event/live        Live]
// ]