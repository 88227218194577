// modules/websocket.js 

// export const wsConnect = host => ({ type: 'WS_CONNECT', host });
// export const wsConnecting = host => ({ type: 'WS_CONNECTING', host });
// export const wsConnected = host => ({ type: 'WS_CONNECTED', host });
// export const wsDisconnect = host => ({ type: 'WS_DISCONNECT', host });
// export const wsDisconnected = host => ({ type: 'WS_DISCONNECTED', host });
// export const wsNewMessage = payload => ({ type: 'NEW_MESSAGE', payload });
// export const wsSendData = payload => ({ type: 'WS_SEND_DATA', payload });
// export const sendLiveMessage = payload => ({ type: 'WS_SEND_DATA', payload });
// export const setInteraction = payload => ({ type: 'WS_SET_INTERACTION', payload });
// export const setViolation = payload => ({ type: 'WS_SET_VIOLATION', payload });

// export const joinRoom = (roomId, interaction, violation) => {
//     wsSendData({ action: 'JOIN_ROOM', data: { roomId } })
//     setInteraction({ action: 'ws/SET_INTERACTION', data: { interaction } })
//     setViolation({ action: 'ws/SET_VIOLATION', data: { violation } })
// };

export const SET_SELECTED_ROOM = "chat/SET_SELECTED_ROOM";
export const SET_NEW_RROM_NAME = "chat/SET_NEW_RROM_NAME";


const initialState = {
    rooms: {},
    selectedRoom: {},
    participants: {},
    messages: {},    
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    let roomMessages = state.messages?.[action.payload?.roomId] ?? []
    let roomParticipants = state.participants?.[action.payload?.roomId] ?? []
    switch (action.type) {
        case 'ws/ADD_CHAT_MESSAGE':
            roomMessages.push(action.payload.message)
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [action.payload?.roomId] : roomMessages
                }
            }
        
        case 'ws/SET_ROOM_PARTICIPANTS':
            return{
                ...state,
                participants: {
                    ...state.participants,
                    [action.payload?.roomId] : action.payload.data
                }
            }
        case 'ws/UPDATE_CHAT_PARTICIPANT':
            const part = action.payload.participant
            return{
                ...state,
                participants: {
                    ...state.participants,
                    [action.payload?.roomId] : roomParticipants.map(item => item.id === part.id ? {...item, ...part} : item)
                }
            }
        case 'ws/UPDATE_CHAT_ROOM':
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    [action.payload?.roomId] : action.payload?.data
                }
            }
        case 'ws/ADD_CHAT_ROOM':
            return{
                ...state,
                rooms: {
                    ...state.rooms,
                    [action.payload?.roomId] : action.payload.data
                }
            }            
        case 'ws/SET_CHAT_ROOMS':
            return{
                ...state,
                rooms : action.payload,
            }            
        case 'ws/SET_CHAT_ROOM_MESSAGES':
            return{
                ...state,
                messages: {
                    ...state.messages,
                    [action.payload?.roomId] : action.payload.items
                }            
        }            
        case SET_SELECTED_ROOM:
            return {
                ...state,
                selectedRoom: action.payload,
            };
        case SET_NEW_RROM_NAME:
            return {
                ...state,
                newRoomName: action.payload,
            };
            //the following were moved to chat.js in a new logic
        // case 'ws/SET_CHAT_ROOM_MESSAGES':
        //     return {
        //         ...state,
        //         rooms : {...state.rooms,
        //             [action.payload?.roomId] : {
        //                 ...(state.rooms?.[action.payload?.roomId] ?? {}),
        //                 messages: action.payload?.messages ?? [],
        //         }}
        //     };
        // case 'ws/UPDATE_CHAT_ROOMS':
        //     const rooms = state.rooms ?? {}
        //     action.payload.rooms.forEach(item => {
        //         if (rooms?.[item.PK]) rooms[item.PK] = {
        //             ...rooms[item.PK],
        //             ...item,
        //         } 
        //         else rooms[item.PK] = item
        //     });
        //     return {
        //         ...state,
        //         rooms,
        //     };
        // case 'ws/ADD_CHAT_ROOM':
        //     const item =action.payload
        //     if (rooms[item.PK]) rooms[item.PK] = {
        //         ...rooms[item.PK],
        //         ...item,
        //     } 
        //     else rooms.push(item)
        //     return {
        //         ...state,
        //         rooms,
        //     };
        // case 'ws/REMOVE_FROM_ROOM':
        //     return {
        //         ...state,
        //         rooms : state.rooms.map(item => item.PK !== action.payload.PK 
        //             ? item
        //             : {
        //                 ...item,
        //                 active: false
        //             }
        //             ),
        //     };
        // case 'ws/SET_INTERACTION':
        //     return {
        //         ...state,
        //         sessions : {...state.sessions,
        //             [action.payload?.PK] : {
        //                 ...(state.sessions?.[action.payload?.PK] ?? {}),
        //                 interaction: action.payload
        //         }}
        //     };
        // case 'ws/ADD_MESSAGE':
        //     let messages = [...(state.sessions?.[action.payload?.roomId]?.messages ?? []), action.payload]
        //     // let messages = state.sessions?.[action.payload?.roomId]?.messages ?? []
        //     // messages.push(action.payload)
        //     return {
        //         ...state,
        //         sessions : {...state.sessions,
        //             [action.payload?.roomId] : {
        //                 ...(state.sessions?.[action.payload?.roomId] ?? {}),
        //                 messages,
        //         }}
        //     };
        // case 'ws/SET_MESSAGES':
        //     return {
        //         ...state,
        //         sessions : {...state.sessions,
        //             [action.payload?.roomId] : {
        //                 ...(state.sessions?.[action.payload?.roomId] ?? {}),
        //                 messages: action.payload?.messages ?? [],
        //         }}
        //     };
        // case 'ws/SET_ROOM_CONNECTIONS':
        //     return {
        //         ...state,
        //         sessions : {...state.sessions,
        //             [action.payload?.roomId] : {
        //                 ...(state.sessions?.[action.payload?.roomId] ?? {}),
        //                 connections: action.payload?.connections ?? [],
        //         }}
        //     };
        // case 'ws/SET_SESION_STATE':
        //     return {
        //         ...state,
        //         sessions : {...state.sessions,
        //             [action.payload?.roomId] : {
        //                 ...(state.sessions?.[action.payload?.roomId] ?? {}),
        //                 state: action.payload?.state ?? {},
        //         }}
        //     };
        default:
            return state;
    }
}

export const setSelectedRoom = (data) => ({
    type: SET_SELECTED_ROOM,
    payload: data,
  })
export const setNewRoomName = (data) => ({
    type: SET_NEW_RROM_NAME,
    payload: data,
  })
