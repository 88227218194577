import { isIOS }        from 'react-device-detect';
import HeaderV2           from 'views/dev/gyula/BrandMinds/components/HeaderV2';
import LeftMenu from 'views/dev/gyula/BrandMinds/components/LeftMenu';


// Data

export default function BrandMindsLayoutUnified({ children }) {
  const menuHidden = true
  return (
    <div className={`brand-minds-app grid-special ${isIOS ? 'ios' : ''}`}>
      <div className="full-screen-modal-placeholder"/> 
      <HeaderV2 />
      <LeftMenu notFixed active hidden={menuHidden} />
      <main>
        {/* used to create a modal portal (BmBigModal) */}
        <div className="main-modal-placeholder"/> 
        {children}
        </main>
    </div>
  );
}
