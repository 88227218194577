
export const Container = props =>{
  const {fluid,noGutters,className} = props;
  return <div className={`container${fluid ? '-fluid' : ''} ${noGutters?'no-gutters':''} ${className??''}`}>{props.children}</div>;
}

export const Row = props => <div className={'row'}>{props.children}</div>;

export const Col = props =>{
  const {xs,sm,md,lg,xl,className,column} = props;
  return(
    <div className={`${xs?`col-${xs}`:'col-12'} ${sm?`col-sm-${sm}`:''} ${md?`col-md-${md}`:''} ${lg?`col-lg-${lg}`:''} ${xl?`col-xl-${xl}`:''} ${className?`${className}`:''} ${column?'fcol':''}`}>
      {props.children}
    </div>
  )
}