import React from 'react'

function SimplePageWrapper(props) {
    const {name, className} = props
    const computedClassName = `page-wrap-${name} ${className ? className : ''}`
    //her is where we can insert meta data (react helmet)
    return (
        <div {...{className: computedClassName}}>
            {props.children}
        </div>
    )
}

export default SimplePageWrapper
