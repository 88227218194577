import MainButtons from "./MainButtons"
import SideMenuOpenPanel from "./SideMenuOpenPanel"
import './index.scss'
import { useSelector } from "react-redux"
import { useDispatch } from 'react-redux'
import { useEffect } from "react"
import { closeMenuPanel } from "store/justActions/menuInteractions"
import DeleteMyAccountModal from "./DeleteMyAccountModal"

function SideMenuBM() {
    const menuSidePanelOpen = useSelector(state => state.menu?.menuSidePanelOpen)
    const dispatch = useDispatch()
    const url = window.url

    useEffect(() => {
        menuSidePanelOpen && dispatch(closeMenuPanel())
    }, [url])
    
    return < >
            <MainButtons/>
            {menuSidePanelOpen && <SideMenuOpenPanel/>}

            <DeleteMyAccountModal/>
        </>
    
}

export default SideMenuBM
