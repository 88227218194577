import axios from "axios";
import { history } from "../../utils/history";
// import { logoutUser, LOGOUT_USER } from "../modules/auth";
import { toastr } from "react-redux-toastr";
// import { showLoading, hideLoading, resetLoading } from "react-redux-loading-bar";
import AWS from "aws-sdk"
import { logoutUser, SET_AUTHENTICATED } from "store/modules/auth";
import { setLoadingIndicator } from "store/modules/page";
///////
// axios.defaults.withCredentials = true;

export const API = "api/API";
const API_START = "api/API_START";
const API_END = "api/API_END";
const API_ERROR = "api/API_ERROR";
const ACCESS_DENIED = "api/ACCESS_DENIED";
const ON_FAILURE = "api/ON_FAILURE";
export const DYNAMO = 'sdk/DYNAMO'
export const NEW_DISPATCH = 'redux/DISPATCH'

export const SQL = 'sdk/SQL'

let url = process.env.REACT_APP_API_ENDPOINT;

////////// cancel token logic //////////
const cancelTokenSources = {}
const CancelToken = axios.CancelToken;

// interceptor
const axiosApiInstance = axios.create();

export function getRefreshToken() {

  const token = localStorage.getItem("refreshToken");
  if (token  !== "undefined" && token !== "null"){
      return token
  }
  return null
}

export async function refreshToken() {
  const rfToken = getRefreshToken();
  const url = process.env.REACT_APP_API_ENDPOINT;

   
  if (!rfToken) 
    throw new Error("refresh token not found");
  const response = await axiosApiInstance.post(`${url}/user/refreshToken`, JSON.stringify({refreshToken:rfToken}),{
    skipAuth: true
  });
  localStorage.setItem("refreshToken", response?.data?.refreshToken);
  localStorage.setItem("authToken", response?.data?.authToken);

  return response?.data?.authToken;
}

// Response interceptor for API calls >> code from internet
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
   
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    let access_token
    try{
      access_token = await refreshToken()
      originalRequest.headers['Authorization'] = 'Bearer ' + access_token;
    } catch(e) {
      console.log(e)
    }
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});


export const apiStart = label => setLoadingIndicator(label, true)

// ({
//   type: API_START,
//   payload: label
// });

export const apiEnd = label => setLoadingIndicator(label, false)
// ({
//   type: API_END,
//   payload: label
// });

export const apiError = error => ({
  type: API_ERROR,
  error
});

export const accessDenied = error => ({
  type: ACCESS_DENIED,
  error
});

export const onFailure = error => ({
  type: ON_FAILURE,
  error
});

// export const logOutUser = () => ({
//   type: LOGOUT_USER
// });

export const apiAction = ({
  endPoint = "",
  method = "GET",
  data = null,
  // headers = null,
  onSuccess = () => { },
  onFailure = () => { },
  extraHeaders = null,
  label = "",
  relativeUrl = false,
  baseUrl = null,
  qs = false,
}) => ({
  type: API,
  payload: {
    endPoint,
    method,
    data,
    onSuccess,
    onFailure,
    extraHeaders,
    label,
    relativeUrl,
    baseUrl,
    qs,
  }
});

export const dynamoAction = ({
  method = "QUERY",
  onSuccess = () => { },
  onFailure = () => { },
  parameters = null,
}) => ({
  type: DYNAMO,
  payload: {
    method,
    parameters,
    onSuccess,
    onFailure,
  }
});

export const sqlAction = ({
  onSuccess = () => { },
  onFailure = () => { },
  parameters = null,
}) => ({
  type: SQL,
  payload: {
    parameters,
    onSuccess,
    onFailure,
  }
});

export const reDispatch = (innerFunction) => ({
  type: NEW_DISPATCH,
  payload: innerFunction
});

const apiMiddleware = ({ getState, dispatch }) => next => action => {
  //console.log("API ACTION", action);
  
  next(action);
  if (action.type !== API && action.type !== DYNAMO && action.type !== SQL && action.type!== NEW_DISPATCH) {
    return;
  }
  if (action.type === NEW_DISPATCH) {
    const {next, onSuccess} = action.payload

    //console.log("test")
    dispatch(action.payload( dispatch))
  }

  if (action.type === API) {
    const {
      endPoint,
      method,
      data,
      accessToken,
      extraHeaders,
      onSuccess,
      onFailure: onFailureCustom,
      label,
      relativeUrl,
      baseUrl,
      qs,
    } = action.payload;
    // GET & DELETE  use params vs POST which may require data
    const dataOrParams = (["GET", "DELETE"].includes(method) || qs) ? "params" : "data";

    let updatedHeaders = {
      "Content-Type": "application/json",
      // "ApplicationType": "WEB"
      // 'Access-Control-Allow-Credentials':true
    }
    axiosApiInstance.defaults.headers.common["Content-Type"] = "application/json";
    // headers && headers.map(header => axios.defaults.headers.common[header.name] = header.value)
    // add token to req
    // const isToken = localStorage.getItem("user");
    // console.log({extraHeaders})
    // extraHeaders && extraHeaders.forEach(header =>
    //   axios.defaults.headers.common[header.name] = header.value
    //   )
    // let token = null;
    // Logic to get token from REDUX. 
    // const state = getState();
    // const { auth } = state;
    // let token = auth.token;
    //Logic to get token from local storage
    const token = localStorage.getItem("authToken");
    const state = getState();
    axiosApiInstance.defaults.headers.common["connection-id"] = state?.ws?.connectionId;
    //console.log("isToken", isToken);
    // if (isToken) {
    //   token = JSON.parse(isToken).IdToken;
    // }
    // console.log(token);

    if (token) updatedHeaders["Authorization"] = token;
    // if (token) axios.defaults.headers.common["Authorization"] = token;
    let cancelToken; 
    if (label) {
      dispatch(apiStart(label));
      // create and retrieving the cancel token
      cancelTokenSources[label]?.cancel(`HTTP request ${label} canceled`)
      cancelTokenSources[label] = CancelToken.source();
      cancelToken = cancelTokenSources[label].token;

    }
    //   Progress.show();
    // dispatch(showLoading());
    //   dispatch(show())
    
    if (!relativeUrl) {
      url = process.env.REACT_APP_API_ENDPOINT;
      url = baseUrl ? `${baseUrl}${endPoint}` : `${url}${endPoint}`;
    } else {
      url = `${endPoint}`
    }
    
    //console.log("getState", getState());
    // return function(dispatch) {
    return axiosApiInstance
      .request({
        url,
        method,
        // mode: 'no-cors',
        headers: updatedHeaders,
        [dataOrParams]: data,
        // withCredentials: true 
        cancelToken
      })
      .then(({ data, headers }) => { 
        // dispatch(hideLoading()); 
        dispatch(onSuccess(data, dispatch)) })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
        // dispatch(resetLoading())
        dispatch(onFailure(error, dispatch));
        if (typeof error?.response?.data === "string") {
          toastr.error(error.response.data);
        } 
        if (typeof error?.message === "string") {
          // toastr.error(error?.message);
        } 
        if (error?.response?.status === 401) {
          // if (error.response.status === 401 || error.response.status === 403) {
            dispatch(logoutUser());
            // dispatch({type:SET_AUTHENTICATED, payload:false})
            history.push("/login");
        }
        dispatch(apiError(error));
        if (onFailure) dispatch(onFailure(error, dispatch));
        if (onFailureCustom) {
          //onFailureCustom(error);
          dispatch(onFailureCustom(error, dispatch));
        }

        // if (error.response && error.response.status === 404) {
        //   dispatch(accessDenied(window.location.pathname));
        // }
      }
      })
      .finally(() => {
        if (label) {
          dispatch(apiEnd(label));
        }
      });
  }
  else if (action.type === DYNAMO) {
    const {
      method,
      parameters,
      onSuccess,
      onFailure
    } = action.payload;
    
    var dynamodb = new AWS.DynamoDB();
    if (method === "QUERY") {
      // dispatch(showLoading());
      console.log("getState", getState());
      return dynamodb.query(parameters).promise()
        .then(data => {
          var unmarshalled = data.Items.map(item => item = AWS.DynamoDB.Converter.unmarshall(item));
          var lastEvalKey = data.LastEvaluatedKey
          var returnData = { items: unmarshalled, lastEvalKey }
          // dispatch(hideLoading());
          dispatch(onSuccess(returnData, dispatch));
        })
        .catch(error  => {
          console.log("PRINT ERR", error, error.stack);
          // dispatch(resetLoading());
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
            toastr.error(String(error.response.data));
          }

          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              // dispatch(logoutUser());
              history.push("/login");
            }
          }
          dispatch(apiError(error));
          if (onFailure) dispatch(onFailure(error));
        });
    }
    else if (method === "PUT") {
      // dispatch(showLoading());
      // console.log("getState", getState());
      return dynamodb.putItem(parameters).promise()
        .then(data => {
          // dispatch(hideLoading());
          dispatch(onSuccess(data, dispatch));

        })
        .catch(error  => {
          console.log("PRINT ERR", error, error.stack);
          // dispatch(resetLoading());
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
            toastr.error(String(error.response.data));
          } 

          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              // dispatch(logoutUser());
              history.push("/login");
            }
          }
          dispatch(apiError(error));
          dispatch(onFailure(error));
        });
    }
    else if (method === "DELETE") {
      // dispatch(showLoading());
      // console.log("getState", getState());
      return dynamodb.deleteItem(parameters).promise()
        .then(data => {
          // dispatch(hideLoading());
          dispatch(onSuccess(data, dispatch));

        })
        .catch(error  => {
          console.log("PRINT ERR", error, error.stack);
          // dispatch(resetLoading());
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
            toastr.error(String(error.response.data));
          } 

          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              // dispatch(logoutUser());
              history.push("/login");
            }
          }
          dispatch(apiError(error));
          dispatch(onFailure(error));
        });
    }
    else if (method === "batch_write") {
      // dispatch(showLoading());
      // console.log("getState", getState());
      return dynamodb.batchWriteItem(parameters).promise()
        .then(data => {
          // dispatch(hideLoading());
          dispatch(onSuccess(data, dispatch));

        })
        .catch(error => {
          console.log("PRINT ERR", error, error.stack);
          // dispatch(resetLoading());
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
            toastr.error(String(error.response.data));
          } else toastr.error("API integration error...");

          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              // dispatch(logoutUser());
              history.push("/login");
            }
          }
          dispatch(apiError(error));
          dispatch(onFailure(error));
        });
    }

  }
  else if (action.type === SQL) {
    console.log("action")
    const {
      parameters,
      onSuccess,
      onFailure
    } = action.payload;
    
    var rds = new AWS.RDSDataService();
    var params = {
      resourceArn: 'arn:aws:rds:eu-central-1:582846778384:cluster:database-1', /* required */
      secretArn: 'arn:aws:secretsmanager:eu-central-1:582846778384:secret:database-1-admin-1Z10ci', /* required */ 
      sql: parameters, /* required */ // sql statement
      database: 'database-1'
    }
    // dispatch(showLoading());

    return rds.executeStatement(params).promise()
    .then(data => {
      // dispatch(hideLoading());
      dispatch(onSuccess(data, dispatch));

    })
    .catch(error => {
      console.log("PRINT ERR", error, error.stack);
      // dispatch(resetLoading());
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "string"
      ) {
        toastr.error(String(error.response.data));
      } else toastr.error("API integration error...");

      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          // dispatch(logoutUser());
          history.push("/login");
        }
      }
      dispatch(apiError(error));
      dispatch(onFailure(error));
    });

  }
};

export default apiMiddleware;
