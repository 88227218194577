import { apiAction, reDispatch } from "store/middleware/api";
import { setApiResponse } from "./auth";
import { toastr } from "react-redux-toastr"
import store from "../index";

const SET_ORDER = "shop/SET_ORDER"
const ADD_TO_CART = "shop/ADD_TO_CART"
const CLEAR_CART = "shop/CLEAR_CART"
const SET_SEARCH_STRING = "shop/SET_SEARCH_STRING"
const SET_CART_SEARCH_STRING = "shop/SET_CART_SEARCH_STRING"
export const SET_SEARCH_RESULTS = "shop/SET_SEARCH_RESULTS"
const SET_MENU_SEARCH_STRING = "shop/SET_MENU_SEARCH_STRING"
const TOGGLE_VIEW_TYPE = "shop/TOGGLE_VIEW_TYPE"
const SET_SHOW_PRICES = "shop/SET_SHOW_PRICES"
const SET_LISTS = "shop/SET_LISTS"
const SET_TICKETS = "shop/SET_TICKETS"
const SET_PAYMENT_INTENT = "shop/SET_PAYMENT_INTENT"
const SET_CART_DATA = "shop/SET_CART_DATA";
const SET_BILLING_PROFILE_ID = "shop/SET_BILLING_PROFILE_ID";
const SET_SELECTED_PAYMENT = "shop/SET_SELECTED_PAYMENT";
const CLEAR_CART_DATA = "shop/CLEAR_CART_DATA";
const SET_SHOP_KEY = "shop/SET_KEY";
const SET_CAN_PAY_WITH_STRIPE = "shop/SET_CAN_PAY_WITH_STRIPE";


const initialState = {
  cart : [],
  shoppingCart: {}, // latest version of shopping cart
  cartData: {},
  menuSeachString :'',
  cartSearchString: '',
  products: [],
  searchString: '',
  viewType: "cards",
  showPrices: ["wVat", "woVat"],
  lists:[],
  tickets: [],
  paymentIntent: {},
  order: {},
  billingProfileId: undefined,  
  selectedPayment: 'STRIPE',
  orderState: null,
  canPayWithStripe: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SHOP_KEY:
      return {
        ...state,
        [action.payload?.key]: action.payload?.value,
      };
    case 'ws/SET_ORDER_STATE':
      return {
        ...state,
        orderState: action.payload?.state,
        orderReason: action.payload?.reason,
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.payload
      }
      case SET_CART_DATA:
        return {
          ...state,
          cartData: {...action.payload}
        }
      case SET_SELECTED_PAYMENT:
        return {
          ...state,
          selectedPayment: action.payload
        }
      case SET_CAN_PAY_WITH_STRIPE:
          return {
            ...state,
            canPayWithStripe: action.payload
          }
      case SET_BILLING_PROFILE_ID:
        return {
          ...state,
          billingProfileId: action.payload
        }
      case SET_PAYMENT_INTENT:
        return {
          ...state,
          paymentIntent: {...action.payload}
        }
      case CLEAR_CART_DATA:
        return {
          ...state,
          cartData: {}
        }
      case ADD_TO_CART:
          let [cart, products] = addToCartArray(state.cart, state.products, action.payload)
          return {
              ...state,
              cart,
              products,
          };
    case CLEAR_CART:
      return {
        ...state,
        cart: [],
      };
      case SET_LISTS:
          return {
              ...state,
              lists: action.payload,
          };
      case SET_SEARCH_STRING:
          return {
              ...state,
              searchString: action.payload,
          };
      case SET_CART_SEARCH_STRING:
          return {
              ...state,
              cartSearchString: action.payload,
          };
      case SET_SHOW_PRICES:
          return {
              ...state,
              showPrices: action.payload,
          };
      case SET_SEARCH_RESULTS:
          return {
              ...state,
              menuSearchItems: action.payload?.items,
          };
      case SET_MENU_SEARCH_STRING:
          return {
              ...state,
              menuSeachString: action.payload,
          };
      case TOGGLE_VIEW_TYPE:
          return {
              ...state,
              viewType: state.viewType === "cards" ? "list" : "cards",
          };
      case 'shop/UPDATE_CART_ITEM_QUANTITY':
          return {
              ...state,
              shoppingCart: {
                ...state.shoppingCart,
                items : state.shoppingCart?.items?.map(item => item.pairId === action.payload.id 
                    ? ({...item, quantity: action.payload?.quantity})
                    : ({...item})
                  ) ?? []
              }
              
          };
    case SET_TICKETS:
      return {
        ...state,
        tickets: action.payload,
      };
      default:
          return state;
  }
}

export const setShoppingCart = (data) => ({
  type: SET_SHOP_KEY,
  payload: {
    key: 'shoppingCart',
    value: data,
  },
})
export const setOrderState = (data) => ({
  type: SET_SHOP_KEY,
  payload: {
    key: 'orderState',
    value: data,
  },
})

export const setLists = (payload) => {
  return {
      payload,
      type: SET_LISTS,
  };
}
export const setBillingProfileId = (payload) => {
  return {
      payload,
      type: SET_BILLING_PROFILE_ID,
  };
}
export const setSelectedPayment = (payload) => {
  return {
      payload,
      type: SET_SELECTED_PAYMENT,
  };
}

//LOCAL STATE ADD TO CART
// export const addToCart = (product, quantity) => {
//   return {
//       payload: {
//         ...product,
//         quantity: isNaN(quantity) ? 0 : quantity,
//       },
//       type: ADD_TO_CART,
//   };
// }

// BE State add to cart
export const addToCart = (product, quantity, cartId) => {

  return apiAction({
    endPoint: `/shop2/cart`,
    method: "POST",
    data: {
      pairId: product.id,
      action: "add",
      cartId
    },
    label: "editCart",
    onSuccess: (data, dispatch) => {
      if (data?.status === "error") {
        toastr.error(["Ticket couldn't be added in the cart"])

        return {type: "a"}
      }
      else return setShoppingCart(data);
    },
    onFailure: () => {
      console.log("error in getting the shopping cart");
      toastr.error(["Ticket couldn't be added in the cart"])
      return {type: "a"}
    }
  });
}

export const removeFromCart = (product, quantity, cartId) => {
  return apiAction({
    endPoint: `/shop2/cart`,
    method: "POST",
    data: {
      pairId: product.id,
      action: "remove",
      cartId
    },
    label: "editCart",
    onSuccess: (data, dispatch) => {
      if (data?.status === "error") {
        toastr.error(["Ticket couldn't be removed from the cart"])
        return {type: "a"};
      }
      else return setShoppingCart(data);
    },
    onFailure: () => {
      console.log("error removing from the shopping cart");
      toastr.error(["Ticket couldn't be removed from the cart"])
      return {type: "a"};
    }
  });
}

export const removeUpgradeFromCart = (ticketId, cartId) => {
  return apiAction({
    endPoint: `/shop2/cart/upgrade-ticket`,
    method: "POST",
    data: {
      ticketId,
      action: "remove",
      cartId
    },
    label: "editCart",
    onSuccess: (data, dispatch) => {
      if (data?.status === "error") {
        toastr.error(["Upgrade couldn't be removed from the cart"])
        return {type: "a"};
      }
      else return setShoppingCart(data);
    },
    onFailure: () => {
      console.log("error removing from the shopping cart");
      toastr.error(["Upgrade couldn't be removed from the cart"])
      return {type: "a"};
    }
  });
}

export const clearCart = (payload) => {
  return {
    payload,
    type: CLEAR_CART,
  };
}

export const toggleViewType = () => {
  return {
      type: TOGGLE_VIEW_TYPE,
  };
}
export const setShowPrices = (data) => {
  return {
      type: SET_SHOW_PRICES,
      payload: data,
  };
}


export const setProductsSearchString = (string) => {
  return {
      payload: string,
      type: SET_SEARCH_STRING,
  };
}

export const setCartSearchString = (string) => {
  return {
      payload: string,
      type: SET_CART_SEARCH_STRING,
  };
}


function addToCartArray (cart, listedProducts, product) {
  let isInTheCart = false;
  let newCart = [...cart]
  let newListedProducts = [...listedProducts]
  newCart.forEach((cartObject, index) => {
    if (cartObject.id === product.id) {
      cartObject.quantity = product.quantity;
      isInTheCart = index;
    }
  });
  if (isInTheCart === false) newCart.push(product)
  if (product?.quantity === 0 && isInTheCart !== false) newCart.splice(isInTheCart, 1)

  //after each add we will always refresh the lsitedProducts array
  newListedProducts.forEach((listPrObject) => {    
      if (listPrObject.id === product.id)
        listPrObject.quantity = product.quantity;
    });
  return [newCart, newListedProducts]
}

//we usually just name the variables what they are, WH type
// you can use find to find stuff in arrays
// update the product list by using the new item that caused the modificaition. minus one for loop
// reafactor with arrrow functions



export const setMenuSearchItems = (items) => {
  //make api call with string
  //get result from API 
  //searchDATA = API result
  return {
      payload: {items},
      type: SET_SEARCH_RESULTS,
  };
}
export const clearMenuSearchItems = () => {
  //make api call with string
  //get result from API 
  //searchDATA = API result
  return {
      payload: {items: []},
      type: SET_SEARCH_RESULTS,
  };
}


export const SetCartData = (cartData) => ({
  payload: cartData,
  type: SET_CART_DATA,
});

export const saveProduct = (data) => apiAction({
  endPoint: `/product`,
  method: "POST",
  data,
  onSuccess: (data, dispatch) => {
    dispatch( setApiResponse(data))
    return {
      type: 'a',
    };
  },
  onFailure: () => {
    console.log("error occured custom");
    return {
      type: "a"
    };
  }
});

export const modifyBasket = (id, quantity) => apiAction({
  endPoint: `/basket/modify-basket-item`,
  method: "POST",
  data : {
    goodsId: id,
    quantity: quantity,
  },
  onSuccess: (data, dispatch) => {
    dispatch(SetCartData(data));
    return {
      type: 'a',
    };
  },
  onFailure: () => {
    console.log("error in modifyBasket()");
    return {
      type: "a"
    };
  }
});

export const getCart = (data) => apiAction({
  endPoint: `/basket/my-basket`,
  method: "GET",
  data,
  onSuccess: (data, dispatch) => {
    dispatch(setApiResponse(data));
    dispatch(SetCartData(data));
    return {
      type: 'a',
    };
  },
  onFailure: () => {
    console.log("error occured custom");
    return {
      type: "a"
    };
  }
});
export const placeOrder = () => { 
  // let state = store.getState();

  return apiAction({
  endPoint: `/basket/finalization`,
  method: "POST",
  // data: state?.shop?.CartData,
  onSuccess: (data, dispatch) => {
    dispatch(setApiResponse(data));
    return {
      type: CLEAR_CART_DATA,
    };
  },
  onFailure: () => {
    console.log("error occured custom");
    return {
      type: "a"
    };
  }
});
}

export const setTickets = (data) => {
  return {
    type: SET_TICKETS,
    payload: data,
  };
}
// export const getPaymentIntent = (amount) => apiAction({
//   endPoint: `/service/stripeIntent`,
//   method: "POST",
//   data : {
//     amount
//   },
//   onSuccess: (data, dispatch) => {
//     dispatch(SetCartData(data));
//     return {
//       type: SET_PAYMENT_INTENT,
//       payload: data.data?.stripeIntentResponse,
//     };
//   },
//   onFailure: () => {
//     console.log("error in fetching the intent");
//     return {type: "a"};
//   }
// });

export const setOrder = (payload) => {
  return {
      payload,
      type: SET_ORDER,
  };
}
export const refreshPaymentTokens = (inputOrderId) => {
  let state = store.getState();

  return apiAction({
  endPoint: `/shop2/order/update`,
  method: "POST",
  label: "refreshPaymentTokens",
  data : {
    "refreshPayment":true,
    orderId: inputOrderId ?? state?.shop?.order?.id,
    connectionId: state?.ws?.connectionId,

  },
  onSuccess: (data, dispatch) => {
    return setOrder(data?.data)
  },
  onFailure: (data, dispatch) => {
    dispatch(setOrderState("STRIPE_INTENT_FAILED"))

    console.log("error in fetching the intent");
    return {type: "a"};
  }
});
}

export const setCanPayWithStripe = (data) => {
  return {
    type: SET_CAN_PAY_WITH_STRIPE,
    payload: data,
  };
}

