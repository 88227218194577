import { forwardRef } from "react";
import { Col }          from "../views/dev/gyula/BrandMinds/components/Grid";
import { horizontalDottedLine } from "./funkySVG/horizontalDottedLine";
import './SimpleCard.scss'
import colors from 'styleBM/colors.module.scss'

export const SimpleCard = forwardRef((props, ref) => {

  const { gridArea, subtitle, nr, title, image, content, progress, className : inputClassName, style, editHeader, label, onClick: inputOnClick, isEdit, setEdit,
    showModal, //opens a specific modal on right text click
    centerContent, center, //centers the card content
    id,
    tight, //it will reduce the card paddings to 16px
    grey80bg, //type of card with  grey-80 bg and no borde
    cardWidht,
    ...rest //includes sm, md, lg, xl,
  } = props;
  const isCol = rest.xs || rest.sm || rest.md || rest.lg || rest.xl

  let className = 'simple-card-wrapper '
  if (inputClassName) className += inputClassName
  if (grey80bg) className += ' has-grey-80-bg-prop'
  if (centerContent || center) className += ' center-content'
  if (tight) className += ' tight'
  if (cardWidht) className += ` width-${cardWidht}`

  const handleOnClick = () => {
    if (inputOnClick)
      inputOnClick()
  }
  const nakedCard =
  <div className={className} style={{...style, gridArea, id}} ref={ref} onClick={() => handleOnClick()}>
    {props.children }
  </div>
 
  return isCol
    ? <Col {...rest}>{nakedCard}</Col>
    : nakedCard
})
export default SimpleCard;


export const SimpleCardSpacer = (props) => {
  const {noMargins, strokeColor, className : inputClassName, strokeWidth=1, dashLength=8, spaceLength} = props
  let className = "spacer"
  if (inputClassName) className += ' ' + inputClassName
  if (noMargins) className += ' no-margins'

  return horizontalDottedLine( {
      strokeWidth, 
      dashLength, 
      spaceLength,
      clear : false, 
      stroke : strokeColor ?? colors['grey-80'], 
      className,
  })
}