import Spinner from "componentsBM/Spinner";
import { useDispatch } from "react-redux";
import { setPageModal } from "store/modules/page";
import "./LoadingScreen.scss";

export default function LoadingScreen() {
  const dispatch = useDispatch()
  dispatch(setPageModal())
  
  return (
    <div className="loader-screen">
      <Spinner />
    </div>
  )
}
