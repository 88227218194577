//middleware/middleware.js 

import { setLoadingIndicator } from 'store/modules/page';
import * as actions from '../modules/websocket';
import { refreshToken } from './api';
// import { updateGame, } from '../modules/game';

const wsPingTimout = 9 * 60 * 1000 
const socketMiddleware = () => {
  let socket = null;
  let wsPing = null;
  let refreshRequested = null;

  const onOpen = store => (event) => {
    console.log('websocket opening');
    store.dispatch(actions.wsConnected());
    wsPing = setInterval(() => {
      socket?.send(JSON.stringify({ action: 'ping' }));
      console.log('ws ping');
    }, wsPingTimout);
  };

  const onClose = store => () => {
    store.dispatch(actions.wsDisconnected());
    if (wsPing) clearInterval(wsPing)
  };

  const onMessage = store => (event) => {
    const eventData = JSON.parse(event.data);
    console.log('receiving server message');

    const newAction = {
      ...eventData,
      type: `ws/${eventData?.action ?? eventData?.type}`,
      payload: eventData?.payload ?? eventData?.data,
    }
    if (eventData?.action ?? eventData?.type) store.dispatch(newAction)
    else console.warn("undispatchable message: ", eventData)
    
    // switch (payload.type) {
    //   case 'update_game_players':
    //     break;
      // default:
        // break;
    // }
  };

  const onError = store => (error) => {
    
    if (!refreshRequested) refreshToken().then(access_token => {
      refreshRequested = false
      store.dispatch({type: "WS_CONNECT"})
    })
    .catch(e => console.log(e))
    refreshRequested = true
  }

  // the middleware part of this function
  return store => next => action => {
    switch (action?.type) {
      case 'WS_CONNECT':
        if (socket !== null) {
          socket.close();
        }

        // connect to the remote host
        const token = localStorage.getItem("authToken");
        // const token = store.getState()?.auth?.token
        socket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}?token=${token}`);

        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);
        socket.onerror = onError(store);
        socket.addEventListener('open', (event) => {
          store.dispatch({type:"ws/SET_WS_IS_CONNECTED"});
          socket?.send(JSON.stringify({ action: 'INITIALIZE_CONNECTION' }));
        });

        break;
      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        console.log('websocket closed');
        break;
      case 'NEW_MESSAGE':
        // console.log('sending a message', action.payload);
        socket?.send(JSON.stringify({ action: 'message', data: action.payload }));
        break;
      case 'WS_SEND_DATA':
        store.dispatch(setLoadingIndicator(action.payload?.action))
        // console.log('sending a message', action.payload);
        try{
          socket?.send(JSON.stringify({...action.payload}));
        } catch (err) {
          console.error(err)
        }
        break;
      default:
        console.log('the next action:', action);
        return next(action);
    }
  };
};

export default socketMiddleware();