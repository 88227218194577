import { lazy } from "react";
import { Redirect } from "react-router";
import AuthGuard from 'componentsBM/AuthGuard';

export const bmAdminRoutes = [
  {
    exact: true,
    path: "/admin/members/list",
    component: lazy(() => import("../views/members/MembersPage")),
  },
  {
    exact: true,
    path: "/admin/members/form",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/user-profile")),
  },
  {
    guard: AuthGuard,
    exact: true,
    path: "/admin/speakers/list",
    component: lazy(() => import("../views/admin/speakers/SpeakersPage")),
  },
  {
    exact: true,
    path: "/admin/speakers/form",
    component: lazy(() => import("../views/admin/speakers/speaker-details/SpeakerDetails")),
  },
  {
    exact: true,
    path: "/admin/users/list",
    component: lazy(() => import("../views/admin/users/UsersPage")),
  },
  {
    exact: true,
    path: "/admin/users/form",
    component: lazy(() => import("../views/admin/users/user-details/UserDetails")),
  },
  {
    exact: true,
    path: "/admin/orders/list",
    component: lazy(() => import("../views/admin/orders/OrdersPage")),
  },
  {
    exact: true,
    path: "/admin/orders/form",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/orders/order-details/OrderDetails")),
  },
  {
    exact: true,
    path: "/admin/tickets/list",
    component: lazy(() => import("../views/admin/tickets/TicketsPage")),
  },
  {
    exact: true,
    path: "/admin/tickets/form",
    component: lazy(() => import ("../views/dev/gyula/BrandMinds/pages/tickets/details/TicketDetails")),
  },
  {
    exact: true,
    path: "/admin/events/list",
    component: lazy(() => import("../views/admin/events/EventsPage")),
  },
  {
    exact: true,
    path: "/admin/events/form",
    component: lazy(() => import("../views/admin/events/event-details/EventDetails")),
  },
  {
    exact: true,
    path: "/admin/live-mode/list",
    component: lazy(() => import("../views/admin/live-mode/LiveAdminPage.jsx")),
  },
  {
    exact: true,
    path: "/admin/live-mode/form",
    component: lazy(() => import("../views/admin/live-mode/live-mode-details/LiveAdminDetails")),
  },
  {
    exact: true,
    path: "/admin/sessions/list",
    component: lazy(() => import("../views/admin/sessions/SessionsPage")),
  },
  {
    exact: true,
    path: "/admin/sessions/form",
    component: lazy(() => import("../views/admin/sessions/session-details/SessionDetails")),
  },
  {
    exact: true,
    path: "/admin/partners/list",
    component: lazy(() => import("../views/admin/partners/PartnersPage")),
  },
  {
    exact: true,
    path: "/admin/partners/form",
    component: lazy(() => import("../views/admin/partners/partner-details/PartnerDetails")),
  },
  {
    exact: true,
    path: "/admin/notification/list",
    component: lazy(() => import("../views/admin/notifications/NotificationPage")),
  },
  {
    exact: true,
    path: "/admin/notification/form",
    component: lazy(() => import("../views/admin/notifications/notification-details/NotificationDetails")),
  },
]