

  
const diffInHours = (ds1, ds2) => {
    const date1 = new Date(ds1);
    const date2 = ds2 ? new Date(ds2) : new Date();
    const diffTime = Math.abs(date2 - date1);
    return  Math.round(diffTime / (1000 * 60 * 60 )*10)/10; 
  }
export const eventSlideMapper = item => {
      const slides = [];
      let newSlide = {
        tempNoTopRightIndicatiors:true,
        topLeftTxt: 'Presented by',
        bottomLeftTxt: 'Today in Bucharest',
        topLeftLogo: item?.mainSponsor?.whiteLogoPng,
        shortCode: '22',
        bar:{
          percent: diffInHours(item?.startDate) / diffInHours(item?.publishDate, item?.startDate) * 100 ,
          // secondary:true
        }
      }
      ///main slide
      slides.push({
        ...item, //add db event data
        ...newSlide, //add general slide data. same for all slides
        //"heroBP": "https://brandmindsawsbestack-assetsbuckete5c0e90f-dbw5in6gd3pf.s3.eu-central-1.amazonaws.com/big-portrait/BrandMinds2019_Selectie_079 1.png",
        // "heroBP": "/images/brand-minds/dashboard/brand-slider/slides/slide1.jpg",
        slideType: 'center',
        navImage: item?.thumb,
        // actions: getSlideActions(item),
        actions: [item?.actions.primary,item?.actions.secondary ],
      })
  
      ///add individual session info
      item?.sessions?.forEach(item => {slides.push({
          ...item, //add db session data
        ...newSlide, //add general slide data. same for all slides
          "heroBP": item?.thumb,
          // "heroBP": "/images/brand-minds/dashboard/brand-slider/slides/slide1.jpg",
          slideType: 'left',
          // "title": "BRAND MINDS 2022 LIVE",
          // "subTitle1": "THE GLOBAL THINKERS BUSINESS SUMMIT",
          // "subTitle2": "JUNE 7 - 9 2022 - ROMEXPO, BUCHAREST",
          // "topLeftLogo": "https://brandmindsawsbestack-assetsbuckete5c0e90f-dbw5in6gd3pf.s3.eu-central-1.amazonaws.com/partner/unicredit-alb.svg", this is transparent also contains data-immage  for images
          // topButtonLabel: 'Upgrade Tickets from 29 E',
          // ticketAnnounceTxt: 'Only 5 tikcets remaining',
          // ticketAnnounceBg: 'color-name',
          navImage: item?.thumb,
          actions: [item?.actions.primary,item?.actions.secondary ],
        })})
    return slides
  }


  // export const getSlideActions = (event, sessionIndex) => {
  //   return [
  //     {
  //       large:true,
  //       secondary: !event?.hasTicket,
  //       high:true,
  //       label: event?.hasTicket 
  //         ? sessionIndex 
  //           ? event?.[sessionIndex]?.isLive ? "Watch live" : "See your tickets"
  //           : "Watch live"
  //         : `Buy tickets from ${event?.ticketInfo?.price} E`,
  //         href:`/events/${event?.PK}/tickets`,
  //       },
  //     {
  //       href:`/events/${event?.PK}/agenda`,
  //       secondary: true,
  //       large:true,
  //       label: sessionIndex 
  //         ? "View Event"
  //         : "Explore the event agenda",
  //     }
  //   ]
  // }