import React from 'react';

// Style
import "./Spinner.scss";
import colors from 'styleBM/colors.module.scss';

function Spinner(props) {

  const {color} = props

  
  const style = {
    "--bgColor" : `${color ?? colors['grad-btn']}`
  }

  return (    
    <div className="lds-roller" {...{style}}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Spinner;