import { lazy } from "react";
import { Redirect } from "react-router";

export const bmRoutes = [
  {
    exact: true,
    path: "/bm/brandminds",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/BrandMinds")),
  },
  {
    exact: true,
    path: "/bm/tickets-admin/vouchers",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/Vouchers")),
  },

  {
    exact: true,
    path: "/bm/page-elements",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/PageElements")),
  },
  {
    exact: true,
    path: "/bm/tickets/list",
    component: lazy(() => import ("../views/dev/gyula/BrandMinds/pages/TicketsPage")),
  },
  {
    exact: true,
    path: "/bm/transferred-tickets/list",
    component: lazy(() => import ("../views/dev/gyula/BrandMinds/pages/TicketsPage")),
  },
  {
    exact: true,
    path: "/bm/tickets/tickets-detail",
    component: lazy(() => import ("../views/dev/gyula/BrandMinds/pages/TicketsDetail")),
  },
  {
    exact: false,
    path: "/bm/tickets/new-tickets-detail",
    component: lazy(() => import ("../views/dev/gyula/BrandMinds/pages/tickets/details/TicketDetails")),
  },
  {
    exact: true,
    path: "/bm/events",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/EventsPage")),
  },
  {
    exact: false,
    path: "/bm/event-details",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/events/EventDetailsPage")),
  },
  {
    exact: true,
    path: "/bm/related-videos",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/related-videos/RelatedVideosPage")),
  },
  {
    exact: true,
    path: "/bm/related-video",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/related-videos/RelatedVideoPage")),
  },
  {
    exact: true,
    path: "/bm/registered-user",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/RegisteredUser")),
  },
  {
    exact: true,
    path: "/bm/ticket-type",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/TicketType")),
  },
  {
    exact: true,
    path: "/bm/get-tickets",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/TicketType")),
  },
  {
    exact: true,
    path: "/bm/icons",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/IconPage")), 
  },
  
  {
    exact: true,
    path: "/bm/brand-minds-2022/event-single",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/EventSingle")),
  },
  {
    exact: true,
    path: "/bm/example",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/Example")),
  },
  // {
  //   exact: false,
  //   path: "/bm/orders",
  //   component: lazy(() => import("../views/dev/gyula/BrandMinds/tables/ordersListTable/myOrdersTable")),
  // },
  {
    exact: true,
    path: "/bm/invite",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/InvitePage")),
  },
  {
    exact: true,
    path: "/bm/orders",
    component: () => <Redirect to="/bm/orders/list" />,
  },
  {
    exact: true,
    path: "/bm/orders/list",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/orders")),
  },
  {
    exact: false,
    path: "/bm/orders/form",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/orders/order-details/OrderDetails")),
  },
  {
    exact: true,
    path: "/bm/invoices",
    component: () => <Redirect to="/bm/invoices/list" />,
  },
  {
    exact: true,
    path: "/bm/invoices/list",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/invoices")),
  },
  {
    exact: true,
    path: "/bm/invoices/form",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/invoices/details/InvoiceDetails")),
  },
  
  {
    exact: true,
    path: "/bm/links",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/LinksPage")),
  },

  {
    exact: true,
    path: "/bm/frameworks/dashboard",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/FrameworksHome")),
  },
  {
    exact: true,
    path: "/bm/frameworks/browse",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/FrameworksBrowse")),
  },
  {
    exact: true,
    path: "/bm/frameworks/article",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/FrameworksArticle")),
  },

  {
    exact: true,
    path: "/bm/certificates/my-certificates",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/MyCertificates")),
  },

  {
    exact: false,
    path: "/bm/profile/my-profile",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/MyProfile")),
  },
  {
    exact: false,
    path: "/bm/profile/my-new-profile",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/user-profile")),
  },
  {
    exact: true,
    path: "/bm/my-account",
    component: lazy(() => import("../views/my-account/MyAccount")),
  },
  {
    exact: true,
    path: "/bm/my-account/billing-profiles",
    component: lazy(() => import("../views/my-account/billing-profiles/BillingProfiles")),
  },
  {
    exact: true,
    path: "/bm/my-account/my-wallet",
    component: lazy(() => import("../views/my-account/wallet/Wallet")),
  },
  {
    exact: true,
    path: "/bm/notes",
    component: lazy(() => import("../views/notes/NotesPage")),
  },
  {
    exact: true,
    path: "/bm/privacy",
    component: lazy(() => import("../views/privacy-settings/PrivacySettingsPage")),
  },
  

  // {
  //   exact: false,
  //   path: "/bm/networking/contacts",
  //   component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/ProfileContactsPage")),
  // },
  // {
  //   exact: false,
  //   path: "/bm/networking/chats",
  //   component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/ChatsPage")),
  // },

  

  {
    exact: true,
    path: "/bm/billing-profile",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/BillingProfile")),
  },
  {
    exact: true,
    path: "/bm/payment",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/Payment")),  
  },
  {
    exact: true,
    path: "/bm/live-now",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/live")),
  },

  {
    exact: true,
    path: "/bm/ticket-variations",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/TicketVariations")),
  },

  {
    exact: true,
    path: "/bm/slider",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/NewSlider")),
  },

  {
    exact: false,
    path: "/bm/brandminds",
    component: () => <Redirect to="/bm/brandminds" />,
  },
];

export const bmRoutesUnauthenticated = [
  {
    exact: true,
    path: "/auth/bm/register",
    component: lazy(() => import("../views/register/Register")),
  },
  {
    exact: true,
    path: "/auth/bm/check-voucher",
    component: lazy(() => import("../views/register/Register")),
  },

  {
    exact: true,
    path: "/auth/bm/bad-move",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/BadMove")),
  },

  {
    exact: false,
    path: "/bm/brandminds",
    component: () => <Redirect to="/bm/brandminds" />,
  },
];
