import { useMemo } from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { closeMenuPanel } from "store/justActions/menuInteractions"
import { setMainMenuButtons } from "store/justActions/menuInteractions"



let searchInMenuData = (input, location) => {
  
  let condition = false
  //check if it's an array
  if (input.length) input?.forEach(item => {
    condition = searchInMenuData(item)
  })
  
  if (input.href && location?.pathname === input.href) condition = true
  // if (window.location.pathname?.includes(input.href)) condition = true

  if (input?.children) condition = searchInMenuData(input?.children)
  return condition
}

const MenuLevel = ({item, level, topItem}) => {
  const dispatch = useDispatch()
  let location = useLocation();

  const active = searchInMenuData(item, location) ? 'active' : ''
  const [open, setOpen] = useState(Boolean(active))

  const showChilds = (!item.collapse) || open
  const onContainerClick = () => {
    item?.children && setOpen(p=>!p)
    level === 3 && dispatch(setMainMenuButtons({type:3, entries: topItem.children}))
    !item?.children && dispatch(closeMenuPanel())
  }

  return <div className={`menu-level__${level}`}>
    <div className={`a-container ${active}`} onClick={onContainerClick}>
      {level === 3 && level3Prefix}
      {item?.children
        ?<>
          <span>{item?.label}</span>
          {item?.collapse && downArrow}
        </>
        :<>
          {level !== 3 && active && <div className="dot"/>}
          <Link className={active} to={item?.href}>{item?.label}</Link>
          {rightArrow}
        </>
        }
    </div>
    {showChilds && item?.children?.map((subItem, i) => <MenuLevel {...{item: subItem, level:level+1, key:i, topItem:item}}/>) }
    </div>
}

export default MenuLevel

const level3Prefix = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_10218_271068)">
<path d="M25.5 17.5H13.5C10.186 17.5 7.5 14.814 7.5 11.5V3.5"  strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_10218_271068">
<rect width="24" height="24" fill="white" transform="translate(4 4)"/>
</clipPath>
</defs>
</svg>

const rightArrow = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 16H26"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19 9L26 16L19 23"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const downArrow = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.1055 13.3334L16.3291 18.6667L21.5528 13.3334"  strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
