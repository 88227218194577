import {createSelector} from 'reselect';
import { eventSlideMapper } from 'selectorComponents/eventParsers';
import { buttonProps } from 'utils/reactButtonMapper';

const dashboardPage = state => state?.backData?.pageSpecific?.dashboardPage
const selectUserProfile = state => state?.user?.profile // naming: selectors with select prefix

export const DashboardPage = createSelector(
     dashboardPage, selectUserProfile,
    ( dashboardPage, userProfile) => { // naming: describe what the selector outputs
        const {tickets } = dashboardPage ?? {}
        const {firstName, lastName} = userProfile ?? {};
        const userFullName = firstName && `${firstName} ${lastName}` //get from state
        // const userFullName = 'Ionut Popescu' //get from state
        const sampleEvent = dashboardPage?.today?.[0] ?? {}
        const sampleSession = sampleEvent.sessions?.[0] ?? {}
     
        const invoiceButtonHref = dashboardPage?.inovices?.length === 1 ? `bm/invoices/form?id=${dashboardPage?.invoices?.[0]?.id}` : null
        let unpaidInvoices = `${dashboardPage?.inovices?.length > 0 ? dashboardPage?.inovices?.length : 'No'} unpaid invoices.`

        let userData = {...userProfile}
        userData.firstName = firstName
        userData.lastName = lastName
        userData.profileCompletion = userData.profileCompletion ?? 0
        userData.details1 = userProfile?.companyDetails?.employer;
        userData.details2 = userProfile?.country?.label;

        userData.avatar = {
          "url":userProfile?.avatar,
          "name": userProfile?.avatar_URL,
          // "type":"image/png",
          // "size":"732 kB"
        }
        // const jobTitle = userProfile?.companyDetails?.jobTitle ?? '';
        // const employer = userProfile?.companyDetails?.employer ?? '';
        // userData.person.role = `<strong>${jobTitle}</strong><br/> ${employer}`
        const totalTickets = `${tickets?.length ?? 'No'} tickets`

        const certificatesRecieved =  `${dashboardPage?.certificates?.length > 0 ? dashboardPage?.certificates?.length : 'No'} certificates recieved.`
        const liveNow = dashboardPage?.now?.map(item => ({
            ...item
        }))
// "PAST
// "JUST_MISSED
// "TODAY
// "SOON
// "UPCOMING
        const mainEvents = eventsMapping(dashboardPage?.withButtons?.mainEvents)
        const happeningSoon = eventsMapping(dashboardPage?.withButtons?.SOON)
        const upcomingEvents = eventsMapping(dashboardPage?.withButtons?.UPCOMING)
        const happeningNow = eventsMapping(dashboardPage?.withButtons?.TODAY)
        const justMissedEvents = eventsMapping(dashboardPage?.withButtons?.JUST_MISSED)
        const previousEvents = eventsMapping(dashboardPage?.withButtons?.PAST)
        const happeningNowSliders = slidersMapping(eventsMapping(dashboardPage?.withButtons?.TODAY))
        const mainEventsSliders = slidersMapping(eventsMapping(dashboardPage?.withButtons?.mainEvents))
        const topCardSlider = slidersMapping(eventsMapping([dashboardPage?.topCard]))
        return {
            tx, firstName,
            liveNow,invoiceButtonHref, previousEvents,
            upcomingEvents, tickets, mainEvents,
            happeningNowSliders,mainEventsSliders,
            happeningNow, happeningSoon,topCardSlider,
            justMissedEvents,
            userFullName,
            sampleEvent,
            sampleSession:{...sampleSession, 
                liveFor:'Live for 2 min',
                hasTicket: true,
            },
            userData,
            unpaidInvoices,totalTickets, 
            certificatesRecieved,
            ourFrameworks: mockMotivations,
            yourFrameworks: mockMotivations,
            experiences: dashboardPage?.allExperiences?.length ? dashboardPage?.allExperiences?.map(experience => ({
              id: experience.PK,
              thumb: experience.avatar,
              title: experience.name,
              info: experience.tooltip,
              primaryAction: buttonProps(experience.actions.primary)
            })): [],
            topCard: dashboardPage?.topCard
        }
    }
);
export default DashboardPage

const slidersMapping = events => events?.map(item => eventSlideMapper(item)) 

const sessionMapper = (item) => ({
  ...item,
  actions : {
    primary: buttonProps(item?.actions?.primary),
    secondary: buttonProps(item?.actions?.secondary),
    default: buttonProps(item?.actions?.default),
  }
})

export const eventMapper = item => ({
  ...item,
  actions : {
    primary: buttonProps(item?.actions?.primary),
    secondary: buttonProps(item?.actions?.secondary),
    default: buttonProps(item?.actions?.default),
  },
  sessions: item?.sessions?.map(session => sessionMapper(session))
})

export const eventsMapping = events => events?.map(item => eventMapper(item))

// let userData = {
//     avatar:{},
//     person:{
//       pill:140
//     },
//     progress:{
//       type:"red",
//       percent:20
//     },
//     pill:100
//   }


const tx = {

  upcoming: 'Upcoming events',
  previousEvents: 'Previous events',
  justMissed: 'Just missed',
  profile: 'Profile',
  welcomeToBm: "Welcome to Brand Minds",
  discoverFrameworks: 'Discover our Mind Frameworks',
  experiences: 'Experiences',
  continueReading: 'Continue Reading',
  happeningSoon: 'Happening Soon',
  pageSubTitle: "BRAND MINDS 2021, Bucharest, 16 APR 22",
  pageTitle: "Select your billing profile",
  yourAccount: "Your Brand Minds account",
  billingProfile: "Billing Profile",
  creteOrSignIn: 'Crete an account or Sign in first',
  proceedToPayment: 'Proceed to Payment',
  paymentTypesText: "MasterCard, Visa and Bank Transfer",//"MasterCard, Visa, Bank Transfer and Crypto Payments",
  orderSummary: 'ORDER SUMMARY',
  tickets: 'Tickets',
  total: 'Total',
  addMoreTickets: 'Add more tickets',
  addVoucher: 'Add voucher',
  signedInAs: 'Signed in as ',

}

export const mockMotivations = [
    {
      id:1001,
      hasTicket: true,
      readTime:15,
      readTimeTxt: '15 min read',
      completed:false,
      newRelease:undefined,
      icon: 'documents3-clear',
      image:"/images/brand-minds/frameworks/motivation.jpg",
      title:"The leadership motivation assessment",
      user:{
        src:"/images/brand-minds/default-user.png",
        name:"Jake Johnson",
      },
    //   connections,
    },
    {
      id:1002,
      readTime:5,
      readTimeTxt: '5, min read',
      completed:false,
      newRelease:undefined,
      icon: 'documents3-clear',
      image:"/images/brand-minds/frameworks/motivation.jpg",
      title:"The leadership motivation assessment",
      user:{
        src:"/images/brand-minds/default-user.png",
        name:"Jake Johnson",
      },
    //   connections,
            progress: 60,

    },
    {
      id:1003,
      readTime:5,
      readTimeTxt: '5, min read',
      completed:true,
      newRelease:undefined,
      icon: 'documents3-clear',
      image:"/images/brand-minds/frameworks/motivation.jpg",
      title:"The leadership motivation assessment",
      user:{
        src:"/images/brand-minds/default-user.png",
        name:"Jake Johnson",
      },
    //   connections,
            progress: 30,

    },
    {
      id:1004,
      readTime:5,
      readTimeTxt: '5, min read',
      completed:false,
      newRelease:undefined,
      icon: 'documents3-clear',
      image:"/images/brand-minds/frameworks/motivation.jpg",
      title:"The leadership motivation assessment",
      user:{
        src:"/images/brand-minds/default-user.png",
        name:"Jake Johnson",
      },
    //   connections,
            progress: 80,

    },
    {
      id:1005,
      readTime:15,
      readTimeTxt: '15 min read',
      completed:false,
      newRelease:"2021-08-13T09:49:56.374+0000",
      icon: 'documents3-clear',
      image:"/images/brand-minds/frameworks/motivation.jpg",
      title:"The leadership motivation assessment",
      user:{
        src:"/images/brand-minds/default-user.png",
        name:"Jake Johnson",
      },
    //   connections,
  
    },
    {
      id:1006,
      readTime:5,
      readTimeTxt: '5, min read',
      completed:false,
      newRelease:undefined,
      icon: 'documents3-clear',
      image:"/images/brand-minds/frameworks/motivation.jpg",
      title:"The leadership motivation assessment",
      user:{
        src:"/images/brand-minds/default-user.png",
        name:"Jake Johnson",
      },
    //   connections,
    },
    {
      id:1007,
      readTime:5,
      readTimeTxt: '5, min read',
      completed:false,
      newRelease:undefined,
      icon: 'documents3-clear',
      image:"/images/brand-minds/frameworks/motivation.jpg",
      title:"The leadership motivation assessment",
      user:{
        src:"/images/brand-minds/default-user.png",
        name:"Jake Johnson",
      },
    //   connections,
    },
    {
      id:1008,
      readTime:15,
      readTimeTxt: '15 min read',
      completed:false,
      newRelease:undefined,
      icon: 'documents3-clear',
      image:"/images/brand-minds/frameworks/motivation.jpg",
      title:"The leadership motivation assessment",
      user:{
        src:"/images/brand-minds/default-user.png",
        name:"Jake Johnson",
      },
    //   connections,
    },
  ]


  const mockExperiences = [
    {
        id:1001,
        thumb:"/images/brand-minds/experiences/bmw.jpg",
        title:"BMW i10 Drivetest",
        info:"Some info here",
        // hasTicket: true
      },
      {
        id:1002,
        thumb:"/images/brand-minds/experiences/visa.jpg",
        title:"BMW i10 Drivetest",
        info:"Some info here",
        hasTicket: true,
      },
      {
        id:1003,
        video:"https://www.youtube.com/watch?v=ysz5S6PUM-U",
        thumb:"/images/brand-minds/experiences/video.jpg",
        title:"BMW i10 Drivetest",
        info:"Some info here",
        hasTicket: true,
      },
      {
        id:1004,
        thumb:"/images/brand-minds/experiences/bmw.jpg",
        title:"BMW i10 Drivetest",
        info:"Some info here",
        // hasTicket: true,
      },
      {
        id:1005,
        thumb:"/images/brand-minds/experiences/bmw.jpg",
        title:"BMW i10 Drivetest",
        info:"Some info here",
        hasTicket: true
      }
  ]


  
  const mockSliderSlides = [
    {
      // tempNoTopRightIndicatiors:true,
			//"heroBP": "https://brandmindsawsbestack-assetsbuckete5c0e90f-dbw5in6gd3pf.s3.eu-central-1.amazonaws.com/big-portrait/BrandMinds2019_Selectie_079 1.png",
			"heroBP": "/images/brand-minds/dashboard/brand-slider/slides/slide1.jpg",
			shortCode: '22',
			slideType: 'center',
			"title": "BRAND MINDS 2022 LIVE",
			"subTitle1": "THE GLOBAL THINKERS BUSINESS SUMMIT",
			"subTitle2": "JUNE 7 - 9 2022 - ROMEXPO, BUCHAREST",
			topLeftTxt: 'Presented by',
			// "topLeftLogo": "https://brandmindsawsbestack-assetsbuckete5c0e90f-dbw5in6gd3pf.s3.eu-central-1.amazonaws.com/partner/unicredit-alb.svg", this is transparent also contains data-immage  for images
			topLeftLogo:"/images/brand-minds/dashboard/brand-slider/unicredit.png",
			topButtonLabel: 'Upgrade Tickets from 29 E',
			ticketAnnounceTxt: 'Only 5 tikcets remaining',
			ticketAnnounceBg: 'color-name',
			bottomLeftTxt: 'Today in Bucharest',
			navImage:"/images/brand-minds/dashboard/brand-slider/nav/bm.png",
			actions:[
				{
					id:1,
					secondary:true,
					high:true,
					large:true,
					label:"See your 4 ticket",
					href:"#!"
				},
				{
					id:2,
					secondary:true,
					large:true,
					label:"Explore the event agenda",
					href:"#!"
				}
			],
			bar:{
				percent:25,
				// secondary:true
			}
    },
		{
			//"heroBP": "https://brandmindsawsbestack-assetsbuckete5c0e90f-dbw5in6gd3pf.s3.eu-central-1.amazonaws.com/big-portrait/BrandMinds2019_Selectie_079 1.png",
			"heroBP": "/images/brand-minds/dashboard/brand-slider/slides/slide2.jpg",
			// shortCode: '22',
			"thumb": "https://brandmindsawsbestack-assetsbuckete5c0e90f-dbw5in6gd3pf.s3.eu-central-1.amazonaws.com/thumb/event-thumb-1.png",
			slideType: 'left',
			//change things below...
			"title": "BRAND MINDS 2022 LIVE",
			"subTitle1": "THE GLOBAL THINKERS BUSINESS SUMMIT",
			"subTitle2": "JUNE 7 - 9 2022 - ROMEXPO, BUCHAREST",
			topLeftTxt: 'Presented by',
			//"topLeftLogo": "https://brandmindsawsbestack-assetsbuckete5c0e90f-dbw5in6gd3pf.s3.eu-central-1.amazonaws.com/partner/unicredit-alb.svg",
			topLeftLogo:"/images/brand-minds/dashboard/brand-slider/unicredit.png",
			topButtonLabel: 'Upgrade Tickets from 29 E',
			ticketAnnounceTxt: 'Only 5 tikcets remaining',
			ticketAnnounceBg: 'color-name',
			bottomLeftTxt: 'Today in Bucharest',
			navImage:"/images/brand-minds/dashboard/brand-slider/nav/nav1.png",
			actions:[
				{
					id:1,
					secondary:true,
					high:true,
					large:true,
					label:"See your 4 ticket",
					href:"#!"
				},
				{
					id:2,
					secondary:true,
					large:true,
					label:"Explore the event agenda",
					href:"#!"
				}
			],
			bar:{
				percent:66,
				// secondary:false  //onlly purple
			}
    }
	]