import React from 'react';

// Components
import SimplePageWrapper from "../dev/gyula/BrandMinds/components/SimplePageWrapper";

// Style
import "./MobilePage.scss";

const MobilePage = () => {
  const tx = {
    title: "Hey there, world-changer!",
    subtitle: "You might be missing out by using our web platfrom on your mobile device. Please download the latest version of the app to unnlock a game-changing mobile experience!"
  }

  const _openGooglePlay = () => {
    window.open("https://play.google.com/store/apps/details?id=com.brandminds");
  }

  const _openAppStore = () => {
    window.open("https://apps.apple.com/ro/app/brand-minds-platform/id1620173670");
  }

  return (
    <SimplePageWrapper name={'mobile-page'}>
      <div className="header-wrapper">
        <img className="header-img mb10" src="/images/brand-minds/registerSuccess.png" />
      </div>

      <div className="txt-wrapper">
        <div className="ptype0 text-center mb4">{tx.title}</div>
        <div className="etype-2 text-center mb10">{tx.subtitle}</div>
      </div>

      <img className="btn mb6" src="/images/brand-minds/googlePlay.png" onClick={() => _openGooglePlay()}/>
      <img className="btn" src="/images/brand-minds/appStore.png" onClick={() => _openAppStore()}/>
    </SimplePageWrapper>
  )
}
export default MobilePage;