import { useSelector } from "store"

export const useUserInfo = () => {
  const profile = useSelector((state) => state.user?.profile)

  return {
    ...checkUserRole({ profile }),
  }
}

export const checkUserRole = ({ profile }) => {
  let isAdmin = false
  let isModerator = false

  let roleName = profile?.role
  if (roleName?.includes('ROL-')) roleName = rolesMap?.[profile?.role] ?? "CLIENT"

  if (roleName === "ROOT") isAdmin = true
  if (roleName === "LIVE_ADMIN") isModerator = true

  return {
    isAdmin,
    isModerator,
  }
}

export const useUserAuthenticated = () => {
  const token = useSelector((state) => state.auth?.token)

  return !!token
}

const rolesMap = {
  "ROL-0001": "CLIENT",
  "ROL-0002": "ROOT",
  "ROL-0003": "LIVE_ADMIN",
}
