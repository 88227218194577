/* eslint-disable import/no-anonymous-default-export */
import { apiAction, reDispatch } from "store/middleware/api"

export const LOAD_FROM_API_START = "form/LOAD_FROM_API_START"
export const LOAD_FROM_API_SUCCESS = "form/LOAD_FROM_API_SUCCESS"
export const LOAD_FROM_API_ERROR = "form/LOAD_FROM_API_ERROR"
export const SET_BE_COMBINED = "form/SET_BE_COMBINED"

const initialFormState = ({ path }) => ({
  lastRefresh: new Date(),
  beCombined: {},
  uiData: {},
  loadAPIs: path ? [path] : [],
  saveAPIs: [],
})
const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_FROM_API_START: {
      const { formName, path } = action.payload ?? {}
      return {
        ...state,
        [formName]: state?.[formName]?.loadAPIs?.length > 0 ? { ...(state?.[formName] ?? {}), loadAPIs: [...state?.[formName]?.loadAPIs, path] } : initialFormState({ path }),
      }
    }
    case LOAD_FROM_API_SUCCESS: {
      const { formName, path, parsedData } = action.payload ?? {}
      return {
        ...state,
        [formName]: {
          ...(state?.[formName] ?? {}),
          lastRefresh: new Date(),
          loadAPIs: state?.[formName]?.loadAPIs?.filter((e) => e !== path),
          beCombined: { ...state?.[formName].beCombined, ...parsedData },
        },
      }
    }
    case LOAD_FROM_API_ERROR: {
      const { formName, path, parsedData } = action.payload ?? {}
      return {
        ...state,
        [formName]: {
          ...(state?.[formName] ?? {}),
          lastRefresh: new Date(),
          loadAPIs: state?.[formName]?.loadAPIs?.filter((e) => e !== path),
          // beCombined: {...state?.[formName].beCombined, ...parsedData}
        },
      }
    }
    case SET_BE_COMBINED: {
      const { formName, path, parsedData } = action.payload ?? {}
      return {
        ...state,
        [formName]: { ...(state?.[formName] ?? {}), lastRefresh: new Date(), beCombined: parsedData },
      }
    }

    default:
      return state
  }
}

export const setFormBeCombined = ({ data, formName = "default" }) => ({
  type: SET_BE_COMBINED,
  payload: {
    formName,
    parsedData: data,
  },
})
export const loadFormApi = (props) =>
  reDispatch((dispatch) => {
    const { path, method = "GET", formName = "default", parser, index, id } = props

    dispatch({
      type: LOAD_FROM_API_START,
      payload: { formName, path },
    })

    return apiAction({
      endPoint: `${path}?id=${id}`,
      method,
      // data: { id },
      onSuccess: (data, dispatch) => {
        // dispatch( setApiResponse(data))
        return {
          type: LOAD_FROM_API_SUCCESS,
          payload: {
            formName,
            path,
            parsedData: parser ? parser(data) : data,
          },
        }
      },
      onFailure: () => {
        console.log("error occured custom")
        return {
          type: LOAD_FROM_API_SUCCESS,
          payload: {
            formName,
            path,
          },
        }
      },
    })
  })
