import { lazy } from "react";
import { Redirect } from "react-router";

export const bmShopRoutes = [
  {
    exact: false,
    path: "/shop/checkout",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/checkout/CheckoutPage")),
  },
  
  {
    exact: false,
    path: "/shop/",
    component: () => <Redirect to="/" />,
  },
];

