import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import LoadingScreen from "componentsBM/LoadingScreen";
import BrandMindsLayoutUnified from "layouts/BrandMindsLayoutUnified";

import { bmRoutes, bmRoutesUnauthenticated } from "./bm.routes";
import { bmDevRoutes } from "./bm.dev.routes";
import BrandMindsLayoutUnifiedwMenu from "layouts/BrandMindsLayoutUnifiedwMenu";
import { bmUserRoutes } from "./bm.user.routes";
import { bmEventsRoutes } from "./bm.events.routes";
import { bmShopRoutes } from "./bm.shop.routes";
import { bmGuestRoutes } from "./bm.guest.routes";
import { bmNetworkRoutes } from "./bm.network.routes";
import { bmAdminRoutes } from "./bm.admin.routes";
import AuthGuard, { ClientGuard } from 'componentsBM/AuthGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const baseRoutes = [
  {
    path: "/events",
    layout: BrandMindsLayoutUnifiedwMenu,
    routes: [...bmEventsRoutes],
  },
  {
    path: "/guest",
    layout: BrandMindsLayoutUnifiedwMenu,
    routes: [...bmGuestRoutes],
  },
  {
    path: "/bm",
    layout: BrandMindsLayoutUnifiedwMenu,
    routes: [...bmRoutes],
  },

  {
    path: "/auth/bm/",
    layout: BrandMindsLayoutUnified,
    routes: [...bmRoutesUnauthenticated],
  },
  {
    exact: true,
    path: "/frame/:eventId/agenda",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/events/EventAgendaPage")),
  },
  // {
  //   path: "/user/",
  //   layout: BrandMindsLayoutUnifiedwMenu,
  //   routes: [...bmUserRoutes],
  // },
  
  {
    layout: BrandMindsLayoutUnifiedwMenu,
    path: "/shop/",
    routes: [...bmShopRoutes],
  },
  {
    layout: BrandMindsLayoutUnifiedwMenu,
    path: "/network/",
    routes: [...bmNetworkRoutes],
  },
  {
    layout: BrandMindsLayoutUnifiedwMenu,
    path: "/admin/",
    routes: [...bmAdminRoutes],
    // guard: AuthGuard,
  },


  {
    exact: true,
    layout: BrandMindsLayoutUnified,
    path: "/login",
    component: lazy(() => import("views/Login")),
  },
  {
    exact: true,
    layout: BrandMindsLayoutUnified,
    path: "/pin-reset",
    component: lazy(() => import("views/LoginReset")),
  },
  {
    exact: true,
    layout: BrandMindsLayoutUnified,
    path: "/signup",
    component: lazy(() => import("views/register/Register")),
  },
  {
    exact: true,
    layout: BrandMindsLayoutUnifiedwMenu,
    path: "/dashboard",
    component: lazy(() => import("views/dev/gyula/BrandMinds/pages/dashboard/DashboardPage")),
    guard: ClientGuard,
  },
  {
    exact: true,
    layout: BrandMindsLayoutUnified,
    path: "/tos",
    component: lazy(() => import("../views/tos/TosPage")),
  },
  {
    exact: true,
    layout: BrandMindsLayoutUnified,
    path: "/CriticalErrorScreen",
    component: lazy(() => import("../views/register/CriticalErrorScreen")),
  },
  
  {
    path: "*",
    // layout: DashboardLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/',
      //   component: () => <Redirect to="/app/manage/users" />
      // },
      {
        component: () => <Redirect to="/dashboard" />,
      },
    ],
  },
];

const devSpecificRoutes = [
  {
    path: "/dev",
    layout: BrandMindsLayoutUnifiedwMenu,
    routes: [...bmDevRoutes],
  },
]

const isDev = window.location?.hostname?.includes("localhost") || (process.env.REACT_APP_STAGE === "DEV")

const routes = isDev ? [...devSpecificRoutes, ...baseRoutes, ] : baseRoutes

export default routes;
