
export const persistKey = 'root021'
export const appVersion = '0.2.1'



export const FILE_TYPE = {
	IMAGE: 		"IMAGE",
	OTHER: 		"OTHER",
	AVATAR: 	"AVATAR",
    VIDEO: 		"VIDEO",
    DOCUMENT: 	"DOCUMENT"
}

export const FILE_ACTION = {
	ADD: "ADD",
	REMOVE: "REMOVE",
}

export const PERMISSION = {
	READ: "read",
	WRITE: "write",
	DELETE: "delete",
}
