import { apiAction, reDispatch } from "store/middleware/api";
import { setApiResponse } from "./auth";

const TOGGLE_LANGUAGE = "user/TOGGLE_LANGUAGE"
const SET_USER_INFO = "user/SET_USER_INFO"
const SET_BILLING_PROFILES = "user/SET_BILLING_PROFILES";
const SET_USER_NOTES = "user/SET_USER_NOTES";
const SET_USER_EMAIL = "user/SET_USER_EMAIL";
const SET_USER_SECTION = "user/SET_USER_SECTION";
const INTEREST_UPDATE = "user/INTEREST_UPDATE";
const SET_INVITATION_DATA = "user/SET_INVITATION_DATA";
export const SET_USER_INTERESTS = "user/SET_USER_INTERESTS";
const SET_KEY = "USER/SET_KEY";
const MERGE_KEY = "USER/MERGE_KEY";

// const GET_USER_NOTES = "user/GET_USER_NOTES";



const initialState = {
  language: 'EN',
  id: null,
  notes: [],
  billingProfiles: [],
  experiences: [],
  education: [],
  books: [],
  requestProfileRefresh: false,
  interests: {},
  invitationData: {},
  profile: {},
  PK: undefined,
  firstName: undefined,
  lastName: undefined,
  countStats:{
    pendingRequests: 0,
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_KEY:
      return {
        ...state,
        [action.payload?.key]: action.payload?.value,
      };
    case MERGE_KEY:
      return {
        ...state,
        [action.payload?.key]: {
          ...(state?.[action.payload?.key] ?? {}),
          ...(action.payload?.value ?? {}),
        }
      };
      case 'ws/REQUEST_PROFILE_REFRESH':
        return {
          ...state,
          requestProfileRefresh: action.payload
      }
      case SET_USER_INTERESTS:
        return {
          ...state,
          interests: action.payload
      }
      case INTEREST_UPDATE:
        const interestItem = state.interests[action.payload.id]
        let value = (interestItem?.value ?? 0) + action.payload.increment
        if (value < 0) value = 3
        if (value > 3) value = 0
        return {
          ...state,
          interests: {
            ...state.interests,
            [action.payload.id] : {
              ...interestItem,
              value
            }
          }
      }
      case TOGGLE_LANGUAGE:
        return {
          ...state,
        language: state.language === "EN" ? "RO" : "EN"
      }
      case SET_USER_INFO:
        return {
          ...state,
          ...action.payload,
      }
      case SET_USER_NOTES:
        return {
          ...state,
          notes: action.payload,
      }
      case SET_USER_EMAIL:
        return {
          ...state,
          email: action.payload
      }
    case SET_BILLING_PROFILES:
      return {
        ...state,
        billingProfiles: action.payload,
      }
    case SET_INVITATION_DATA:
      return {
        ...state,
        invitationData: action.payload
      }
      case SET_USER_SECTION:
        return {
          ...state,
          ...action.payload
        }
      default:
          return state;
  }
}

export const setUserStatsPendingRequests = count => ({
  type: MERGE_KEY,
  payload: {
    key: "countStats",
    value: {
      pendingRequests: count
    }
  } 
})
export const interestButtonClick = (id, value) => reDispatch(dispatch =>  {
  dispatch(apiAction({
    endPoint: `/user/profile/interests`,
    method: "POST",
    data:{interests:{
        [id] : value
      } 
    },
    onSuccess: () => {
      return {type: 'a',};
    },
    onFailure: () => {
      // console.log("error occured custom");
      return undoInterestButtonClick()
    }
  }))
  return {
    type: INTEREST_UPDATE,
    payload: {
      id,
      increment: 1
    }
  }
})
export const undoInterestButtonClick = (id) => ({
  type: INTEREST_UPDATE,
  payload: {
    id,
    increment: -1
  }
})
export const clearProfileRefreshRequest = () => ({
  type: 'ws/REQUEST_PROFILE_REFRESH',
  payload: false
})
export const setUserSection = (data) => ({
  type: SET_USER_SECTION,
  payload: data
})
export const toggleLanguage = () => ({
  type: TOGGLE_LANGUAGE
})
export const setUserInfo = (data) => ({
  type: SET_USER_INFO,
  payload: data,
})
export const setInvitationData = (data) => ({
  type: SET_INVITATION_DATA,
  payload: data
})
export const setUserNotes = (data) => ({
  type: SET_USER_NOTES,
  payload: data
})
export const setUserEmail = (data) => ({
  type: SET_USER_EMAIL,
  payload: data
})

export const setBillingProfiles = (data) => ({
  type: SET_BILLING_PROFILES,
  payload: data
})


export const setUserProfile = (value) => ({
  type: SET_KEY,
  payload: {
    key: 'profile',
    value,
  },
})