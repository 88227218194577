import { SET_LEFT_MENU_DATA } from "store/modules/menu";
import store from 'store/index'


export const mainMenuItemClick = (item) => {
    const menuActiveId  = store.getState()?.menu?.menuActiveId;
    const menuSidePanel = store.getState()?.menu?.menuSidePanel;
    const mainMenuButtonsType = store.getState()?.menu?.mainMenuButtons?.type;
    const menuSidePanelOpenState = store.getState()?.menu?.menuSidePanelOpen;
  
    let menuSidePanelOpen = item?.id === menuActiveId ? (!menuSidePanelOpenState) : true
    if (mainMenuButtonsType === 1 && item.href) menuSidePanelOpen = false
    return {
        type: SET_LEFT_MENU_DATA,
        payload: {
            menuActiveId:  item?.id,
            menuSidePanel: !menuSidePanel ? item : 
                (item?.id === menuActiveId ? undefined : item),
            menuSidePanelOpen,
        }
    }
  }


  export const closeMenuPanel = () => ({
    type: "menu/SET_KEY",
    payload: {
        key: 'menuSidePanelOpen',
        value: false,
    }
  })
  export const setMenuButtonActive = (id) => ({
    type: "menu/SET_KEY",
    payload: {
        key: 'menuActiveId',
        value: id,
    }
  })

  export const setMainMenuButtons = (data) => ({
    type: "menu/SET_KEY",
    payload: {
      key: 'mainMenuButtons',
      value: data,
    },
  })