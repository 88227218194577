const companyItem1 = {
  id: 1,
  numeCompanie: "Nume Entitate chiar lung SR...",
  tipCompanie: "Reseller",
  cui: "RO 123456785",
  cltvTitle: "RON 184.876",
  // prodImages: [
  //   { id: 0, src: "", alt: "" },
  //   { id: 1, src: "", alt: "" },
  //   { id: 2, src: "", alt: "" },
  //   { id: 3, src: "", alt: "" },
  //   { id: 4, src: "", alt: "" },
  //   { id: 5, src: "", alt: "" },
  //   { id: 6, src: "", alt: "" },
  //   { id: 7, src: "", alt: "" },
  //   { id: 8, src: "", alt: "" },
  // ],
  filialaClientului: "Arad",
  delivered: "Livrata pe ",
  tranzactiDate: "2021-03-19T13:01:06.835Z",
  address: "La Str. Clucerului 12, Bucuresti district 9",

  tranzactiStatus: {
    delivered: "Livrata pe ",
    date: "2021-03-19T13:01:06.835Z",
    address: "La Str. Clucerului 12, Bucuresti district 9",
  },

  status: "Activa",
  problems: "Raporteaza o problema",
  reportedProblem: "Raportat pe ",
  // orderNumber:"1154877",
  eventName:"Brand Minds",
  eventDate:"2021-03-19T13:01:06.835Z",
  ticketNumber:"3",
  billingProfile:"Billing Profile 1",
  // orderNumber:"2852",
  name:"Ursulache Remusache",
  paymanetType:"Bank transfer",
  orderStatus: "Paid",
  email:"bruce.wayne@company.com",
  rewards:"Send a reminder",
  referal:"Invite sent"


};

const companyItem2 = {
  id: 2,
  numeCompanie: "Nume Entitate chiar lung SR...",
  tipCompanie: "Reseller",
  cui: "RO 123456785",
  comanda: "A3123PT982234777ASFR",
  date: "",
  cltvTitle: "RON 184.876",
  // prodImages: [
  //   { id: 0, src: "", alt: "" },
  //   { id: 1, src: "", alt: "" },
  //   { id: 2, src: "", alt: "" },
  //   { id: 3, src: "", alt: "" },
  // ],
  filialaClientului: "Timisoara",
  // delivered: "Livrata pe ",
  tranzactiDate: "2021-03-19T13:01:06.835Z",
  address: "La Str. Clucerului 12, Bucuresti district 9",

  tranzactiStatus: {
    //delivered: "Livrata pe ",
    date: "2021-03-19T13:01:06.835Z",
    address: "La Str. Clucerului 12, Bucuresti district 9",
  },

  status: "Activa",
  problems: "Raporteaza o problema",
  reportedProblem: "Raportat pe ",
  // orderNumber:"1154877",
  eventName:"Brand Minds",
  eventDate:"2021-03-19T13:01:06.835Z",
  ticketNumber:"3",
  billingProfile:"Billing Profile 2",
  // orderNumber:"2852",
  name:"Ursulache Remusache",
  paymanetType:"Card",
  orderStatus: "Pending Payment",
  email:"bruce.wayne@company.com",
  rewards:"15 points",
  referal:"Account created"



};

const companyItem3 = {
  id: 3,
  numeCompanie: "Nume Entitate chiar lung SR...",
  tipCompanie: "Large Company",
  cui: "RO 123456785",
  comanda: "A3123PT982234777ASFR",
  date: "",
  cltvTitle: "RON 185.876",
  status: "Inactiva",
  prodImages: [
    { id: 0, src: "", alt: "" },
    { id: 1, src: "", alt: "" },
    { id: 2, src: "", alt: "" },
    { id: 3, src: "", alt: "" },
  ],
  filialaClientului: "Tirgu Mures",
  delivered: "Livrata pe ",
  tranzactiDate: "2021-03-19T13:01:06.835Z",
  tranzactiStatus: {
    delivered: "Livrata pe ",
    date: "2021-03-19T13:01:06.835Z",
  },

  problems: "Raporteaza o problema",
  reportedProblem: "Raportat pe ",
  // orderNumber:"1154877",
  eventName:"Brand Minds",
  eventDate:"2021-03-19T13:01:06.835Z",
  billingProfile:"Billing Profile 1",
  // orderNumber:"2852",
  name:"Tudoreasca Dudulescu",
  paymanetType:"Bank transfer",
  orderStatus: "Failed Order",
  email:"bruce.wayne@company.com",
  rewards:"105 points",
  referal:"Profile completed"

};

const companyItem4 = {
  id: 4,
  numeCompanie: "Nume Entitate chiar lung SR...",
  tipCompanie: "Small Company",
  cui: "RO 123456785",
  comanda: "A3123PT982234777ASFR",
  date: "",
  cltvTitle: "RON 185.876",
  status: "Inactiva",
  prodImages: [
    { id: 0, src: "", alt: "" },
    { id: 1, src: "", alt: "" },
    { id: 2, src: "", alt: "" },
    { id: 3, src: "", alt: "" },
  ],
  filialaClientului: "Cluj Napoca",
  delivered: "Livrata pe ",
  tranzactiDate: "2021-03-19T13:01:06.835Z",
  tranzactiStatus: {
    delivered: "Livrata pe ",
    date: "2021-03-19T13:01:06.835Z",
  },

  problems: "Raporteaza o problema",
  reportedProblem: "Raportat pe ",
  // orderNumber:"1154877",
  eventName:"Brand Minds",
  eventDate:"2021-03-19T13:01:06.835Z",
  ticketNumber:"3",
  billingProfile:"Billing Profile 1",
  // orderNumber:"2852",
  name:"Ursulache Remusache",
  paymanetType:"Card",
  orderStatus: "Paid",
  email:"bruce.wayne@company.com",
  rewards:"115 points",
  referal:"Ticket purchased"

};

const companyItem5 = {
  id: 5,
  numeCompanie: "Nume Entitate chiar lung SR...",
  tipCompanie: "Small Company",
  cui: "RO 123456785",
  comanda: "A3123PT982234777ASFR",
  date: "",
  cltvTitle: "RON 185.876",
  status: "Inactiva",
  prodImages: [
    { id: 0, src: "", alt: "" },
    { id: 1, src: "", alt: "" },
    { id: 2, src: "", alt: "" },
    { id: 3, src: "", alt: "" },
  ],
  filialaClientului: "Cluj Napoca",
  delivered: "Livrata pe ",
  tranzactiDate: "2021-03-19T13:01:06.835Z",
  tranzactiStatus: {
    delivered: "Livrata pe ",
    date: "2021-03-19T13:01:06.835Z",
  },

  problems: "Raporteaza o problema",
  reportedProblem: "Raportat pe ",
  // orderNumber:"1154877",
  eventName:"Brand Minds",
  eventDate:"2021-03-19T13:01:06.835Z",
  ticketNumber:"3",
  billingProfile:"Billing Profile 1",
  // orderNumber:"2852",
  name:"Ursulache Remusache",
  paymanetType:"Card",
  orderStatus: "Paid",
  email:"bruce.wayne@company.com",
  rewards:"115 points",
  referal:"Referal completed"

};

const orderTableData = {
  header: {
    text1: "comanda",
    text2: "tip plata",
    text3: "produse",
    text4: "status livrare",
    text5: "status",
    text6: "probleme",
  },
  contents: [companyItem1, companyItem2, companyItem3, companyItem4,companyItem5],
};
export default orderTableData;
