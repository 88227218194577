import { lazy } from "react";
import { Redirect } from "react-router";

export const bmGuestRoutes = [
  {
    exact: true,
    path: "/guest/events/list",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/dashboard/DashboardPage")),
  },
  {
    exact: true,
    path: "/guest/events/:eventId/details",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/events/EventDetailsPage")),
  },
  {
    exact: true,
    path: "/guest/events/:eventId/tickets",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/TicketType")),
  },
  {
    exact: true,
    path: "/guest/events/:eventId/checkout",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/checkout/CheckoutPage")),
  },
  {
    exact: false,
    path: "/guest/events",
    component: () => <Redirect to="/guest/events/list" />,
  },
];
