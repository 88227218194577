import { useHistory }   from "react-router";
import { Link, NavLink }      from "react-router-dom";
import CustomSelect     from './CustomSelect';
import SvgIcon          from 'componentsBM/SvgIcon';
import Btn              from "./Btn";


// Data
import { header }       from "../mockData/header";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBackList } from "store/modules/backData";
import { logoutUser } from "store/modules/auth";
import {horizontalDottedLine} from "componentsBM/funkySVG/horizontalDottedLine"
import useShowModalOutsideClick from "utils/useShowModalOutsideClick";
import { selectCartEventId, selectNewAlerts, selectTotalTicketsInCart, selectTotalUnreadMessages } from "./HeaderV2.selectors";
const logo = "/images/brand-minds/BMLogo.svg"

const HeaderV2 = props => {

  const user = useSelector(state => state?.user?.profile)
  const history = useHistory()
  const {firstName, lastName, coins} = user ?? {};
  const userFullName = firstName && `${firstName} ${lastName}` //get from state
  const userInitials = (firstName?.[0].toUpperCase() ?? '') + (lastName?.[0].toUpperCase() ?? '')
  
  // debugger;
  const loginScreen = window.location.pathname?.includes('login')

  const loggedInUserDiv = <div className="logged-in-user-wrapper">
    <div className="initials">{userInitials}</div>
    <div>{userFullName}</div>
    
  </div>
  return(
    <header className="bm-header not-fixed">
      <div className="left">
        <img className="logo" alt="logo" src={logo} />
      </div>
      <nav className="right">
            <div className="user-related unloggged">
              {userFullName 
                ? <>
                  {menuButtons.map((e, i) => <MenuButton key={i} {...e}/>)}
                  <UserMenuButton {...{firstName, coins}}/>
                </>
                : loginScreen
                    ? <Btn {...{variant:"grey",label:"Create account",href:"/auth/bm/register",icoName:"user"}} />
                    : <Btn {...{variant:"grey",label:"Sign in",href:"/login",icoName:"user"}} />
              }
            </div>
          </nav>
    </header>
  );
}
export default HeaderV2;

const menuButtons = [
  {
    href: "/network/request/list",
    icon : "BMContacts", 
    selector: state => state?.ws?.pendingRequests,
  },
  {
    href: "/network/messages",
    icon : "chats", 
    selector: state => selectTotalUnreadMessages(state),
  },
  {
    href: "/network/alerts",
    icon : "notifications", 
    selector: state => selectNewAlerts(state),
  },
  {
    href: "/events/<eventId>/tickets",
    icon : "CartEmpty", 
    selector : state => selectTotalTicketsInCart(state),
    // statName: "cartItems",
    cart:true,
  },
]
const MenuButton = ({href, icon, pill, selector,cart}) => {
  const stat = useSelector(state => selector(state))
  const eventId = useSelector(state => selectCartEventId(state))
  let to = href.replace(/<eventId>/,eventId) 
  if (cart && !eventId) return null
  const tagValue = pill ?? stat
  return <NavLink className="simple-top-button" to={to}>
    <SvgIcon name={icon}/>
    {tagValue > 0 && <span className="red-pill">{tagValue}</span>}
  </NavLink>
}

export const UserMenuButton = ({firstName, coins}) => {
  // const [open, setOpen] = useState(false)
  const {ref, showModal, setShowModal} = useShowModalOutsideClick(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const _logout = () => {
    dispatch(logoutUser())
    history.push(`/login`)
  }

  const userMenu = <div ref={ref} className="user-menu">
    <div className="flex ai-c jc-sb mb2">
      <div className="ptype">QUICK MENU</div>
      {smallClose}
    </div>
      {horizontalDottedLine({strokeWidth : 1, className:"spacer"})}
      {menuItems.map((item, i) => 
        <div key={i} className="menu-item" onClick={() => history.push(item.href)}>
          <div className="ptype0-strong">{item.label}</div>
          <SvgIcon name="arrow right clear"/>
        </div>
      )}
      {horizontalDottedLine({strokeWidth : 1, className:"spacer"})}
      <div className="logout" onClick={() => _logout()}>
        <SvgIcon name="logout clear"/>
        LogOut
      </div>
  </div>

  return (
    <div className="logged-in-user-wrapper" onClick={() => setShowModal(p=>!p)}>
      <SvgIcon name="UserWhite"/>
      <div>{firstName}</div>
      <div className="user-coins">
      {bmSmall}
      <span style={{lineHeight: "1px"}}>{coins ?? 199}</span>
      </div>
      {showModal && userMenu}
  </div>
  )
}

const menuItems = [
  {
    label: 'My Account',
    href:"/bm/my-account",
  },
  {
    label: 'My Profile',
    href:"/bm/profile/my-new-profile",
  },
  {
    label: 'My Tickets',
    href:"/bm/tickets/list",
  },
  {
    label: 'Wallet',
    href:"/bm/my-account/my-wallet",
  },
  {
    label: 'My Connections',
    href:"/network/myconnections/list",
  },
]
const bmSmall = <svg width="16" height="17" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M20.4674 17.2377L25.6845 6H16V27.4988L16.2489 27.2992H27.427L20.4674 17.2377ZM33.3371 25.2964L27.3558 16.649L31.5332 7.65064C31.9084 6.82927 32.073 5.92588 32.0119 5.02335C31.9507 4.12081 31.6658 3.24822 31.1833 2.48618C30.7008 1.72413 30.0363 1.09671 29.2509 0.662309C28.4656 0.22791 27.5847 7.00119e-05 26.6894 0H15.3354C13.9204 0.000160998 12.5634 0.568601 11.5629 1.57958C10.5623 2.59056 10.0002 3.96182 10 5.39156V40L18.3573 33.2992H28.6639C29.592 33.2986 30.5038 33.0536 31.3096 32.5883C32.1154 32.1231 32.7872 31.4534 33.2589 30.6459C33.7306 29.8383 33.9858 28.9206 33.9994 27.983C34.013 27.0454 33.7852 26.1176 33.3371 25.2964Z" fill="black"/>
</svg>



const smallClose = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#333333"/>
<path d="M21.3337 10.6667L10.667 21.3333" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21.3337 21.3333L10.667 10.6667" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const arrowRight = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 16H26" stroke="#B3B3B3" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19 9L26 16L19 23" stroke="#B3B3B3" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

