import { lazy } from "react";
import { Redirect } from "react-router";

export const bmEventsRoutes = [
  {
    exact: true,
    path: "/events/list",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/dashboard/DashboardPage")),
  },
  {
    exact: true,
    path: "/events/:eventId/details",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/events/EventDetailsPage")),
  },
  {
    exact: true,
    path: "/events/:eventId/speakers",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/events/EventSpeakersPage")),
  },
  // {
  //   exact: true,
  //   path: "/events/:eventId/sessions",
  //   component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/events/EventDetailsPage")),
  // },
  {
    exact: true,
    path: "/events/:eventId/partners",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/events/EventPartnersPage")),
  },
  {
    exact: true,
    path: "/events/:eventId/venue",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/events/EventVenuePage")),
  },
  {
    exact: true,
    path: "/events/:eventId/reel",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/events/EventSummaryPage")),
  },
  {
    exact: true,
    path: "/events/:eventId/stream/:sessionId",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/live")),
  },
  {
    exact: true,
    path: "/events/:eventId/replay/:sessionId",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/replay")),
  },
  {
    exact: true,
    path: "/events/:eventId/agenda",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/events/EventAgendaPage")),
  },
  {
    exact: true,
    path: "/events/:eventId/related",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/related-videos/RelatedVideosPage")),
  },
  {
    exact: true,
    path: "/events/:eventId/related/:recordId",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/related-videos/RelatedVideoPage")),
  },
  {
    exact: true,
    path: "/events/:eventId/tickets",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/TicketType")),
  },
  {
    exact: true,
    path: "/events/:eventId/checkout",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/checkout/CheckoutPage")),
  },
  {
    exact: false,
    path: "/events",
    component: () => <Redirect to="/events/list" />,
  },
];
