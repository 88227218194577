
export const adminMenuData = [
  {
    id: 'members',
    label: "Members",
    btnLabel: "Members",
    svg: "inviteuser",
    href: "/admin/members/list",
  },
  {
    id: 'users',
    label: "Admin Users",
    btnLabel: "Admin Users",
    svg: "manageusers",
    href: "/admin/users/list",
  },
  {
    id: 'events',
    label: "Events",
    btnLabel: "Events",
    svg: "stage",
    href: "/admin/events/list",
  },
  {
    id: 'sessions',
    label: "Sessions",
    btnLabel: "Sessions",
    svg: "video",
    href: "/admin/sessions/list",
  },
  {
    id: 'speakers',
    label: "Speakers",
    btnLabel: "Speakers",
    svg: "speaker",
    href: "/admin/speakers/list",
  },
  {
    id: 'partners',
    label: "Partners",
    btnLabel: "Partners",
    svg: "business",
    href: "/admin/partners/list",
  },
  {
    id: 'notification',
    label: "Notifications",
    btnLabel: "Notifications",
    svg: "notifications",
    href: "/admin/notification/list",
  },
  {
    id: 'live',
    label: "Live",
    btnLabel: "Live",
    svg: "stage",
    href: "/admin/live-mode/list",
  },
  {
    id: 'orders',
    label: "Orders",
    btnLabel: "Orders",
    svg: "checklist",
    href: "/admin/orders/list",
  },
  {
    id: 'invoices',
    label: "Invoices",
    btnLabel: "Invoices",
    svg: "invoicedollar",
    href: "/admin/invoices/list",
  },
  {
    id: 'tickets',
    label: "Tickets",
    btnLabel: "Tickets",
    svg: "ticketevents",
    href: "/admin/tickets/list",
  },
]
