const RESET_MEMBER_DATA = "selectedMember/RESET_MEMBER_DATA";
const SET_MEMBER_DATA = "selectedMember/SET_MEMBER_DATA";
const SET_MEMBER_SECTION = "selectedMember/SET_MEMBER_SECTION";
export const SET_MEMBER_INTERESTS = "selectedMember/SET_MEMBER_INTERESTS";

const initialState = {
  id: null,
  interests: {},
  achievement: [],
  education: [],
  certificates: [],
  books: [],  
  PK: undefined,
  firstName: undefined,
  lastName: undefined,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_MEMBER_DATA:
      return {
        initialState,
      }
    case SET_MEMBER_DATA:
      return {
        ...state,
        ...action.payload
      }
    case SET_MEMBER_INTERESTS:
      return {
        ...state,
        interests: action.payload
      }
    case SET_MEMBER_SECTION:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}

export const resetMemberProfile = () => {
  return {
    type: RESET_MEMBER_DATA,
  }
}

export const setMemberProfile = (data) => ({
  type: SET_MEMBER_DATA,
  payload: data
})

export const setMemberSection = (data) => ({
  type: SET_MEMBER_SECTION,
  payload: data
})