import { lazy } from "react";
import { Redirect } from "react-router";

export const bmNetworkRoutes = [
  {
    exact: true,
    path: "/network/member/list",
    component: lazy(() => import("../views/members/MembersPage")),
  },
  {
    exact: true,
    path: "/network/alerts",
    component: lazy(() => import("../views/network/alerts/AlertsPage")),
  },
  {
    exact: true,
    path: "/network/request/list",
    component: lazy(() => import("../views/network/requests/RequestsPage")),
  },
  {
    exact: true,
    path: "/network/myconnections/list",
    component: lazy(() => import("../views/network/my-connections/MyConnectionsPage")),
  },
  {
    exact: true,
    path: "/network/contact/form",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/user-profile")),
  },
  {
    exact: true,
    path: "/network/speakers/list",
    component: lazy(() => import("../views/network/all-speakers/AllSpeakersPage")),
  },
  {
    exact: true,
    path: "/network/myspeakers/list",
    component: lazy(() => import("../views/network/my-speakers/MySpeakersPage")),
  },
  {
    exact: true,
    path: "/network/speaker/form",
    component: lazy(() => import("../views/network/components/speaker-profile/SpeakerProfile")),
  },
  {
    exact: true,
    path: "/network/messages",
    component: lazy(() => import("../views/network/conversations/ConversationsPage")),
  },
  
  {
    exact: false,
    path: "/network/",
    component: () => <Redirect to="/" />,
  },
];

