import { apiAction, dynamoAction } from 'store/middleware/api';
import { getSKfromType } from 'utils/geenrateIds';
import store from '../index'
import { setApiResponse } from './auth';
import { SET_DICTIONARY } from './backData';
import { ADD_ITEMS, SET_ITEMS } from './dataView';
import { SET_CRT_FORM_ITEM } from './page';
import { SET_SEARCH_RESULTS } from './shop';


export const searchBmStuff = ({ label, iType = "TICKET", sortAsc = true, sortField, firstRow = 0, pageSize = 25, searchTerm, reload, admin, type }) => {
  let state = store.getState();
  const nextAction = reload ? SET_ITEMS : ADD_ITEMS
  return apiAction({
    endPoint: `/general/search`,
    method: "POST",
    data: {
      iType,
      // filters:state?.dataView?.selectedFilters,
      // sortAsc: state?.dataView?.sort?.[1] ?? guestSortAsc ?? sortAsc,
      // sortField: state?.dataView?.sort?.[0] ?? guestSortField ?? sortField,
        // "from": 5,
      from: reload 
        ? 0 
        : state?.dataView?.lastItemHint 
          ? state?.dataView?.lastItemHint
          : 0,
      size : pageSize,
      searchTerm: searchTerm ?? state.dataView?.searchString,
      admin: admin ?? false,
      type: type ?? "",
    },
    label,
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      const newItems = data?.Items
      const newLastItemHint = Number(reload ? 0 : state?.dataView?.lastItemHint ?? 0) + newItems.length

        return {
          type: nextAction,
          payload: {
            items: newItems,
            lastItemHint: newItems.length === pageSize && newLastItemHint,
            count: data?.Count
          },
        }
    },
    onFailure: () => {
      console.log("error occured custom");
      return {
        type: "a"
      };
    }
  });
}





  

export const loadDynamoFormItemByPKBM = ({ PK, reload = true, dictionary }) =>
  dynamoAction({
    method: "QUERY",
    parameters: {
      TableName: "BrandMindsAwsBeStack-maintable5C74991E-VTSJVW7Y0AVD",
      KeyConditionExpression: "#pk = :pkvalue",
      // IndexName: "SK-PK",
      ExpressionAttributeNames: {
        "#pk": "PK"
      },
      ExpressionAttributeValues: {
        ":pkvalue": { S: PK }
      }
    },
    onSuccess: (data, dispatch) => {
      if (dictionary) {
        let payload = {}
        payload[dictionary] = data?.items
        return {
          type: SET_DICTIONARY,
          payload
        }
      } else
        return {
          type: SET_ITEMS,
          payload: data
        }
    },
    onFailure: (err) => {
      // showMagicToaster({title: err})
      console.log(err)
      return {
        type: "a"
      }
    }
  })
