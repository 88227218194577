import { createSelector } from "reselect"
import { chatParticipantsList } from "views/network/conversations/ConversationsPage.selector"
import {decode} from "base-64"


export const selectNewAlerts = (state) => state.ws.newAlertCount;

export const selectTotalUnreadMessages = createSelector(
  state => chatParticipantsList(state),
  (chatParticipantsListData) => {
      const {roomsList} = chatParticipantsListData ?? {}
      const unreadMessages = roomsList.reduce((p,c) => p + (c.unreadNumber ?? 0), 0)
      return unreadMessages
  }
)
export const selectTotalTicketsInCart = createSelector(
  state => state.shop?.shoppingCart?.items,
  (cartProducts) => {
    return cartProducts?.reduce((p,c) => p + (c.quantity ?? 0), 0)
  }
)
export const selectCartEventId = createSelector(
  state => state?.shop?.cart,
  (cartProducts) => {
    const firstProduct = cartProducts?.[0]
    if (!firstProduct) return undefined

    try{

      const {PK} = JSON.parse(decode(firstProduct?.id));
      return PK
    } catch(e){
      console.warn(e);
      return undefined
    }
  }
)