import { apiAction } from "store/middleware/api"
import { setAllFieldErrors, setCrtFormItem, setCustomObject, setPageModalData, setWizzardStep } from 'store/modules/page'
import { toastr } from "react-redux-toastr"
import { setPageModal, SET_CRT_LIST_ITEMS, SET_AUTOCOMPLETE_SUGGESTIONS } from "store/modules/page"
import { LOGIN_USER, logoutUser } from 'store/modules/auth'
import { setInvitationData, setUserInfo, setUserProfile, setUserSection, SET_USER_INTERESTS } from 'store/modules/user'
import { setCrtList } from 'store/modules/page'
import { setBillingProfileId, setTickets } from 'store/modules/shop'
import { showMagicToaster } from "utils/MagicToasterCall"
import { setAppliedVouchers, setInvoices, setOrders, setSelectedEventDetails, setInvoiceDetails, setBmUserProfile, setTicketDetails, setReplayAccess } from "store/modules/bm"
import { setOrder } from "store/modules/shop"
import { setUserEmail, setBillingProfiles } from "store/modules/user"
import { history } from "utils/history"
import store from 'store/index'
import { setUserNotes } from "store/modules/user"
import { setDataItems } from "store/modules/dataView"
import { loadPageSpecificData, SET_DICTIONARY } from "store/modules/backData"
import { setMemberSection } from "store/modules/selectedMember"
import { loadViewData } from "store/modules/dataView/dataView-dataLoader"
const elasticOverhead = 500; // milise
export const checkUser = (username) =>
    apiAction({
        endPoint: "/user/check",
        method: "POST",
        data: username,
        onSuccess: (data, dispatch) => {
            console.log("checkUser", data)
            const status = data?.status
            const email = username?.username
            //if suspended go to CriticalErrorScreen
            if (data.errorCode === "UserSuspended") {
                history.push("/CriticalErrorScreen")
            }

            else if (status === "success") {
                dispatch(setCrtFormItem({ username }))
                dispatch(setUserEmail(email))
                dispatch(setCustomObject({ loginUserName: true, loginUserMessage: "" }));
            }
            else {
                toastr.error(["User does not exist!"]);
                dispatch(setAllFieldErrors({ username: "User does not exist!" }))
                dispatch(setCustomObject({ loginUserName: false, loginUserMessage: "User does not exist!" }));
            }


            return {
                type: "a",
                payload: data
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const checkUserInRegistrationProcess = (userData) =>
    apiAction({
        endPoint: "/user/check",
        method: "POST",
        data: { username: userData?.email },
        onSuccess: (data, dispatch) => {
            console.log("checkUser", data)
            const status = data?.status
            if (status === "success") {
                toastr.error(["User already exists!"]);
                dispatch(setAllFieldErrors({ email: "User already exists" }))
            }
            else {
                dispatch(setCrtFormItem(userData))
                dispatch(setWizzardStep("comapnyDetails"))
                dispatch(setAllFieldErrors({}))
            }
            return {
                type: "a",
                payload: data
            }
        },
        onFailure: (data, dispatch) => {

            console.log("error occured custom")
            console.log(data)
            return { type: "a" }
        }
    })
export const login = (data, redirect = '/dashboard', dispatch) =>
    apiAction({
        endPoint: "/user/login",
        method: "POST",
        data,
        onSuccess: (data, dispatch) => {
            console.log("login", data)

            if (data.status === "success") {
                dispatch(setPageModal());
                const guestPage = false
                dispatch(loadPageSpecificData({ endPoint: `/screens/events${guestPage ? '-guest' : ''}`, id: 'dashboardPage' }))
                loginDataSetter(data, dispatch)

                // dispatch(setUserInfo(data?.profile))
                dispatch(setCrtFormItem())
                // dispatch(setBillingProfiles())
                dispatch(setUserProfile(data?.profile))

                toastr.success(["Logged in successfully!"])
                history.push(redirect)
            }
            else if (data.status === "error" && data.errorCode === "IncorrectPassword") {
                toastr.error([`Incorrect password! You have ${data.nrOfFailedLogins} attemps left!`])
                dispatch(setAllFieldErrors({ pin: "Wrong PIN Code. Please retry." }))

                dispatch(setCustomObject({ isError: true, error: `Incorrect password!`, type: "wrongPass" }));
            }
            else if (data.status === "error" && data.errorCode === "PasswordAttemptsExceeded") {
                toastr.error(["Password attempts limit exceeded! Please try again after 90 seconds!"])
                dispatch(setAllFieldErrors({ pin: "Too many attempts. Please try again after 90 seconds!." }))
                dispatch(setCustomObject({ isError: true, type: "limitExceeded", error: "Password attempts limit exceeded! Please try again after 90 seconds!" }));
            }
            // else if (data.status === "error" && data.errorCode === "UserNotFound"){
            //     toastr.error(["User does not exist!"])
            //     dispatch(setCustomObject({loginUserName: false, loginUserMessage: "User does not exist!"}));
            // } 
            return {
                type: SET_CRT_LIST_ITEMS,
                payload: {
                    items: {
                        userProfile: undefined
                    }
                }
            }
        },
        onFailure: (e) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const getVoucher = (voucherId) =>
    apiAction({
        endPoint: "/public/voucher",
        method: "POST",
        data: {
            voucherId
        },
        onSuccess: (data, dispatch) => {
            console.log("getVoucher", data)

            const status = data?.status
            const voucherObj = data?.voucher
            const voucherUser = data?.voucher?.userId
            const voucherId = data?.voucher?.PK

            const validVoucher = voucherUser === "" || voucherUser === undefined

            if (status === "success") {
                toastr.success(["Voucher applied successfully!"]);
                dispatch(setPageModal())
                dispatch(setAppliedVouchers([{ ...voucherObj }]))
                dispatch(setCrtFormItem({ voucherId, isVoucherValid: validVoucher }))
                dispatch(setCustomObject({ voucher: true, voucherMessage: "Add a discount code" }));
            }
            else {
                toastr.error(["Invalid voucher!"]);
                dispatch(setAppliedVouchers())
                dispatch(setAllFieldErrors({ voucher: "Add a discount code - Sorry. This seems like an invalid code" }))
                dispatch(setCrtFormItem({ isVoucherValid: validVoucher }))
                dispatch(setCustomObject({ voucher: false, voucherMessage: "Add a discount code - Sorry. This seems like an invalid code!" }));
            }

            return {
                type: "a",
                payload: data
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

const loginDataSetter = (data, dispatch) => {            
    const tokenData = {
        AccessToken: data?.tokens?.authToken,
        IdToken: data?.tokens?.authToken,
        refreshToken: data?.tokens?.refreshToken,
        email: data?.profile?.email,
        userData: data?.profile
    };
    localStorage.setItem("refreshToken", data?.tokens?.refreshToken);
    localStorage.setItem("authToken", data?.tokens?.authToken);
    dispatch({
        type: LOGIN_USER,
        payload: tokenData
    })

}
export const registerUser = (data) =>
    apiAction({
        endPoint: "/user/create",
        method: "POST",
        data: {
            ...data
        },
        onSuccess: (data, dispatch) => {
            console.log("registerUser", data)

            dispatch(setCrtFormItem({ ...data, userId: data?.profile?.PK, currentEmailCode: data?.profile?.currentEmailCode }))
            toastr.success(["Registered successfully!"])
            // history.push(`${history.location.pathname}?step=accountDetailsDone`)
            dispatch(setWizzardStep('accountDetailsDone'))
            dispatch(setUserInfo(data?.profile))
            loginDataSetter(data, dispatch)
            // else if (data.status === "error" && data.errorCode === "PasswordAttemptsExceeded") toastr.error(["Password attempts limit exceeded! Please try again after 90 seconds!"])

            return {
                type: "a",
                payload: data
            }
        },
        onFailure: (e, dispatch) => {
            if ((e.response.data.status === "error" && (e.response.data.code === "UserAlreadyExists"))) {
                toastr.error(["An account with the given email already exists!"])
            }
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const resendCode = (data) =>
    apiAction({
        endPoint: "/user/resendEmailCode",
        method: "POST",
        data: data,
        onSuccess: (item, dispatch) => {
            console.log("resendCode", data)

            dispatch(setCrtFormItem({ ...data, currentEmailCode: item?.currentEmailCode }))
            toastr.success("Code sent to your email" )
            return {
                type: "a",
                payload: item
            }
        },
        onFailure: (e, dispatch) => {
            toastr.error("There was an error. Please report error E109" )
            console.log("error occured custom E109")
            console.log(e)
            return {
                type: "a"
            }
        }
    })


export const assignTicket = (data) => {
    return apiAction({
        endPoint: "/shop/ticket/assign-ticket",
        method: "POST",
        data: data,
        label: "assignTicket",
        onSuccess: (item, dispatch) => {
            dispatch(setPageModalData())
            dispatch(setPageModal())
            toastr.success(["Tickets assigned successfully!"])

            const urlSearchParams = new URLSearchParams(window.location.search)
            const params = Object.fromEntries(urlSearchParams.entries())

            if (!params?.id) {
                setTimeout(() => {
                    dispatch(setDataItems([]))
                    dispatch(loadViewData())    
                }, elasticOverhead);
            } 
            else {
                dispatch(getTicketDetails())
            }

            history.push("/bm/transferred-tickets/list")
            return {
                type: "a",
                payload: item
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getInvitationData = (data) =>
    apiAction({
        endPoint: "/user/getInvitationData",
        method: "POST",
        data: data,
        onSuccess: (item, dispatch) => {
            dispatch(setInvitationData(item.data))
            return {
                type: "a",
                payload: item
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const emailTicket = (data) =>
    apiAction({
        endPoint: "/shop/ticket/email-ticket",
        method: "POST",
        data: data,
        onSuccess: (item, dispatch) => {
            // POORLY IMPL
            // NEEDS ERROR HANDLES
            // showMagicToaster({ title: "Ticket sent to your email" })
            return {
                type: "a",
                payload: item
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const downloadTicket = (data) =>
    apiAction({
        endPoint: "/shop/ticket/download-ticket",
        method: "POST",
        data: data,
        onSuccess: (item, dispatch) => {
            // poorly impl
            // needs error handles 
            if(item?.profile?.dld) {
                const link = document.createElement("a");
                link.href = "https://www.clickdimensions.com/links/TestPDFfile.pdf";
                link.target = "_blank"
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            return {
                type: "a",
                payload: item
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const claimTicket = (data) =>
    apiAction({
        endPoint: "/shop/ticket/claim-ticket",
        method: "POST",
        data: data,
        label: "revokeTicket",
        onSuccess: (item, dispatch) => {
            dispatch(setPageModalData())
            dispatch(setPageModal())

            toastr.success(["Ticket revoked successfully!"])

            const urlSearchParams = new URLSearchParams(window.location.search)
            const params = Object.fromEntries(urlSearchParams.entries())

            if (!params?.id)
                setTimeout(() => {
                    dispatch(setDataItems([]))
                    dispatch(loadViewData())    
                }, elasticOverhead);
            else
                dispatch(getTicketDetails())


            return {
                type: "a",
                payload: item
            }
        },
        onFailure: (e, dispatch) => {
            toastr.error(["Ticket couldn't be revoked"])
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

    export const checkInTicket = (data) => {
        return apiAction({
            endPoint: "/shop/ticket/checkin-ticket",
            method: "POST",
            data: data,
            label: "checkInTicket",
            onSuccess: (item, dispatch) => {
                dispatch(setPageModalData())
                dispatch(setPageModal())
                toastr.success(["Checked in successfully!"])

                const urlSearchParams = new URLSearchParams(window.location.search)
                const params = Object.fromEntries(urlSearchParams.entries())
    
                if (!params?.id)
                {
                    setTimeout(() => {
                        dispatch(setDataItems([]))
                        dispatch(loadViewData())    
                    }, elasticOverhead);
                } else {
                    dispatch(getTicketDetails())
                } 
                
                    
                return {
                    type: "a",
                    payload: item
                }
            },
            onFailure: (e, dispatch) => {
                console.log("error occured custom")
                console.log(e)
                return {
                    type: "a"
                }
            }
        })
    }

export const upgradeTicket = (data) =>
    apiAction({
        endPoint: "/shop2/cart/upgrade-ticket",
        method: "POST",
        data: data,
        label: "upgradeTicket",
        onSuccess: (item, dispatch) => {           
            dispatch(setPageModal())
            dispatch(setPageModalData())
            
            toastr.success(["Ticket upgrade added to cart!"])

            history.push("/shop/checkout")

            return {
                type: "a"
            }
        },
        onFailure: (e, dispatch) => {
            toastr.error(["Ticket upgrade couldn't be added to cart"])
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
})

export const consumeTicket = (data, eventId, sessionId) =>
    apiAction({
        endPoint: "/shop/ticket/use-ticket",
        method: "POST",
        data: data,
        label: "useTicket",
        onSuccess: (item, dispatch) => {           
            dispatch(setPageModal())
            dispatch(setPageModalData())

            history.push(`/events/${eventId}/stream/${sessionId}`)

            return {
                type: "a"
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
})

export const verifyUserCode = (data) =>
    apiAction({
        endPoint: "/user/verifyEmail",
        method: "POST",
        data: data,
        onSuccess: (item, dispatch) => {
            console.log("verifyUserCode", data)

            const userProfile = item?.profile
            const isCodeValid = item?.validUserCode

            if (isCodeValid) {
                const userData = { ...userProfile, PK: undefined, userId: userProfile?.PK, onboardingStep: "4" }
                dispatch(setCrtFormItem(userData))
                toastr.success(["Email verified successfully!"])
                // history.push("./register?step=userInterests");
                dispatch(setWizzardStep('userInterests'))

            }
            else toastr.error(["The code you provided is incorrect!"])

            return {
                type: "a",
                payload: item
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const addInterests = (data) =>
    apiAction({
        endPoint: "/user/interests",
        method: "POST",
        data: data,
        onSuccess: (item, dispatch) => {
            console.log("addInterests", data)

            const status = item?.status

            if (status === "success") {
                toastr.success(["Interests saved successfully!"])
                dispatch(setCrtFormItem())
                history.push('/dashboard')
            }
            else toastr.error(["Interests could not be saved!"])

            return {
                type: "a",
                payload: item
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const saveQuote = (quoteObj, crtListItems) => {
    const userId = store.getState()?.user?.profile?.PK
    
    return apiAction({
        endPoint: "/user/quote",
        method: "POST",
        data: quoteObj,
        label: "saveQuote",
        onSuccess: (data, dispatch) => {
            console.log("saveQuote", data)

            const newQuote = { author: quoteObj?.author, bgImage: quoteObj?.bgImage, quote: quoteObj?.quote }

            if (data?.status === "success") {
                toastr.success(["Quote saved successfully!"])
                dispatch(getUserProfileCompletion({userId: userId}))
                dispatch(getUserProfile({ fullProfile: true, selectKeys : "COMPLEX", coinsTx: true }))
                dispatch(setPageModal())
            }
            else toastr.error(["Quote was not saved!"])

            return {
                type: SET_CRT_LIST_ITEMS,
                payload: {
                    items: {
                        ...crtListItems,
                        quote: newQuote
                    }
                }
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const saveEducation = (education, existingEducations, crtListItems) =>
    apiAction({
        endPoint: "/user/education",
        method: "POST",
        data: education,
        label: "saveEducation",
        onSuccess: (data, dispatch) => {
            console.log("saveEducation", data)

            if (data?.status === "success") {
                toastr.success(["Education saved successfully!"])
                // dispatch(getUserProfile({ fullProfile: true }))
                dispatch(getUserSection("education"))
                dispatch(setPageModal())
            }
            else toastr.error(["Education was not saved!"])

            // existingEducations.push(education)

            return {
                type: SET_CRT_LIST_ITEMS,
                payload: {
                    items: {
                        ...crtListItems,
                        educations: existingEducations
                    }
                }
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
export const sendEventOperation = (data) =>
    apiAction({
        endPoint: "/event/operations",
        method: "POST",
        data,
        onSuccess: (data, dispatch) => {
            console.log("command sent", data)
            return {
                type: "a"
            }

        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const saveAchievement = (achievement, existingAchievements, crtListItems) =>
    apiAction({
        endPoint: "/user/achievement",
        method: "POST",
        data: achievement,
        label: "saveAchievement",
        onSuccess: (data, dispatch) => {
            console.log("saveAchievement", data)

            if (data?.status === "success") {
                toastr.success(["Achievement saved successfully!"])
                // dispatch(getUserProfile({ fullProfile: true }))
                dispatch(getUserSection("achievement"))

                dispatch(setPageModal())

            }
            else toastr.error(["Achievement was not saved!"])

            // existingAchievements.push(achievement)

            return {
                type: SET_CRT_LIST_ITEMS,
                payload: {
                    items: {
                        ...crtListItems,
                        achievements: existingAchievements
                    }
                }
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const getLinkedinProfiles = (keyword) =>
    apiAction({
        endPoint: "/general/linkedin/search",
        method: "POST",
        data: keyword,
        label: "getLinkedinProfiles",
        onSuccess: (data, dispatch) => {
            console.log("getLinkedinProfiles", data)
           
            return {
                type: data?.status === "success" ? SET_CRT_LIST_ITEMS : "a",
                payload: {
                    items: {profiles: data?.data}
                }
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const getLinkedinProfileDetails = (id) =>
    apiAction({
        endPoint: "/general/linkedin/details",
        method: "POST",
        data: id,
        label: `getLinkedinProfileDetails_${id?.id}`,
        onSuccess: (data, dispatch) => {
            console.log("getLinkedinProfileDetails", data)
            dispatch(setPageModal("importLinkedinStep2Modal")) 

            let payload = {};
            Object.keys(data?.data?.dictionary)?.forEach(dictionary => {               
                payload[dictionary] = data?.data?.dictionary?.[dictionary]?.map(e => ({...e, id: e.PK})) ?? [];
                dispatch({
                    type: SET_DICTIONARY,
                    payload,
                })
            })

            return dispatch({
                type: data?.status === "success" ? SET_CRT_LIST_ITEMS : "a",
                payload: {
                    items: {
                        education: data?.data?.education?.map(e => {e.imported = false; return e}),
                        experiences: data?.data?.experiences?.map(e => {e.imported = false; return e})
                    }
                }
            })
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const importLinkedin = (saveData) =>
    apiAction({
        endPoint: "/user/importLinkedin",
        method: "POST",
        data: saveData,
        onSuccess: (data, dispatch) => {
            console.log("importLinkedin", data)
      
            dispatch(setPageModal())
            dispatch(setPageModalData())
            toastr.success(["Linkedin data imported successfully!"])

            dispatch(getUserSection("education"))
            dispatch(getUserSection("achievement"))

            return {
                type: "a"
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const saveUserProfile = (profile) =>
    apiAction({
        endPoint: "/user/profile",
        method: "POST",
        data: profile,
        onSuccess: (data, dispatch) => {
            console.log("saveUserProfile", data)

            if (data?.status === "success") {
                toastr.success(["Profile updated successfully!"])
                
            }
            else toastr.error(["Profile was not updated!"])
            
            const updatedProfile = data?.profile
            if (updatedProfile.avatar_URL === "") {
                updatedProfile.avatar = "";
            }
            dispatch(getUserProfile({full:true, complexKeys:"label"}))
            // dispatch(setUserInfo(updatedProfile))
            dispatch(setPageModal())
            return {
                type: data?.status === "success" ? SET_CRT_LIST_ITEMS : "a",
                payload: {
                    items: {
                        userProfile: updatedProfile
                    }
                }
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const autocompleteSuggestions = (userInput) =>
    apiAction({
        endPoint: "/general/autocomplete",
        method: "POST",
        data: userInput,
        onSuccess: (data, dispatch) => {
            console.log("autocompleteSuggestions", data)

            return {
                type: SET_AUTOCOMPLETE_SUGGESTIONS,
                payload: data?.suggestions
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const getDictionary = (dictionaryType) =>
    apiAction({
        endPoint: "/public/dictionary",
        method: "POST",
        data: dictionaryType,
        onSuccess: (data, dispatch) => {
            console.log("getDictionary", data)

            return {
                type: SET_CRT_LIST_ITEMS,
                payload: {
                    items: { events: data.items }
                }
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })

export const getEventTickets = (eventId) => {
    // const eventTickets = store.getState()?.bm?.setEventTickets;
    // if (eventTickets?.length > 0) return {type:'a'}
    return apiAction({
        endPoint: "/event/authTickets",
        method: "POST",
        data: eventId,
        label: "eventTickets",
        onSuccess: (data, dispatch) => {
            console.log("getEventTickets", data)

            const tickets = data?.tickets
            dispatch(setTickets(tickets))

            return {
                type: "a",
                payload: data
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getMyEventTickets= (eventItem) => {
    return apiAction({
        endPoint: "/shop/ticket/event-tickets",
        method: "GET",
        data: eventItem,
        onSuccess: (data, dispatch) => {
            console.log("getMyEventTickets", data)
            dispatch(setDataItems(data.data));

            return {
                type: "a"
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getUserProfile = (userItem) => {
    setUserSection({})

    return apiAction({
        endPoint: `/user/profile`,
        method: "GET",
        data: userItem,
        onSuccess: (data, dispatch) => {
            console.log("getUserProfile", data)

            const userProfile = { ...data }
            dispatch({
                type: SET_USER_INTERESTS,
                payload: data?.profile?.interests_v3,
            })

            //dispatch(setUserProfile(data?.profile))
            //dispatch(setUserSection(data))
            //return setBmUserProfile(data)
            dispatch(setUserSection(data))
            return {
                type: "a"
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getUserProfileCompletion = (userItem) => {
    return apiAction({
        endPoint: `/user/profile/completion`,
        method: "GET",
        data: userItem,
        onSuccess: (data, dispatch) => {
            console.log("getUserProfileCompletion", data)

            return setUserSection({completion: data?.data})
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getUserProfileBuyStats = (userItem) => {
    return apiAction({
        endPoint: `/user/profile/buystats`,
        method: "GET",
        data: userItem,
        onSuccess: (data, dispatch) => {
            console.log("getUserProfileBuyStats", data)

            return setUserSection({buyStats: data?.data})
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getUserSection = (section) => {
    const asMember = store.getState()?.page?.asMember;
    let userId
	try {
		userId = new URLSearchParams(window.location.search)?.get("id")
	} catch { }
    //if (order !== undefined) return { type: 'a' }
    return apiAction({
        endPoint: asMember ? `/user/${section}?userPk=${userId}&asMember=true` :  `/user/${section}`,
        method: "GET",
        label: "userSection",
        // data: {userId},
        onSuccess: (data, dispatch) => {
            //  const userProfile = { ...data.profile }
            const sectionData = {}
            sectionData[section] = data?.profile ?? data?.data;
            if (asMember) return setMemberSection(sectionData)
            else return setUserSection(sectionData)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log({e})
            if(["USER_PROFILE_EDUCATION-NOT_FOUND",
                "CERTIFICATE-NOT_FOUND",
                "USER_PROFILE_BOOK-NOT_FOUND"
                ].includes(e?.response?.data?.code)){
                    dispatch(setUserSection({[section]:[]}))
                }
            return {
                type: "a"
            }
        }
    })
}

export const deleteUserSection = (item, section) => {
    return apiAction({
        endPoint: `/user/${section}`,
        method: "DELETE",
        data: {id: item.id},
        onSuccess: (data, dispatch) => {
            dispatch(getUserSection(section))

            return {type:"a"}
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            // console.log({e})
            // if(["USER_PROFILE_EDUCATION-NOT_FOUND",
            //     "CERTIFICATE-NOT_FOUND",
            //     "USER_PROFILE_BOOK-NOT_FOUND"
            //     ].includes(e?.response?.data?.code)){
            //         dispatch(setUserSection({[section]:[]}))
            //     }
            return {
                type: "a"
            }
        }
    })
}


export const getAllBooks = () => {
    const searchValue = store.getState()?.page?.crtList?.searchString

    return apiAction({
        endPoint: `/general/books${searchValue ? '?q=' + searchValue : ''}`,
        method: "GET",
        data: {},
        onSuccess: (data, dispatch) => {

            return setCrtList({ items: data.data })
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const addFavouriteBook = (bookId) => {
    return apiAction({
        endPoint: `/general/books`,
        method: "POST",
        data: { volumeId: bookId },
        onSuccess: (data, dispatch) => {

            return getUserSection("books")
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const removeFavouriteBook = (bookId) => {
    return apiAction({
        endPoint: `/user/books?id=${bookId}`,
        method: "DELETE",
        data: {},
        onSuccess: (data, dispatch) => {

            return getUserSection("books")
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getBillingProfiles = (props) => {
    const { billingProfileIdToSet } = props ?? {}
    return apiAction({
        endPoint: "/user/billingProfile",
        method: "GET",
        // data: { userId }, // the API will know the userId from the auth token
        onSuccess: (data, dispatch) => {
            console.log("getBillingProfiles", data)

            const billingProfiles = data?.billingProfiles
            // dispatch(setBillingProfiles(billingProfiles))
            setTimeout(() => {
                billingProfileIdToSet && dispatch(setBillingProfileId(billingProfileIdToSet))
            }, 200);

            return setBillingProfiles(billingProfiles)
            // return {  this will actually be dispatched
            //     type: "a",
            //     payload: data
            // }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getBillingProfilesForAccount = () => {
    return apiAction({
        endPoint: "/user/billingProfile?goupedBy=STATUS&archived=TRUE",
        method: "GET",
        label: "billingProfiles",
        // data: { userId }, // the API will know the userId from the auth token
        onSuccess: (data, dispatch) => {
            console.log("getBillingProfilesForAccount", data)

            const billingProfiles = data?.billingProfilesCategories

            return setBillingProfiles(billingProfiles)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const archiveBillingProfile = (billingProfileId) => {
    return apiAction({
        endPoint: "/user/billingProfile",
        method: "DELETE",
        data: {id: billingProfileId},
        label: `archiveBillingProfile_${billingProfileId}`,
        onSuccess: (data, dispatch) => {
            console.log("archiveBillingProfile", data)

            dispatch(setPageModal())
            return getBillingProfilesForAccount()
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const unarchiveBillingProfile = (billingProfileId) => {    
    return apiAction({
        endPoint: "/user/billingProfile?action=UNARCHIVE",
        method: "DELETE",
        data: {id: billingProfileId},
        label: `unarchiveBillingProfile_${billingProfileId}`,
        onSuccess: (data, dispatch) => {
            console.log("archiveBillingProfile", data)

            dispatch(setPageModal())
            return getBillingProfilesForAccount()
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const editBillingProfile = (billingProfileData, isAdd, section = "checkout") => {
    const order = store.getState()?.shop?.order;
    return apiAction({
        endPoint: "/user/billingProfile",
        method: "POST",
        data: billingProfileData,
        label: "editBillingProfile",
        onSuccess: (data, dispatch) => {
            console.log("editBillingProfile", data)
            const newBillingProfileSK = data?.billingProfile?.SK
            const status = data?.status

            if (isAdd && section === "orderDetails") {
                const orderItem = {
                    id: order?.id,
                    billingProfileSK: newBillingProfileSK
                }
                dispatch(updateOrderDetails(orderItem));
            }
            if (section === "account") {
                dispatch(setPageModal())
                dispatch(getBillingProfilesForAccount())
            }

            if (status === "success") {
                dispatch(setPageModal())
                dispatch(getBillingProfiles({ billingProfileIdToSet: newBillingProfileSK }))
                //dispatch(setCrtFormItem(billingProfileData)) // what do we use this for? if you get it refer to me on Slack :)
                toastr.success([`Billing profile ${isAdd ? "saved" : "edited"} successfully!`])
            }
            else toastr.error([`Billing profile could not be ${isAdd ? "saved" : "edited"}!`])

            return {
                type: "a",
                payload: data
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getOrderDetails = (orderItem) => {
    return apiAction({
        endPoint: "/shop/order/details",
        method: "POST",
        data: orderItem,
        onSuccess: (data, dispatch) => {
            console.log("getOrderDetails", data)

            // const order = { order: data.orderDetails, events: data.events }
            return setOrder(data.orderDetails)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getOrderDetailsForShop = (orderItem) => {
    if (!orderItem?.id)
        return { type: 'a' }
    return apiAction({
        endPoint: "/shop/order/details",
        method: "POST",
        data: orderItem,
        onSuccess: (data, dispatch) => {
            console.log("getOrderDetailsForShop", data)

            return setOrder(data.orderDetails)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getTicketDetails = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (!params?.id) {
        toastr.error(["Something went wrong"])
        return { type: 'a' }
    }

    const data = {id: params?.id}

    return apiAction({
        endPoint: "/event/tickets",
        method: "GET",
        data,
        onSuccess: (data, dispatch) => {
            console.log("getTicketDetails", data)
            return setTicketDetails(data?.profile)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const hasSessionAccess = (sessionId) => {
    return apiAction({
        endPoint: `/prod/general/cloudFrontSigner?sessionId=${sessionId}`,
        method: "GET",
        relativeUrl: true,
        label: "hasSessionAccess",
        onSuccess: (data, dispatch) => {
            console.log("hasSessionAccess", data)

            dispatch(setReplayAccess("OK"))
            return {
                type: "a"
            }

        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)

            const replayAccess = e?.response?.data?.code ?? "ERROR"
            
            dispatch(setReplayAccess(replayAccess))
            return {
                type: "a"
            }
        }
    })
}

export const updateOrderDetails = (orderToUpdate) => {
    return apiAction({
        endPoint: "/shop/order/update",
        method: "POST",
        data: orderToUpdate,
        onSuccess: (data, dispatch) => {
            console.log("updateOrderDetails", data)

            //const order = { order: data.orderDetails, events: data.events }
            //dispatch(getOrderDetails({id: orderToUpdate?.id}))
            return getOrderDetails({id: orderToUpdate?.id})
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getInvoiceDetails = (invoiceItem) => {
    return apiAction({
        endPoint: "/shop/invoice/details",
        method: "POST",
        data: invoiceItem,
        onSuccess: (data, dispatch) => {
            console.log("getInvoiceDetails", data)
            return setInvoiceDetails(data.data)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getInvoices = (invoiceItem) => {
    return apiAction({
        endPoint: "/user/invoices",
        method: "GET",
        data: invoiceItem,
        label: "MY_INVOICES_PAGE",
        onSuccess: (data, dispatch) => {
            dispatch(setDataItems(data.data));

            return setInvoices(data.data)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getOrders = (orderItem) => {
    return apiAction({
        endPoint: "/shop/order/list",
        method: "GET",
        data: orderItem,
        label: "MY_ORDERS_PAGE",
        onSuccess: (data, dispatch) => {
            dispatch(setDataItems(data.data));

            return setOrders(data.data)
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getEvents = () => {
    return apiAction({
        endPoint: "/event/list",
        method: "GET",
        onSuccess: (data, dispatch) => {
            dispatch(setDataItems(data.events));

            return {
                type: "a"
            }
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getEventDetails = (eventId) => {
    // const event = store.getState()?.bm?.selectedEventDetails;
    // if (event !== undefined) return { type: 'a' }
    const guestPage = window.location.pathname?.includes('guest') || window.location.pathname?.includes('frame')

    return apiAction({
        endPoint: `/screens/events${guestPage ? '-guest' : ''}?PK=${eventId}`,
        method: "GET",
        onSuccess: (data, dispatch) => {
            console.log("getEvent", data)

            return setSelectedEventDetails({ ...data })
        },
        onFailure: (e, dispatch) => {
            console.log("error occured custom")
            console.log(e)
            return {
                type: "a"
            }
        }
    })
}

export const getUserNotes = (sessionId) => {
    let url = `/user/notes`
    if (!!sessionId)
        url += `?sessionId=${sessionId}`
        
    return apiAction({    
        endPoint: url,
        method: "GET",
        onSuccess: (data, dispatch) => {
            console.log("getUserNotes", data)
            dispatch(setUserNotes(data?.notes))
            // dispatch(setUserNotes(data?.notes?.[0]?.notes))

            return {
                type: "a"
            }
        },
        onFailure: () => {
            console.log("error occured custom");
            return {
                type: "a"
            };
        }

    })
}

export const saveUserNote = (data, isFromLive=true) => {
    const sessionId = data?.sessionId
    return apiAction({
        endPoint: `/user/notes`,
        method: "POST",
        data,
        onSuccess: (data, dispatch) => {
            if (isFromLive)
                dispatch(getUserNotes(sessionId))
            else {
                dispatch(getUserNotes())
                dispatch(setPageModal())
            }
            return {
                type: 'a',
            };
        },
        onFailure: () => {
            console.log("error occured custom");
            return {
                type: "a"
            };
        }
    });
}

export const deleteUserNote = (data, isFromLive=true) => {
    const sessionId = data?.SK?.split("#")?.[1]
    return apiAction({
        endPoint: `/user/notes`,
        method: "DELETE",
        data,
        onSuccess: (data, dispatch) => {
            if (isFromLive)           
                dispatch(getUserNotes(sessionId))
            else {
                dispatch(getUserNotes())
                dispatch(setPageModal())
            }
            return {
                type: 'a',
            };
        },
        onFailure: () => {
            console.log("error occured custom");
            return {
                type: "a"
            };
        }
    });
}

export const saveUserPrivacy = (data) => {
    const profile = store.getState()?.user?.profile;
    return apiAction({
        endPoint: `/user/profile/privacy`,
        method: "POST",
        data,
        onSuccess: (data, dispatch) => {
            let newProfile = {...profile}
            newProfile.privacyDetails = data?.data
            dispatch(setUserSection({profile: newProfile}))
            return {
                type: 'a',
            };
        },
        onFailure: () => {
            console.log("error occured custom");
            return {
                type: "a"
            };
        }
    });
}

export const resetPassword = (data) => apiAction({
    endPoint: `/user/resetPassword`,
    method: "POST",
    data,
    onSuccess: (data, dispatch) => {
        console.log("resetPassword", data)

        const { status, successCode } = data
        if (status === "success" && successCode === "PasswordReset") {
            // setEmailCodeStep(true)
            dispatch(setWizzardStep('code'))
            toastr.success(["Password reset successfully!"])
        }
        else if (status === "success" && successCode === "PinChangedSuccessfully") {
            toastr.success(["Password reset successfully!"])
        }
        else if (status === "success" && successCode === "PasswordChangedSuccessfully") {
            toastr.success(["Password set successfully!"])
            history.push('/login')
        }
        else {
            toastr.error(["Password reset failed!"])
        }

        return {
            type: 'a',
        };
    },
    onFailure: () => {
        console.log("error occured custom");
        return {
            type: "a"
        };
    }
});

export const deleteMyAccount = () => apiAction({
    endPoint: "/user/delete",
    method: "DELETE",
    onSuccess: (data, dispatch) => {
        dispatch(logoutUser())
        history.push(`/login`)
        
        return {
            type: 'a',
        };
    },
    onFailure: () => {
        console.log("error occured custom");
        return {
            type: "a"
        };
    }
});

export const checkResetPasswordCode = (data) => apiAction({
    endPoint: '/user/resetPasswordCode',
    method: "POST",
    data,
    onSuccess: (data, dispatch) => {
        console.log("checkResetPasswordEmailCode", data)

        const { status } = data
        if (status === "success") {
            dispatch(setWizzardStep('pin'))
            // setPinStep(true)
            // setEmailCodeStep(false)
            toastr.success(["Email code is correct!"])
        }
        else {
            toastr.error(["Incorrect code!"])
        }

        return {
            type: 'a',
        };
    },
    onFailure: () => {
        console.log("error occured custom");
        return {
            type: "a"
        };
    }
});



export const sendTestNotification = (data) =>
    apiAction({
        endPoint: "/user/sendPushNotification",
        method: "POST",
        data,
        // {
        //     "body": "The body of this notification. Some good amount of text goes in here.", 
        //     "title": "I hope for an 88 badge also...", 
        //     "userPk": "USR-aKkqnLrHSBsO",
        //     "bmType": "type1",
        //      "sound": "true",
        //      "badge": 88
        //   },
        onSuccess: (item, dispatch) => {
            showMagicToaster({ title: "Sent!" })
            return {type: "a",}
        }
    })
