const orderItem1 = {
  id: 1,
  orderDate: "2021-03-19T13:01:06.835Z",
  tranzactiStatus: {
    delivered: "Livrata pe ",
    date: "2021-03-19T13:01:06.835Z",
    address: "La Str. Clucerului 12, Bucuresti district 9"
  },
  orderID: "1154877",
  eventName: "Brand Minds",
  eventDate: "2021-03-19T13:01:06.835Z",
  ticketNumber: "3",
  orderNumber: "2852",
  name: "Ursulache Remusache",
  billingProfile: "Billing Profile 1",
  orderStatus: "Paid",
  paymanetType: "Bank transfer"
}

const orderItem2 = {
  id: 2,
  orderDate: "2021-03-19T13:01:06.835Z",
  tranzactiStatus: {
    delivered: "Livrata pe ",
    date: "2021-03-19T13:01:06.835Z",
    address: "La Str. Clucerului 12, Bucuresti district 9"
  },
  orderID: "1154877",
  eventName: "Brand Minds",
  eventDate: "2021-03-19T13:01:06.835Z",
  ticketNumber: "3",
  orderNumber: "2852",
  name: "Ursulache Remusache",
  billingProfile: "Billing Profile 1",
  orderStatus: "Pending Payment",
  paymanetType: "Card"
}

const orderItem3 = {
  id: 3,
  orderDate: "2021-03-19T13:01:06.835Z",
  tranzactiStatus: {
    delivered: "Livrata pe ",
    date: "2021-03-19T13:01:06.835Z",
    address: "La Str. Clucerului 12, Bucuresti district 9"
  },
  orderID: "1154877",
  eventName: "Brand Minds",
  eventDate: "2021-03-19T13:01:06.835Z",
  ticketNumber: "3",
  orderNumber: "2852",
  name: "Ursulache Remusache",
  orderStatus: "Paid",
  billingProfile: "Billing Profile 1",
  paymanetType: "Cash"
}

const orderItem4 = {
  id: 4,
  orderDate: "2021-03-19T13:01:06.835Z",
  tranzactiStatus: {
    delivered: "Livrata pe ",
    date: "2021-03-19T13:01:06.835Z",
    address: "La Str. Clucerului 12, Bucuresti district 9"
  },
  orderID: "1154877",
  eventName: "Brand Minds",
  eventDate: "2021-03-19T13:01:06.835Z",
  ticketNumber: "3",
  billingProfile: "Billing Profile 1",
  orderNumber: "2852",
  name: "Ursulache Remusache",
  orderStatus: "other",
  paymanetType: "Bank transfer"
}

const orderTableData = {
  header: {
    text1: "Order date",
    text2: "Event",
    text3: "Tickets",
    text4: "Amount",
    text5: "Billing Profile",
    text6: "Purchased by",
    text7: "Payment type",
    text8: "Status",
    //text9: "Actiuni"
  },
  contents: [orderItem1, orderItem2, orderItem3, orderItem4]
}
export default orderTableData;
