import Btn from "componentsBM/Btn"
import SvgIcon from "componentsBM/SvgIcon"
import { sideMenuOpenMock } from "./menuMock"
import MenuLevel from "./MenuLevel"
import { useDispatch, useSelector } from "react-redux"
import { closeMenuPanel } from "store/justActions/menuInteractions"
import sideMenuSelector from "./pageSelector"
import { setPageModal } from "store/modules/page"

function SideMenuOpenPanel(props) {
    const {sidePanelData} = useSelector(state => sideMenuSelector(state))

    // const mainMenuButtons = useSelector(state => state.page?.menuSidePanel)
    const {title, hero, entries} = sidePanelData ?? {}
    const dispatch = useDispatch()
    // const closeBtn = <SvgIcon name="close"/>
    const heroSection = <div className="panel-top">
            <div className="mb2 ptype1-strong">{hero?.title}</div>
            {hero?.img && <img src={hero?.img} alt="main"/>}
            <div className="mb2 ptype0 color-gray-20">{hero?.subtitle}</div>
            <div className="ptype0-strong ">{hero?.description}</div>
            <div className="mt4 flex gap6">
                {hero?.actions?.primary && <Btn {...hero?.actions?.primary}/>}
                {hero?.actions?.secondary && <Btn {...hero?.actions?.secondary}/>}
            </div>
        </div>
    // const bottomSection = <div>bottom section</div>

    const deleteMyAccount = () => {
        dispatch(setPageModal("deleteMyAccount"))
        dispatch(closeMenuPanel())
    }
    return (
        <div className="side-menu-open-panel">
            <div className="left">
                <div className="left__scroll-wrapper">
                    <div className="title">
                        <div className="etype-5">{title}</div>
                        <div onClick={()=> dispatch(closeMenuPanel())}>
                            {closeBtn}
                            </div>
                    </div>
                    {heroSection}
                    {hero?.description && spacer}
                    <div className="side-menu-bottom-section">
                        {entries?.map((item, index) => <MenuLevel {...{item, level:1, key:index}}/>)}
                    </div>
                    {/* <SideMenuBottomSection {...props}/> */}

                    {sidePanelData?.id === 'account' &&
                        <Btn small special="alert" label="Delete my account" onClick={()=> deleteMyAccount()}/>
                    }
                </div>
            </div>
            <div className="right" onClick={()=> dispatch(closeMenuPanel())}/>
        </div>
    )
}

export default SideMenuOpenPanel

const spacer = <svg className="spacer" width="421" height="2" viewBox="0 0 421 2" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 1H421" stroke="#666666" strokeDasharray="8 8"/>
</svg>

const closeBtn = <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#333333"/>
<path d="M35 21L21 35" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M35 35L21 21" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
