// import React, { Suspense, lazy} from 'react';
import React from 'react'

// A
import { ReactComponent as AddClear } from 'views/dev/gyula/BrandMinds/assets/icons/Add-clear.svg'
import { ReactComponent as AddTicket } from 'views/dev/gyula/BrandMinds/assets/icons/addticket.svg'
import { ReactComponent as ArrowRightClear } from 'views/dev/gyula/BrandMinds/assets/icons/ArrowRightClear.svg'
import { ReactComponent as ArrowLeftClear } from 'views/dev/gyula/BrandMinds/assets/icons/ArrowLeftClear.svg'
import { ReactComponent as ArrowUp } from 'views/dev/gyula/BrandMinds/assets/icons/ArrowUp.svg'
import { ReactComponent as ArrowDown } from 'views/dev/gyula/BrandMinds/assets/icons/ArrowDown.svg'
// import { ReactComponent as Add } from 'views/dev/gyula/BrandMinds/assets/icons/Add.svg'
// import { ReactComponent as AddRound } from 'views/dev/gyula/BrandMinds/assets/icons/AddRound.svg'
// import { ReactComponent as Address2 } from 'views/dev/gyula/BrandMinds/assets/icons/Address2.svg'
// import { ReactComponent as Address3 } from 'views/dev/gyula/BrandMinds/assets/icons/Address3.svg'
// import { ReactComponent as AddToList } from 'views/dev/gyula/BrandMinds/assets/icons/AddToList.svg'
// import { ReactComponent as Alert } from 'views/dev/gyula/BrandMinds/assets/icons/Alert.svg'
// import { ReactComponent as Apps } from 'views/dev/gyula/BrandMinds/assets/icons/Apps.svg'
// import { ReactComponent as ArrowLeft } from 'views/dev/gyula/BrandMinds/assets/icons/ArrowLeft.svg'
// import { ReactComponent as ArrowRight } from 'views/dev/gyula/BrandMinds/assets/icons/ArrowRight.svg'
// import { ReactComponent as ATM } from 'views/dev/gyula/BrandMinds/assets/icons/ATM.svg'
// import { ReactComponent as Attach } from 'views/dev/gyula/BrandMinds/assets/icons/Attach.svg'
// import { ReactComponent as Audit } from 'views/dev/gyula/BrandMinds/assets/icons/Audit.svg'
// B
import { ReactComponent as Badge } from 'views/dev/gyula/BrandMinds/assets/icons/Badge.svg'
import { ReactComponent as BadgeWhite } from 'views/dev/gyula/BrandMinds/assets/icons/BadgeWhite.svg'
import { ReactComponent as Briefcase } from 'views/dev/gyula/BrandMinds/assets/icons/Briefcase.svg'
import { ReactComponent as BriefcaseClear } from 'views/dev/gyula/BrandMinds/assets/icons/Briefcase-clear.svg'
import { ReactComponent as Bell } from 'views/dev/gyula/BrandMinds/assets/icons/Bell.svg'
import { ReactComponent as BusinessClear } from 'views/dev/gyula/BrandMinds/assets/icons/Business-clear.svg'
import { ReactComponent as BillingProfileWhite } from 'views/dev/gyula/BrandMinds/assets/icons/BillingProfileWhite.svg'
import { ReactComponent as BMpoints } from 'views/dev/gyula/BrandMinds/assets/icons/BMpoints.svg'
// import { ReactComponent as Bag } from 'views/dev/gyula/BrandMinds/assets/icons/Bag.svg'
// import { ReactComponent as BagDistribution } from 'views/dev/gyula/BrandMinds/assets/icons/BagDistribution.svg'
// import { ReactComponent as BagHandover } from 'views/dev/gyula/BrandMinds/assets/icons/BagHandover.svg'
// import { ReactComponent as BagReceive } from 'views/dev/gyula/BrandMinds/assets/icons/BagReceive.svg'
// import { ReactComponent as BagReception } from 'views/dev/gyula/BrandMinds/assets/icons/BagReception.svg'
// import { ReactComponent as Bank } from 'views/dev/gyula/BrandMinds/assets/icons/Bank.svg'
// import { ReactComponent as BillingProfile } from 'views/dev/gyula/BrandMinds/assets/icons/BillingProfile.svg'
// import { ReactComponent as Block } from 'views/dev/gyula/BrandMinds/assets/icons/Block.svg'
// import { ReactComponent as BMcontacts } from 'views/dev/gyula/BrandMinds/assets/icons/BMcontacts.svg'
// import { ReactComponent as BMinvite } from 'views/dev/gyula/BrandMinds/assets/icons/BMinvite.svg'
// import { ReactComponent as BMstage } from 'views/dev/gyula/BrandMinds/assets/icons/BMstage.svg'
// import { ReactComponent as BMvoucher } from 'views/dev/gyula/BrandMinds/assets/icons/BMvoucher.svg'
// import { ReactComponent as Box } from 'views/dev/gyula/BrandMinds/assets/icons/Box.svg'
// import { ReactComponent as Books } from 'views/dev/gyula/BrandMinds/assets/icons/Books.svg'
// import { ReactComponent as Business } from 'views/dev/gyula/BrandMinds/assets/icons/Business.svg'
// C
import { ReactComponent as ChevronDown } from 'views/dev/gyula/BrandMinds/assets/icons/ChevronDown.svg'
import { ReactComponent as CreateTask } from 'views/dev/gyula/BrandMinds/assets/icons/CreateTask.svg'
import { ReactComponent as ChevronUp } from 'views/dev/gyula/BrandMinds/assets/icons/ChevronUp.svg'
import { ReactComponent as CallClear } from 'views/dev/gyula/BrandMinds/assets/icons/Call-clear.svg'
// import { ReactComponent as Calendar } from 'views/dev/gyula/BrandMinds/assets/icons/Calendar.svg'
// import { ReactComponent as Camera } from 'views/dev/gyula/BrandMinds/assets/icons/Camera.svg'
// import { ReactComponent as Call } from 'views/dev/gyula/BrandMinds/assets/icons/Call.svg'
// import { ReactComponent as Cancel } from 'views/dev/gyula/BrandMinds/assets/icons/Cancel.svg'
// import { ReactComponent as Career } from 'views/dev/gyula/BrandMinds/assets/icons/Career.svg'
// import { ReactComponent as CartAdd } from 'views/dev/gyula/BrandMinds/assets/icons/CartAdd.svg'
// import { ReactComponent as CartEmpty } from 'views/dev/gyula/BrandMinds/assets/icons/CartEmpty.svg'
// import { ReactComponent as CartFull } from 'views/dev/gyula/BrandMinds/assets/icons/CartFull.svg'
// import { ReactComponent as Casete } from 'views/dev/gyula/BrandMinds/assets/icons/Casete.svg'
// import { ReactComponent as Cash } from 'views/dev/gyula/BrandMinds/assets/icons/Cash.svg'
// import { ReactComponent as Chat } from 'views/dev/gyula/BrandMinds/assets/icons/Chat.svg'
// import { ReactComponent as Chats } from 'views/dev/gyula/BrandMinds/assets/icons/Chats.svg'
// import { ReactComponent as CheckList } from 'views/dev/gyula/BrandMinds/assets/icons/Checklist.svg'
// import { ReactComponent as Copy } from 'views/dev/gyula/BrandMinds/assets/icons/Copy.svg'
// import { ReactComponent as Cup } from 'views/dev/gyula/BrandMinds/assets/icons/Cup.svg'
// import { ReactComponent as Certificate } from 'views/dev/gyula/BrandMinds/assets/icons/Certificate.svg'
// D
// import { ReactComponent as DarkModeMoon } from 'views/dev/gyula/BrandMinds/assets/icons/DarkModeMoon.svg'
// import { ReactComponent as Dashboard } from 'views/dev/gyula/BrandMinds/assets/icons/Dashboard.svg'
// import { ReactComponent as DashboardOmeter } from 'views/dev/gyula/BrandMinds/assets/icons/DashboardOmeter.svg'
// import { ReactComponent as Deals } from 'views/dev/gyula/BrandMinds/assets/icons/Deals.svg'
// import { ReactComponent as Deals2 } from 'views/dev/gyula/BrandMinds/assets/icons/Deals2.svg'
// import { ReactComponent as Delete } from 'views/dev/gyula/BrandMinds/assets/icons/Delete.svg'
import { ReactComponent as DefaultUserImage } from 'views/dev/gyula/BrandMinds/assets/icons/Default-user-image.svg'
import { ReactComponent as DeleteClear } from 'views/dev/gyula/BrandMinds/assets/icons/DeleteClear.svg'
import { ReactComponent as Documents3White } from 'views/dev/gyula/BrandMinds/assets/icons/Documents3-white.svg'
import { ReactComponent as DocumentsClear } from 'views/dev/gyula/BrandMinds/assets/icons/Documents-clear.svg'
import { ReactComponent as Documents3clear } from 'views/dev/gyula/BrandMinds/assets/icons/Documents3-clear.svg'
import { ReactComponent as DoneClear } from 'views/dev/gyula/BrandMinds/assets/icons/DoneClear.svg'
import { ReactComponent as DoneCheck } from 'views/dev/gyula/BrandMinds/assets/icons/DoneCheck.svg'
import { ReactComponent as DeleteGrey } from 'views/dev/gyula/BrandMinds/assets/icons/DeleteGrey.svg'
// import { ReactComponent as Delivery } from 'views/dev/gyula/BrandMinds/assets/icons/Delivery.svg'
// import { ReactComponent as Details } from 'views/dev/gyula/BrandMinds/assets/icons/Details.svg'
// import { ReactComponent as Documents } from 'views/dev/gyula/BrandMinds/assets/icons/Documents.svg'
// import { ReactComponent as Documents2 } from 'views/dev/gyula/BrandMinds/assets/icons/Documents2.svg'
// import { ReactComponent as Documents3 } from 'views/dev/gyula/BrandMinds/assets/icons/Documents3.svg'
// import { ReactComponent as Done } from 'views/dev/gyula/BrandMinds/assets/icons/Done.svg'
// import { ReactComponent as Download } from 'views/dev/gyula/BrandMinds/assets/icons/Download.svg'
// E
// import { ReactComponent as Edit } from 'views/dev/gyula/BrandMinds/assets/icons/Edit.svg'
import { ReactComponent as EditWhite } from 'views/dev/gyula/BrandMinds/assets/icons/EditWhite.svg'
// import { ReactComponent as Email } from 'views/dev/gyula/BrandMinds/assets/icons/Email.svg'
import { ReactComponent as EmailClear } from 'views/dev/gyula/BrandMinds/assets/icons/Email-clear.svg'
// import { ReactComponent as Equipment } from 'views/dev/gyula/BrandMinds/assets/icons/Equipment.svg'
// import { ReactComponent as Expand } from 'views/dev/gyula/BrandMinds/assets/icons/Expand.svg'
import { ReactComponent as Education } from 'views/dev/gyula/BrandMinds/assets/icons/Education.svg'
// F
// import { ReactComponent as Filter } from 'views/dev/gyula/BrandMinds/assets/icons/Filter.svg'
// import { ReactComponent as FilterOn } from 'views/dev/gyula/BrandMinds/assets/icons/FilterOn.svg'
// import { ReactComponent as Flag } from 'views/dev/gyula/BrandMinds/assets/icons/Flag.svg'
//G
import { ReactComponent as GoldCoin } from 'views/dev/gyula/BrandMinds/assets/icons/GoldCoin.svg'
// H
// import { ReactComponent as Heart } from 'views/dev/gyula/BrandMinds/assets/icons/Heart.svg'
import { ReactComponent as History } from 'views/dev/gyula/BrandMinds/assets/icons/History.svg'
import { ReactComponent as Home } from 'views/dev/gyula/BrandMinds/assets/icons/Home.svg'
// I
// import { ReactComponent as ID } from 'views/dev/gyula/BrandMinds/assets/icons/ID.svg'
import { ReactComponent as Ideea } from 'views/dev/gyula/BrandMinds/assets/icons/Ideea.svg'
import { ReactComponent as Interests } from 'views/dev/gyula/BrandMinds/assets/icons/Interests.svg'
// import { ReactComponent as InviteUser } from 'views/dev/gyula/BrandMinds/assets/icons/InviteUser.svg'
// import { ReactComponent as Invoice } from 'views/dev/gyula/BrandMinds/assets/icons/Invoice.svg'
// import { ReactComponent as InvoiceDollar } from 'views/dev/gyula/BrandMinds/assets/icons/InvoiceDollar.svg'
// J
// import { ReactComponent as Jobs1 } from 'views/dev/gyula/BrandMinds/assets/icons/Jobs1.svg'
// import { ReactComponent as Jobs2 } from 'views/dev/gyula/BrandMinds/assets/icons/Jobs2.svg'
// K
// import { ReactComponent as KanBan } from 'views/dev/gyula/BrandMinds/assets/icons/KanBan.svg'
// L
import { ReactComponent as LanguageRO } from 'views/dev/gyula/BrandMinds/assets/icons/LanguageRO.svg'
// import { ReactComponent as Leader } from 'views/dev/gyula/BrandMinds/assets/icons/Leader.svg'
// import { ReactComponent as LightModeSun } from 'views/dev/gyula/BrandMinds/assets/icons/LightModeSun.svg'
// import { ReactComponent as Link1 } from 'views/dev/gyula/BrandMinds/assets/icons/Link1.svg'
import { ReactComponent as Link2 } from 'views/dev/gyula/BrandMinds/assets/icons/Link2.svg'
// import { ReactComponent as List } from 'views/dev/gyula/BrandMinds/assets/icons/List.svg'
import { ReactComponent as ListBm } from 'views/dev/gyula/BrandMinds/assets/icons/ListBm.svg'
// import { ReactComponent as ListModels } from 'views/dev/gyula/BrandMinds/assets/icons/ListModels.svg'
// import { ReactComponent as ListOfBags } from 'views/dev/gyula/BrandMinds/assets/icons/ListOfBags.svg'
// import { ReactComponent as LiveVideo } from 'views/dev/gyula/BrandMinds/assets/icons/LiveVideo.svg'
// import { ReactComponent as Location } from 'views/dev/gyula/BrandMinds/assets/icons/Location.svg'
// import { ReactComponent as LogOut } from 'views/dev/gyula/BrandMinds/assets/icons/LogOut.svg'
import { ReactComponent as LogOutClear } from 'views/dev/gyula/BrandMinds/assets/icons/LogOutClear.svg'
import { ReactComponent as Like2} from 'views/dev/gyula/BrandMinds/assets/icons/Like2.svg'
import { ReactComponent as Lock} from 'views/dev/gyula/BrandMinds/assets/icons/Lock.svg'
// M 
// import { ReactComponent as MagicLink } from 'views/dev/gyula/BrandMinds/assets/icons/MagicLink.svg'
// import { ReactComponent as ManageUsers } from 'views/dev/gyula/BrandMinds/assets/icons/ManageUsers.svg'
// import { ReactComponent as Maps } from 'views/dev/gyula/BrandMinds/assets/icons/Maps.svg'
import { ReactComponent as Match } from 'views/dev/gyula/BrandMinds/assets/icons/Match.svg'
// import { ReactComponent as Menu } from 'views/dev/gyula/BrandMinds/assets/icons/Menu.svg'
// import { ReactComponent as MinWeight } from 'views/dev/gyula/BrandMinds/assets/icons/MinWeight.svg'
import { ReactComponent as MoreHorizontal } from 'views/dev/gyula/BrandMinds/assets/icons/MoreHorizontal.svg'
import { ReactComponent as MoreHorizontalClear } from 'views/dev/gyula/BrandMinds/assets/icons/MoreHorizontal-clear.svg'
// import { ReactComponent as Move } from 'views/dev/gyula/BrandMinds/assets/icons/Move.svg'
// N
// import { ReactComponent as NewTab } from 'views/dev/gyula/BrandMinds/assets/icons/NewTab.svg'
// import { ReactComponent as NewTrey } from 'views/dev/gyula/BrandMinds/assets/icons/NewTrey.svg'
// import { ReactComponent as NotesImage } from 'views/dev/gyula/BrandMinds/assets/icons/NotesImage.svg'
// import { ReactComponent as NotesImageAndText } from 'views/dev/gyula/BrandMinds/assets/icons/NotesImageAndText.svg'
// import { ReactComponent as NotesText } from 'views/dev/gyula/BrandMinds/assets/icons/NotesText.svg'
// import { ReactComponent as Notifications } from 'views/dev/gyula/BrandMinds/assets/icons/Notifications.svg'
// O
// import { ReactComponent as OnlineStore } from 'views/dev/gyula/BrandMinds/assets/icons/OnlineStore.svg'
// P
// import { ReactComponent as PartialList } from 'views/dev/gyula/BrandMinds/assets/icons/PartialList.svg'
// import { ReactComponent as Password } from 'views/dev/gyula/BrandMinds/assets/icons/Password.svg'
// import { ReactComponent as Paste } from 'views/dev/gyula/BrandMinds/assets/icons/Paste.svg'
// import { ReactComponent as Pay1 } from 'views/dev/gyula/BrandMinds/assets/icons/Pay1.svg'
// import { ReactComponent as PayByCard } from 'views/dev/gyula/BrandMinds/assets/icons/PayByCard.svg'
// import { ReactComponent as PayByInvoice } from 'views/dev/gyula/BrandMinds/assets/icons/PayByInvoice.svg'
// import { ReactComponent as PayByTransfer } from 'views/dev/gyula/BrandMinds/assets/icons/PayByTransfer.svg'
import { ReactComponent as Play } from 'views/dev/gyula/BrandMinds/assets/icons/Play.svg'
// import { ReactComponent as Print } from 'views/dev/gyula/BrandMinds/assets/icons/Print.svg'
// import { ReactComponent as Privacy } from 'views/dev/gyula/BrandMinds/assets/icons/Privacy.svg'
import { ReactComponent as PrivacyClear } from 'views/dev/gyula/BrandMinds/assets/icons/PrivacyClear.svg'
// import { ReactComponent as ProcessStep } from 'views/dev/gyula/BrandMinds/assets/icons/ProcessStep.svg'
// import { ReactComponent as ProcessStep2 } from 'views/dev/gyula/BrandMinds/assets/icons/ProcessStep2.svg'
// import { ReactComponent as ProductMagazine } from 'views/dev/gyula/BrandMinds/assets/icons/ProductMagazine.svg'
// import { ReactComponent as Projects } from 'views/dev/gyula/BrandMinds/assets/icons/Projects.svg'
// import { ReactComponent as Promo } from 'views/dev/gyula/BrandMinds/assets/icons/Promo.svg'
// Q
import { ReactComponent as Quote } from 'views/dev/gyula/BrandMinds/assets/icons/Quote.svg'
// R
import { ReactComponent as Reload } from 'views/dev/gyula/BrandMinds/assets/icons/Reload.svg'
// import { ReactComponent as Rooms } from 'views/dev/gyula/BrandMinds/assets/icons/Rooms.svg'
import { ReactComponent as Room } from 'views/dev/gyula/BrandMinds/assets/icons/Room.svg'
// S
// import { ReactComponent as SaciColectori } from 'views/dev/gyula/BrandMinds/assets/icons/SaciColectori.svg'
import { ReactComponent as SaveDisk } from 'views/dev/gyula/BrandMinds/assets/icons/SaveDisk.svg'
// import { ReactComponent as ScanQR } from 'views/dev/gyula/BrandMinds/assets/icons/ScanQR.svg'
// import { ReactComponent as ScrollIco } from 'views/dev/gyula/BrandMinds/assets/icons/ScrollIco.svg'
// import { ReactComponent as Search } from 'views/dev/gyula/BrandMinds/assets/icons/Search.svg'
import { ReactComponent as SearchClear } from 'views/dev/gyula/BrandMinds/assets/icons/Search-clear.svg'
import { ReactComponent as Secure } from 'views/dev/gyula/BrandMinds/assets/icons/Secure.svg'
// import { ReactComponent as SendEmail } from 'views/dev/gyula/BrandMinds/assets/icons/SendEmail.svg'
// import { ReactComponent as Services } from 'views/dev/gyula/BrandMinds/assets/icons/Services.svg'
// import { ReactComponent as Settings } from 'views/dev/gyula/BrandMinds/assets/icons/Settings.svg'
// import { ReactComponent as Share } from 'views/dev/gyula/BrandMinds/assets/icons/Share.svg'
// import { ReactComponent as ShoppingBag } from 'views/dev/gyula/BrandMinds/assets/icons/ShoppingBag.svg'
// import { ReactComponent as SignIn } from 'views/dev/gyula/BrandMinds/assets/icons/SignIn.svg'
import { ReactComponent as Sms } from 'views/dev/gyula/BrandMinds/assets/icons/Sms.svg'
// import { ReactComponent as Sort } from 'views/dev/gyula/BrandMinds/assets/icons/Sort.svg'
// import { ReactComponent as Speaker } from 'views/dev/gyula/BrandMinds/assets/icons/Speaker.svg'
import { ReactComponent as SpeakerClear } from 'views/dev/gyula/BrandMinds/assets/icons/Speaker-clear.svg'
import { ReactComponent as Stage } from 'views/dev/gyula/BrandMinds/assets/icons/Stage.svg'
import { ReactComponent as Star } from 'views/dev/gyula/BrandMinds/assets/icons/Star.svg'
// import { ReactComponent as Stats } from 'views/dev/gyula/BrandMinds/assets/icons/Stats.svg'
// import { ReactComponent as Stock } from 'views/dev/gyula/BrandMinds/assets/icons/Stock.svg'
// import { ReactComponent as Subtract } from 'views/dev/gyula/BrandMinds/assets/icons/Subtract.svg'
// import { ReactComponent as Support } from 'views/dev/gyula/BrandMinds/assets/icons/Support.svg'
// T
// import { ReactComponent as Tabs } from 'views/dev/gyula/BrandMinds/assets/icons/Tabs.svg'
// import { ReactComponent as TaskList } from 'views/dev/gyula/BrandMinds/assets/icons/TaskList.svg'
// import { ReactComponent as TicketEvents } from 'views/dev/gyula/BrandMinds/assets/icons/TicketEvents.svg'
// import { ReactComponent as TicketManager } from 'views/dev/gyula/BrandMinds/assets/icons/TicketManager.svg'
// import { ReactComponent as Tickets } from 'views/dev/gyula/BrandMinds/assets/icons/Tickets.svg'
// import { ReactComponent as Ticket } from 'views/dev/gyula/BrandMinds/assets/icons/Tickets.svg'
// import { ReactComponent as TicketSmall } from "views/dev/gyula/BrandMinds/assets/icons/TicketSmall.svg";
// import { ReactComponent as Timer } from 'views/dev/gyula/BrandMinds/assets/icons/Timer.svg'
// import { ReactComponent as Tweak } from 'views/dev/gyula/BrandMinds/assets/icons/Tweak.svg'
// U
// import { ReactComponent as Undo } from 'views/dev/gyula/BrandMinds/assets/icons/Undo.svg'
// import { ReactComponent as Uniprest } from 'views/dev/gyula/BrandMinds/assets/icons/Uniprest.svg'
// import { ReactComponent as UniprestCompanoProducts } from 'views/dev/gyula/BrandMinds/assets/icons/UniprestCompanoProducts.svg'
// import { ReactComponent as UniprestDiscountPachete } from 'views/dev/gyula/BrandMinds/assets/icons/UniprestDiscountPachete.svg'
// import { ReactComponent as UniprestGlobalProducts } from 'views/dev/gyula/BrandMinds/assets/icons/UniprestGlobalProducts.svg'
// import { ReactComponent as UniprestGrilaDiscount } from 'views/dev/gyula/BrandMinds/assets/icons/UniprestGrilaDiscount.svg'
// import { ReactComponent as UniprestNeteNete } from 'views/dev/gyula/BrandMinds/assets/icons/UniprestNeteNete.svg'
// import { ReactComponent as UniprestPlus } from 'views/dev/gyula/BrandMinds/assets/icons/UniprestPlus.svg'
// import { ReactComponent as UniprestStaff } from 'views/dev/gyula/BrandMinds/assets/icons/UniprestStaff.svg'
// import { ReactComponent as UniprestVolumeDiscount } from 'views/dev/gyula/BrandMinds/assets/icons/UniprestVolumeDiscount.svg'
// import { ReactComponent as Upload } from 'views/dev/gyula/BrandMinds/assets/icons/Upload.svg'
// import { ReactComponent as User } from 'views/dev/gyula/BrandMinds/assets/icons/User.svg'
import { ReactComponent as UserWhite } from 'views/dev/gyula/BrandMinds/assets/icons/UserWhite.svg'
import { ReactComponent as UserAtComputer } from 'views/dev/gyula/BrandMinds/assets/icons/UserAtComputer.svg'
// import { ReactComponent as UserAtDesk } from 'views/dev/gyula/BrandMinds/assets/icons/UserAtDesk.svg'
import { ReactComponent as UserAtTable } from 'views/dev/gyula/BrandMinds/assets/icons/UserAtTable.svg'
// import { ReactComponent as UserRequests } from 'views/dev/gyula/BrandMinds/assets/icons/UserRequests.svg'
import { ReactComponent as UnassignedPurple } from 'views/dev/gyula/BrandMinds/assets/icons/UnassignedPurple.svg'
// V
// import { ReactComponent as Valuables } from 'views/dev/gyula/BrandMinds/assets/icons/Valuables.svg'
// import { ReactComponent as Video } from 'views/dev/gyula/BrandMinds/assets/icons/Video.svg'
import { ReactComponent as VideoClear } from 'views/dev/gyula/BrandMinds/assets/icons/Video-clear.svg'
import { ReactComponent as ViewLarge } from 'views/dev/gyula/BrandMinds/assets/icons/ViewLarge.svg'
import { ReactComponent as ViewMedium } from 'views/dev/gyula/BrandMinds/assets/icons/ViewMedium.svg'
import { ReactComponent as ViewSmall } from 'views/dev/gyula/BrandMinds/assets/icons/ViewSmall.svg'
// import { ReactComponent as Visibility } from 'views/dev/gyula/BrandMinds/assets/icons/Visibility.svg'
// import { ReactComponent as VisibilityHidden } from 'views/dev/gyula/BrandMinds/assets/icons/VisibilityHidden.svg'
// import { ReactComponent as VisibilityOn } from 'views/dev/gyula/BrandMinds/assets/icons/VisibilityOn.svg'
// W
// import { ReactComponent as Warehouse } from 'views/dev/gyula/BrandMinds/assets/icons/Warehouse.svg'
// import { ReactComponent as Warning } from 'views/dev/gyula/BrandMinds/assets/icons/Warning.svg'
// import { ReactComponent as WebPage } from 'views/dev/gyula/BrandMinds/assets/icons/WebPage.svg'
// import { ReactComponent as Weight } from 'views/dev/gyula/BrandMinds/assets/icons/Weight.svg'
import { ReactComponent as Whisper } from 'views/dev/gyula/BrandMinds/assets/icons/Whisper.svg'

// Other icons
import { ReactComponent as Check } from 'views/dev/gyula/BrandMinds/assets/icons/check.svg'
import { ReactComponent as BuyTicket } from 'views/dev/gyula/BrandMinds/assets/icons/buyticket.svg'
import { ReactComponent as Friends } from 'views/dev/gyula/BrandMinds/assets/icons/friends.svg'
import { ReactComponent as AddPhoto } from 'views/dev/gyula/BrandMinds/assets/icons/addphoto.svg'
import { ReactComponent as AddUser1 } from 'views/dev/gyula/BrandMinds/assets/icons/AddUser.svg'
import { ReactComponent as AddUser1Clear } from 'views/dev/gyula/BrandMinds/assets/icons/AddUser-clear.svg'
import { ReactComponent as EmailWhite } from 'views/dev/gyula/BrandMinds/assets/icons/EmailWhite.svg'
import { ReactComponent as DownloadWhite } from 'views/dev/gyula/BrandMinds/assets/icons/DownloadWhite.svg'
import { ReactComponent as Yellow } from 'views/dev/gyula/BrandMinds/assets/svg/yellow.svg'
import { ReactComponent as IdWhite } from 'views/dev/gyula/BrandMinds/assets/icons/ID-white.svg'

import { ReactComponent as Fullscreen } from 'views/dev/gyula/BrandMinds/assets/icons/Fullscreen.svg'
import { ReactComponent as FullscreenExit } from 'views/dev/gyula/BrandMinds/assets/icons/FullscreenExit.svg'

// import AddUser from 'views/addUser/AddUser'


// LIVE PAGE
// import { ReactComponent as Reactions } from "views/dev/gyula/BrandMinds/assets/icons/Reactions.svg";
import { ReactComponent as BMlogo } from "views/dev/gyula/BrandMinds/assets/icons/BMlogo.svg";
import { ReactComponent as BMlogoclear } from "views/dev/gyula/BrandMinds/assets/icons/BMlogo-clear.svg";
import { ReactComponent as Facebook } from 'views/dev/gyula/BrandMinds/assets/icons/Facebook.svg';
import { ReactComponent as Instagram } from 'views/dev/gyula/BrandMinds/assets/icons/Instagram.svg';
import { ReactComponent as LinkedIn } from 'views/dev/gyula/BrandMinds/assets/icons/LinkedIn.svg';
import { ReactComponent as Google } from 'views/dev/gyula/BrandMinds/assets/icons/Google.svg';
import { ReactComponent as MoreAlert } from 'views/dev/gyula/BrandMinds/assets/icons/MoreAlert.svg';
import { ReactComponent as InfringmentInfo} from 'views/dev/gyula/BrandMinds/assets/icons/InfringmentInfo.svg';

// InputIcons
import { ReactComponent as InputBusiness }      from 'views/dev/gyula/BrandMinds/assets/InputIcons/Business.svg'
import { ReactComponent as InputManageUsers }   from 'views/dev/gyula/BrandMinds/assets/InputIcons/ManageUsers.svg'
import { ReactComponent as MasterCard }         from 'views/dev/gyula/BrandMinds/assets/InputIcons/MasterCard.svg'
import { ReactComponent as AddressPin }         from 'views/dev/gyula/BrandMinds/assets/InputIcons/AddressPin.svg'
import { ReactComponent as PasswordInput }      from 'views/dev/gyula/BrandMinds/assets/InputIcons/PasswordInput.svg'

// Payment refactor
import { ReactComponent as BillingCard }        from 'views/dev/gyula/BrandMinds/assets/billingTabs/Card.svg'
import { ReactComponent as BillingCrypto }      from 'views/dev/gyula/BrandMinds/assets/billingTabs/Crypto.svg'
import { ReactComponent as BillingTransfer }    from 'views/dev/gyula/BrandMinds/assets/billingTabs/Transfer.svg'


import { ReactComponent as BillingCardNew }        from 'views/dev/gyula/BrandMinds/assets/billingTabs/CardNew.svg'
import { ReactComponent as BillingCryptoNew }      from 'views/dev/gyula/BrandMinds/assets/billingTabs/CryptoNew.svg'
import { ReactComponent as BillingTransferNew }    from 'views/dev/gyula/BrandMinds/assets/billingTabs/TransferNew.svg'

import { ReactComponent as NoElementsIlustration} from 'views/dev/gyula/BrandMinds/assets/svg/NoElementsIlustration.svg'
import { ReactComponent as BigWhiteBMLogo} from 'views/dev/gyula/BrandMinds/assets/svg/bigWhiteBMLogo.svg'

// ============= GENERATED IMPORTS ================

import { ReactComponent as Icon48Add } from 'assets/icons/48/Add.svg'
import { ReactComponent as Icon48AddProcessStep } from 'assets/icons/48/AddProcessStep.svg'
import { ReactComponent as Icon48Address1 } from 'assets/icons/48/Address_1.svg'
import { ReactComponent as Icon48Address2 } from 'assets/icons/48/Address_2.svg'
import { ReactComponent as Icon48Address3 } from 'assets/icons/48/Address_3.svg'
import { ReactComponent as Icon48Addround } from 'assets/icons/48/Add_round.svg'
import { ReactComponent as Icon48Addtolist } from 'assets/icons/48/Add_to_list.svg'
import { ReactComponent as Icon48Agendabookmark } from 'assets/icons/48/Agenda bookmark.svg'
import { ReactComponent as Icon48AIrecommendations } from 'assets/icons/48/AI_recommendations.svg'
import { ReactComponent as Icon48Alert1 } from 'assets/icons/48/Alert-1.svg'
import { ReactComponent as Icon48ALERT } from 'assets/icons/48/ALERT.svg'
import { ReactComponent as Icon48Apps } from 'assets/icons/48/Apps.svg'
import { ReactComponent as Icon48Arrowleft } from 'assets/icons/48/Arrow-left.svg'
import { ReactComponent as Icon48Arrowright } from 'assets/icons/48/Arrow-right.svg'
import { ReactComponent as Icon48ATM } from 'assets/icons/48/ATM.svg'
import { ReactComponent as Icon48Attach } from 'assets/icons/48/Attach.svg'
import { ReactComponent as Icon48Audit } from 'assets/icons/48/Audit.svg'
import { ReactComponent as Icon48Award } from 'assets/icons/48/Award.svg'
import { ReactComponent as Icon48Back } from 'assets/icons/48/Back.svg'
import { ReactComponent as Icon48Backpack } from 'assets/icons/48/Backpack.svg'
import { ReactComponent as Icon48Badge } from 'assets/icons/48/Badge.svg'
import { ReactComponent as Icon48Bag } from 'assets/icons/48/Bag.svg'
import { ReactComponent as Icon48BagDistribution } from 'assets/icons/48/BagDistribution.svg'
import { ReactComponent as Icon48BagHandover } from 'assets/icons/48/BagHandover.svg'
import { ReactComponent as Icon48BagReceive } from 'assets/icons/48/BagReceive.svg'
import { ReactComponent as Icon48BagReception } from 'assets/icons/48/BagReception.svg'
import { ReactComponent as Icon48Bank } from 'assets/icons/48/Bank.svg'
import { ReactComponent as Icon48BillingProfile } from 'assets/icons/48/BillingProfile.svg'
import { ReactComponent as Icon48Block } from 'assets/icons/48/Block.svg'
import { ReactComponent as Icon48BMContacts } from 'assets/icons/48/BMContacts.svg'
import { ReactComponent as Icon48BMInvite } from 'assets/icons/48/BM_Invite.svg'
import { ReactComponent as Icon48BMStage } from 'assets/icons/48/BM_Stage.svg'
import { ReactComponent as Icon48BMvoucher } from 'assets/icons/48/BM_voucher.svg'
import { ReactComponent as Icon48Books } from 'assets/icons/48/Books.svg'
import { ReactComponent as Icon48Box } from 'assets/icons/48/Box.svg'
import { ReactComponent as Icon48Business } from 'assets/icons/48/Business.svg'
import { ReactComponent as Icon48Calendar } from 'assets/icons/48/Calendar.svg'
import { ReactComponent as Icon48Call } from 'assets/icons/48/Call.svg'
import { ReactComponent as Icon48Camera } from 'assets/icons/48/Camera.svg'
import { ReactComponent as Icon48Cancel } from 'assets/icons/48/Cancel.svg'
import { ReactComponent as Icon48Career } from 'assets/icons/48/Career.svg'
import { ReactComponent as Icon48CartEmpty } from 'assets/icons/48/CartEmpty.svg'
import { ReactComponent as Icon48CartFull } from 'assets/icons/48/CartFull.svg'
import { ReactComponent as Icon48CartAdd } from 'assets/icons/48/Cart_Add.svg'
import { ReactComponent as Icon48Casete } from 'assets/icons/48/Casete.svg'
import { ReactComponent as Icon48Cash } from 'assets/icons/48/Cash.svg'
import { ReactComponent as Icon48Certificate } from 'assets/icons/48/Certificate.svg'
import { ReactComponent as Icon48Chat } from 'assets/icons/48/Chat.svg'
import { ReactComponent as Icon48Chats } from 'assets/icons/48/Chats.svg'
import { ReactComponent as Icon48Checkboxactive } from 'assets/icons/48/Checkbox_active.svg'
import { ReactComponent as Icon48Checkboxinactive1 } from 'assets/icons/48/Checkbox_inactive-1.svg'
import { ReactComponent as Icon48Checkboxinactive2 } from 'assets/icons/48/Checkbox_inactive-2.svg'
import { ReactComponent as Icon48Checkboxinactive } from 'assets/icons/48/Checkbox_inactive.svg'
import { ReactComponent as Icon48Checkboxissue } from 'assets/icons/48/Checkbox_issue.svg'
import { ReactComponent as Icon48Checkboxissues } from 'assets/icons/48/Checkbox_issues.svg'
import { ReactComponent as Icon48Checkboxmultideselect } from 'assets/icons/48/Checkbox_multi-deselect.svg'
import { ReactComponent as Icon48Checkboxrestricted } from 'assets/icons/48/Checkbox_restricted.svg'
import { ReactComponent as Icon48Checklist } from 'assets/icons/48/Checklist.svg'
import { ReactComponent as Icon48Checkmixed } from 'assets/icons/48/Check_mixed.svg'
import { ReactComponent as Icon48Checksel } from 'assets/icons/48/Check_sel.svg'
import { ReactComponent as Icon48Checkunsel } from 'assets/icons/48/Check_unsel.svg'
import { ReactComponent as Icon48Close } from 'assets/icons/48/Close.svg'
import { ReactComponent as Icon48Collapse1 } from 'assets/icons/48/Collapse-1.svg'
import { ReactComponent as Icon48Collapse } from 'assets/icons/48/Collapse.svg'
import { ReactComponent as Icon48Copy } from 'assets/icons/48/Copy.svg'
import { ReactComponent as Icon48Createatask } from 'assets/icons/48/Create_a_task.svg'
import { ReactComponent as Icon48Crypto } from 'assets/icons/48/Crypto.svg'
import { ReactComponent as Icon48Cup } from 'assets/icons/48/Cup.svg'
import { ReactComponent as Icon48DarkModeMoon } from 'assets/icons/48/DarkModeMoon.svg'
import { ReactComponent as Icon48Dashboard } from 'assets/icons/48/Dashboard.svg'
import { ReactComponent as Icon48Dashboardometer } from 'assets/icons/48/Dashboard_ometer.svg'
import { ReactComponent as Icon48Deals } from 'assets/icons/48/Deals.svg'
import { ReactComponent as Icon48Deals2 } from 'assets/icons/48/Deals2.svg'
import { ReactComponent as Icon48Delete } from 'assets/icons/48/Delete.svg'
import { ReactComponent as Icon48Delivery } from 'assets/icons/48/Delivery.svg'
import { ReactComponent as Icon48Details } from 'assets/icons/48/Details.svg'
import { ReactComponent as Icon48Documents } from 'assets/icons/48/Documents.svg'
import { ReactComponent as Icon48Documents2 } from 'assets/icons/48/Documents2.svg'
import { ReactComponent as Icon48Documents3 } from 'assets/icons/48/Documents3.svg'
import { ReactComponent as Icon48Done1 } from 'assets/icons/48/Done-1.svg'
import { ReactComponent as Icon48Done } from 'assets/icons/48/Done.svg'
import { ReactComponent as Icon48Down } from 'assets/icons/48/Down.svg'
import { ReactComponent as Icon48Download } from 'assets/icons/48/Download.svg'
import { ReactComponent as Icon48Drinks } from 'assets/icons/48/Drinks.svg'
import { ReactComponent as Icon48Edit } from 'assets/icons/48/Edit.svg'
import { ReactComponent as Icon48Eggs } from 'assets/icons/48/Eggs.svg'
import { ReactComponent as Icon48Email } from 'assets/icons/48/Email.svg'
import { ReactComponent as Icon48Equipment } from 'assets/icons/48/Equipment.svg'
import { ReactComponent as Icon48Expand1 } from 'assets/icons/48/Expand-1.svg'
import { ReactComponent as Icon48Expand2 } from 'assets/icons/48/Expand-2.svg'
import { ReactComponent as Icon48Expand } from 'assets/icons/48/Expand.svg'
import { ReactComponent as Icon48FaceID } from 'assets/icons/48/FaceID.svg'
import { ReactComponent as Icon48FaceTouchID } from 'assets/icons/48/FaceTouchID.svg'
import { ReactComponent as Icon48Farmfarmer } from 'assets/icons/48/Farm_farmer.svg'
import { ReactComponent as Icon48Farmtractor } from 'assets/icons/48/Farm_tractor.svg'
import { ReactComponent as Icon48Filter } from 'assets/icons/48/Filter.svg'
import { ReactComponent as Icon48Filteron } from 'assets/icons/48/Filter_on.svg'
import { ReactComponent as Icon48Flag } from 'assets/icons/48/Flag.svg'
import { ReactComponent as Icon48Hat } from 'assets/icons/48/Hat.svg'
import { ReactComponent as Icon48Heart } from 'assets/icons/48/Heart.svg'
import { ReactComponent as Icon48HeartFilled } from 'assets/icons/48/HeartFilled.svg'
import { ReactComponent as Icon48Historyrecommendations } from 'assets/icons/48/History_recommendations.svg'
import { ReactComponent as Icon48HomePage } from 'assets/icons/48/HomePage.svg'
import { ReactComponent as Icon48IcoPlaceholder } from 'assets/icons/48/IcoPlaceholder.svg'
import { ReactComponent as Icon48ID } from 'assets/icons/48/ID.svg'
import { ReactComponent as Icon48ImageProfile1 } from 'assets/icons/48/Image_Profile_1.svg'
import { ReactComponent as Icon48ImageProfile21 } from 'assets/icons/48/Image_Profile_2-1.svg'
import { ReactComponent as Icon48ImageProfile2 } from 'assets/icons/48/Image_Profile_2.svg'
import { ReactComponent as Icon48Info } from 'assets/icons/48/Info.svg'
import { ReactComponent as Icon48InviteUser } from 'assets/icons/48/InviteUser.svg'
import { ReactComponent as Icon48InvoiceDollar } from 'assets/icons/48/Invoice-dollar.svg'
import { ReactComponent as Icon48Invoice } from 'assets/icons/48/Invoice.svg'
import { ReactComponent as Icon48Jobs1 } from 'assets/icons/48/Jobs1.svg'
import { ReactComponent as Icon48Jobs2 } from 'assets/icons/48/Jobs2.svg'
import { ReactComponent as Icon48KanBan } from 'assets/icons/48/KanBan.svg'
import { ReactComponent as Icon48Language } from 'assets/icons/48/Language.svg'
import { ReactComponent as Icon48Leader } from 'assets/icons/48/Leader.svg'
import { ReactComponent as Icon48Left } from 'assets/icons/48/Left.svg'
import { ReactComponent as Icon48Level } from 'assets/icons/48/Level.svg'
import { ReactComponent as Icon48LightModeSun } from 'assets/icons/48/LightModeSun.svg'
import { ReactComponent as Icon48Link1 } from 'assets/icons/48/Link-1.svg'
import { ReactComponent as Icon48Link } from 'assets/icons/48/Link.svg'
import { ReactComponent as Icon48list } from 'assets/icons/48/list.svg'
import { ReactComponent as Icon48ListOfBags } from 'assets/icons/48/ListOfBags.svg'
import { ReactComponent as Icon48Listmodels } from 'assets/icons/48/List_models.svg'
import { ReactComponent as Icon48LiveVideo1 } from 'assets/icons/48/LiveVideo-1.svg'
import { ReactComponent as Icon48LiveVideo } from 'assets/icons/48/LiveVideo.svg'
import { ReactComponent as Icon48Location } from 'assets/icons/48/Location.svg'
import { ReactComponent as Icon48LogOut } from 'assets/icons/48/LogOut.svg'
import { ReactComponent as Icon48MagicLink } from 'assets/icons/48/MagicLink.svg'
import { ReactComponent as Icon48ManageUsers } from 'assets/icons/48/ManageUsers.svg'
import { ReactComponent as Icon48MapPins } from 'assets/icons/48/MapPins.svg'
import { ReactComponent as Icon48Maps } from 'assets/icons/48/Maps.svg'
import { ReactComponent as Icon48Membership } from 'assets/icons/48/Membership.svg'
import { ReactComponent as Icon48Menu } from 'assets/icons/48/Menu.svg'
import { ReactComponent as Icon48MenuS } from 'assets/icons/48/MenuS.svg'
import { ReactComponent as Icon48MenuSright } from 'assets/icons/48/MenuS_right.svg'
import { ReactComponent as Icon48Menuleft } from 'assets/icons/48/Menu_left.svg'
import { ReactComponent as Icon48Minus } from 'assets/icons/48/Minus.svg'
import { ReactComponent as Icon48MinWeight } from 'assets/icons/48/Min_Weight.svg'
import { ReactComponent as Icon48More } from 'assets/icons/48/More.svg'
import { ReactComponent as Icon48Move } from 'assets/icons/48/Move.svg'
import { ReactComponent as Icon48NewTab } from 'assets/icons/48/NewTab.svg'
import { ReactComponent as Icon48NewTrey } from 'assets/icons/48/NewTrey.svg'
import { ReactComponent as Icon48Next } from 'assets/icons/48/Next.svg'
import { ReactComponent as Icon48Notesimage } from 'assets/icons/48/Notes_image.svg'
import { ReactComponent as Icon48NotesimageAndText } from 'assets/icons/48/Notes_imageAndText.svg'
import { ReactComponent as Icon48notestext } from 'assets/icons/48/notes_text.svg'
import { ReactComponent as Icon48Notifications } from 'assets/icons/48/Notifications.svg'
import { ReactComponent as Icon48Onlinestore } from 'assets/icons/48/Online_store.svg'
import { ReactComponent as Icon48OpenBook } from 'assets/icons/48/Open Book.svg'
import { ReactComponent as Icon48Pheart } from 'assets/icons/48/P-heart.svg'
import { ReactComponent as Icon48PaperDiploma } from 'assets/icons/48/Paper Diploma.svg'
import { ReactComponent as Icon48PartialList } from 'assets/icons/48/PartialList.svg'
import { ReactComponent as Icon48Password } from 'assets/icons/48/Password.svg'
import { ReactComponent as Icon48Paste } from 'assets/icons/48/Paste.svg'
import { ReactComponent as Icon48Pay1 } from 'assets/icons/48/Pay1.svg'
import { ReactComponent as Icon48Paybycard } from 'assets/icons/48/Pay_by_card.svg'
import { ReactComponent as Icon48Paybyinvoice } from 'assets/icons/48/Pay_by_invoice.svg'
import { ReactComponent as Icon48Paybytransfer } from 'assets/icons/48/Pay_by_transfer.svg'
import { ReactComponent as Icon48Pending } from 'assets/icons/48/Pending.svg'
import { ReactComponent as Icon48Plus } from 'assets/icons/48/Plus.svg'
import { ReactComponent as Icon48Pricecontroller } from 'assets/icons/48/Price_controller.svg'
import { ReactComponent as Icon48Print } from 'assets/icons/48/Print.svg'
import { ReactComponent as Icon48Privacy } from 'assets/icons/48/Privacy.svg'
import { ReactComponent as Icon48ProcessStep } from 'assets/icons/48/ProcessStep.svg'
import { ReactComponent as Icon48ProcessStep2 } from 'assets/icons/48/ProcessStep2.svg'
import { ReactComponent as Icon48ProductMagazine } from 'assets/icons/48/ProductMagazine.svg'
import { ReactComponent as Icon48Productinshop } from 'assets/icons/48/Product_in_shop.svg'
import { ReactComponent as Icon48Projects } from 'assets/icons/48/Projects.svg'
import { ReactComponent as Icon48Promo } from 'assets/icons/48/Promo.svg'
import { ReactComponent as Icon48Radioactive } from 'assets/icons/48/Radio_active.svg'
import { ReactComponent as Icon48Radioinactive } from 'assets/icons/48/Radio_inactive.svg'
import { ReactComponent as Icon48Radioselected } from 'assets/icons/48/Radio_selected.svg'
import { ReactComponent as Icon48Radiounsel } from 'assets/icons/48/Radio_unsel.svg'
import { ReactComponent as Icon48Reactions } from 'assets/icons/48/Reactions.svg'
import { ReactComponent as Icon48Refresh } from 'assets/icons/48/Refresh.svg'
import { ReactComponent as Icon48RemoveElement } from 'assets/icons/48/RemoveElement.svg'
import { ReactComponent as Icon48Reorder } from 'assets/icons/48/Reorder.svg'
import { ReactComponent as Icon48ResellPartners } from 'assets/icons/48/Resell-Partners.svg'
import { ReactComponent as Icon48ResetPass } from 'assets/icons/48/ResetPass.svg'
import { ReactComponent as Icon48Revert } from 'assets/icons/48/Revert.svg'
import { ReactComponent as Icon48Reviews } from 'assets/icons/48/Reviews.svg'
import { ReactComponent as Icon48Right } from 'assets/icons/48/Right.svg'
import { ReactComponent as Icon48Rooms } from 'assets/icons/48/Rooms.svg'
import { ReactComponent as Icon48SaciColectori } from 'assets/icons/48/SaciColectori.svg'
import { ReactComponent as Icon48Save } from 'assets/icons/48/Save.svg'
import { ReactComponent as Icon48Scale } from 'assets/icons/48/Scale.svg'
import { ReactComponent as Icon48ScanQR } from 'assets/icons/48/ScanQR.svg'
import { ReactComponent as Icon48Scrollico } from 'assets/icons/48/Scroll_ico.svg'
import { ReactComponent as Icon48Search } from 'assets/icons/48/Search.svg'
import { ReactComponent as Icon48Sendemail } from 'assets/icons/48/Send_email.svg'
import { ReactComponent as Icon48Services } from 'assets/icons/48/Services.svg'
import { ReactComponent as Icon48Settings } from 'assets/icons/48/Settings.svg'
import { ReactComponent as Icon48Share } from 'assets/icons/48/Share.svg'
import { ReactComponent as Icon48ShoppingBag } from 'assets/icons/48/Shopping Bag.svg'
import { ReactComponent as Icon48SignIn } from 'assets/icons/48/SignIn.svg'
import { ReactComponent as Icon48SmallCart } from 'assets/icons/48/SmallCart.svg'
import { ReactComponent as Icon48Sorin } from 'assets/icons/48/Sorin.svg'
import { ReactComponent as Icon48Sort } from 'assets/icons/48/Sort.svg'
import { ReactComponent as Icon48Speaker } from 'assets/icons/48/Speaker.svg'
import { ReactComponent as Icon48Stats } from 'assets/icons/48/Stats.svg'
import { ReactComponent as Icon48Stock } from 'assets/icons/48/Stock.svg'
import { ReactComponent as Icon48Subtract } from 'assets/icons/48/Subtract.svg'
import { ReactComponent as Icon48Support } from 'assets/icons/48/Support.svg'
import { ReactComponent as Icon48Tabs } from 'assets/icons/48/Tabs.svg'
import { ReactComponent as Icon48TaskList } from 'assets/icons/48/TaskList.svg'
import { ReactComponent as Icon48Template } from 'assets/icons/48/Template.svg'
import { ReactComponent as Icon48Tickets } from 'assets/icons/48/Tickets.svg'
import { ReactComponent as Icon48Ticketevents } from 'assets/icons/48/Ticket_events.svg'
import { ReactComponent as Icon48Ticketmanager } from 'assets/icons/48/Ticket_manager.svg'
import { ReactComponent as Icon48Timer } from 'assets/icons/48/Timer.svg'
import { ReactComponent as Icon48Top } from 'assets/icons/48/Top.svg'
import { ReactComponent as Icon48TouchID } from 'assets/icons/48/TouchID.svg'
import { ReactComponent as Icon48Truck } from 'assets/icons/48/Truck.svg'
import { ReactComponent as Icon48Tweak } from 'assets/icons/48/Tweak.svg'
import { ReactComponent as Icon48Undo } from 'assets/icons/48/Undo.svg'
import { ReactComponent as Icon48UniprestDiscountPachete } from 'assets/icons/48/Uniprest-DiscountPachete.svg'
import { ReactComponent as Icon48UniprestGrilaDiscount } from 'assets/icons/48/Uniprest-GrilaDiscount.svg'
import { ReactComponent as Icon48UniprestNeteNete } from 'assets/icons/48/Uniprest-NeteNete.svg'
import { ReactComponent as Icon48UniprestVolumeDiscount } from 'assets/icons/48/Uniprest-VolumeDiscount.svg'
import { ReactComponent as Icon48Uniprest } from 'assets/icons/48/Uniprest.svg'
import { ReactComponent as Icon48UniprestCompanoProducts } from 'assets/icons/48/UniprestCompanoProducts.svg'
import { ReactComponent as Icon48UniprestGlobalProducts } from 'assets/icons/48/UniprestGlobalProducts.svg'
import { ReactComponent as Icon48UniprestPlus } from 'assets/icons/48/UniprestPlus.svg'
import { ReactComponent as Icon48UniprestStaff } from 'assets/icons/48/UniprestStaff.svg'
import { ReactComponent as Icon48Up } from 'assets/icons/48/Up.svg'
import { ReactComponent as Icon48Upload } from 'assets/icons/48/Upload.svg'
import { ReactComponent as Icon48Useratcomp } from 'assets/icons/48/User at comp.svg'
import { ReactComponent as Icon48Useratdesk } from 'assets/icons/48/User at desk.svg'
import { ReactComponent as Icon48User } from 'assets/icons/48/User.svg'
import { ReactComponent as Icon48UserRequests } from 'assets/icons/48/User_Requests.svg'
import { ReactComponent as Icon48Valuables } from 'assets/icons/48/Valuables.svg'
import { ReactComponent as Icon48Video } from 'assets/icons/48/Video.svg'
import { ReactComponent as Icon48VisibilityHidden } from 'assets/icons/48/Visibility-Hidden.svg'
import { ReactComponent as Icon48Visibility } from 'assets/icons/48/Visibility.svg'
import { ReactComponent as Icon48VisibilityOn } from 'assets/icons/48/VisibilityOn.svg'
import { ReactComponent as Icon48Warehouse } from 'assets/icons/48/Warehouse.svg'
import { ReactComponent as Icon48Warning } from 'assets/icons/48/Warning.svg'
import { ReactComponent as Icon48WebPage } from 'assets/icons/48/WebPage.svg'
import { ReactComponent as Icon48Website } from 'assets/icons/48/Website.svg'
import { ReactComponent as Icon48Weight } from 'assets/icons/48/Weight.svg'


// ============= end of GENERATED IMPORTS ================
export default function SvgIcon({ mr, h, w, className, style, name = 'download' }) {
    const blankIcon = (
        <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='32' height='32' fill='white' />
        </svg>
    )
    
    const inlineStyle = { ...style }
    if (mr) inlineStyle.marginRight = mr * 4
    if (h) {
        inlineStyle.height = h * 4
        if (w) inlineStyle.width = w * 4
        else inlineStyle.width = h * 4
    }
    let findSvgByName = () => {
        switch (name.toLowerCase().replaceAll('-', '').replaceAll('_', '').replaceAll(' ', '')) {
// ===== generated code=====
case 'add':
  return <Icon48Add style={inlineStyle} />
case 'addprocessstep':
  return <Icon48AddProcessStep style={inlineStyle} />
case 'address1':
  return <Icon48Address1 style={inlineStyle} />
case 'address2':
  return <Icon48Address2 style={inlineStyle} />
case 'address3':
  return <Icon48Address3 style={inlineStyle} />
case 'addround':
  return <Icon48Addround style={inlineStyle} />
case 'addtolist':
  return <Icon48Addtolist style={inlineStyle} />
case 'agendabookmark':
  return <Icon48Agendabookmark style={inlineStyle} />
case 'airecommendations':
  return <Icon48AIrecommendations style={inlineStyle} />
case 'alert1':
  return <Icon48Alert1 style={inlineStyle} />
case 'alert':
  return <Icon48ALERT style={inlineStyle} />
case 'apps':
  return <Icon48Apps style={inlineStyle} />
case 'arrowleft':
  return <Icon48Arrowleft style={inlineStyle} />
case 'arrowright':
  return <Icon48Arrowright style={inlineStyle} />
case 'atm':
  return <Icon48ATM style={inlineStyle} />
case 'attach':
  return <Icon48Attach style={inlineStyle} />
case 'audit':
  return <Icon48Audit style={inlineStyle} />
case 'award':
  return <Icon48Award style={inlineStyle} />
case 'back':
  return <Icon48Back style={inlineStyle} />
case 'backpack':
  return <Icon48Backpack style={inlineStyle} />
case 'badge':
  return <Icon48Badge style={inlineStyle} />
case 'bag':
  return <Icon48Bag style={inlineStyle} />
case 'bagdistribution':
  return <Icon48BagDistribution style={inlineStyle} />
case 'baghandover':
  return <Icon48BagHandover style={inlineStyle} />
case 'bagreceive':
  return <Icon48BagReceive style={inlineStyle} />
case 'bagreception':
  return <Icon48BagReception style={inlineStyle} />
case 'bank':
  return <Icon48Bank style={inlineStyle} />
case 'billingprofile':
  return <Icon48BillingProfile style={inlineStyle} />
case 'block':
  return <Icon48Block style={inlineStyle} />
case 'bmcontacts':
  return <Icon48BMContacts style={inlineStyle} />
case 'bminvite':
  return <Icon48BMInvite style={inlineStyle} />
case 'bmstage':
  return <Icon48BMStage style={inlineStyle} />
case 'bmvoucher':
  return <Icon48BMvoucher style={inlineStyle} />
case 'books':
  return <Icon48Books style={inlineStyle} />
case 'box':
  return <Icon48Box style={inlineStyle} />
case 'business':
  return <Icon48Business style={inlineStyle} />
case 'calendar':
  return <Icon48Calendar style={inlineStyle} />
case 'call':
  return <Icon48Call style={inlineStyle} />
case 'camera':
  return <Icon48Camera style={inlineStyle} />
case 'cancel':
  return <Icon48Cancel style={inlineStyle} />
case 'career':
  return <Icon48Career style={inlineStyle} />
case 'cartempty':
  return <Icon48CartEmpty style={inlineStyle} />
case 'cartfull':
  return <Icon48CartFull style={inlineStyle} />
case 'cartadd':
  return <Icon48CartAdd style={inlineStyle} />
case 'casete':
  return <Icon48Casete style={inlineStyle} />
case 'cash':
  return <Icon48Cash style={inlineStyle} />
case 'certificate':
  return <Icon48Certificate style={inlineStyle} />
case 'chat':
  return <Icon48Chat style={inlineStyle} />
case 'chats':
  return <Icon48Chats style={inlineStyle} />
case 'checkboxactive':
  return <Icon48Checkboxactive style={inlineStyle} />
case 'checkboxinactive1':
  return <Icon48Checkboxinactive1 style={inlineStyle} />
case 'checkboxinactive2':
  return <Icon48Checkboxinactive2 style={inlineStyle} />
case 'checkboxinactive':
  return <Icon48Checkboxinactive style={inlineStyle} />
case 'checkboxissue':
  return <Icon48Checkboxissue style={inlineStyle} />
case 'checkboxissues':
  return <Icon48Checkboxissues style={inlineStyle} />
case 'checkboxmultideselect':
  return <Icon48Checkboxmultideselect style={inlineStyle} />
case 'checkboxrestricted':
  return <Icon48Checkboxrestricted style={inlineStyle} />
case 'checklist':
  return <Icon48Checklist style={inlineStyle} />
case 'checkmixed':
  return <Icon48Checkmixed style={inlineStyle} />
case 'checksel':
  return <Icon48Checksel style={inlineStyle} />
case 'checkunsel':
  return <Icon48Checkunsel style={inlineStyle} />
case 'close':
  return <Icon48Close style={inlineStyle} />
case 'collapse1':
  return <Icon48Collapse1 style={inlineStyle} />
case 'collapse':
  return <Icon48Collapse style={inlineStyle} />
case 'copy':
  return <Icon48Copy style={inlineStyle} />
case 'createatask':
  return <Icon48Createatask style={inlineStyle} />
case 'crypto':
  return <Icon48Crypto style={inlineStyle} />
case 'cup':
  return <Icon48Cup style={inlineStyle} />
case 'darkmodemoon':
  return <Icon48DarkModeMoon style={inlineStyle} />
case 'dashboard':
  return <Icon48Dashboard style={inlineStyle} />
case 'dashboardometer':
  return <Icon48Dashboardometer style={inlineStyle} />
case 'deals':
  return <Icon48Deals style={inlineStyle} />
case 'deals2':
  return <Icon48Deals2 style={inlineStyle} />
case 'delete':
  return <Icon48Delete style={inlineStyle} />
case 'delivery':
  return <Icon48Delivery style={inlineStyle} />
case 'details':
  return <Icon48Details style={inlineStyle} />
case 'documents':
  return <Icon48Documents style={inlineStyle} />
case 'documents2':
  return <Icon48Documents2 style={inlineStyle} />
case 'documents3':
  return <Icon48Documents3 style={inlineStyle} />
case 'done1':
  return <Icon48Done1 style={inlineStyle} />
case 'done':
  return <Icon48Done style={inlineStyle} />
case 'down':
  return <Icon48Down style={inlineStyle} />
case 'download':
  return <Icon48Download style={inlineStyle} />
case 'drinks':
  return <Icon48Drinks style={inlineStyle} />
case 'edit':
  return <Icon48Edit style={inlineStyle} />
case 'eggs':
  return <Icon48Eggs style={inlineStyle} />
case 'email':
  return <Icon48Email style={inlineStyle} />
case 'equipment':
  return <Icon48Equipment style={inlineStyle} />
case 'expand1':
  return <Icon48Expand1 style={inlineStyle} />
case 'expand2':
  return <Icon48Expand2 style={inlineStyle} />
case 'expand':
  return <Icon48Expand style={inlineStyle} />
case 'faceid':
  return <Icon48FaceID style={inlineStyle} />
case 'facetouchid':
  return <Icon48FaceTouchID style={inlineStyle} />
case 'farmfarmer':
  return <Icon48Farmfarmer style={inlineStyle} />
case 'farmtractor':
  return <Icon48Farmtractor style={inlineStyle} />
case 'filter':
  return <Icon48Filter style={inlineStyle} />
case 'filteron':
  return <Icon48Filteron style={inlineStyle} />
case 'flag':
  return <Icon48Flag style={inlineStyle} />
case 'hat':
  return <Icon48Hat style={inlineStyle} />
case 'heart':
  return <Icon48Heart style={inlineStyle} />
case 'heartfilled':
  return <Icon48HeartFilled style={inlineStyle} />
case 'historyrecommendations':
  return <Icon48Historyrecommendations style={inlineStyle} />
case 'homepage':
  return <Icon48HomePage style={inlineStyle} />
case 'icoplaceholder':
  return <Icon48IcoPlaceholder style={inlineStyle} />
case 'id':
  return <Icon48ID style={inlineStyle} />
case 'imageprofile1':
  return <Icon48ImageProfile1 style={inlineStyle} />
case 'imageprofile21':
  return <Icon48ImageProfile21 style={inlineStyle} />
case 'imageprofile2':
  return <Icon48ImageProfile2 style={inlineStyle} />
case 'info':
  return <Icon48Info style={inlineStyle} />
case 'inviteuser':
  return <Icon48InviteUser style={inlineStyle} />
case 'invoicedollar':
  return <Icon48InvoiceDollar style={inlineStyle} />
case 'invoice':
  return <Icon48Invoice style={inlineStyle} />
case 'jobs1':
  return <Icon48Jobs1 style={inlineStyle} />
case 'jobs2':
  return <Icon48Jobs2 style={inlineStyle} />
case 'kanban':
  return <Icon48KanBan style={inlineStyle} />
case 'language':
  return <Icon48Language style={inlineStyle} />
case 'leader':
  return <Icon48Leader style={inlineStyle} />
case 'left':
  return <Icon48Left style={inlineStyle} />
case 'level':
  return <Icon48Level style={inlineStyle} />
case 'lightmodesun':
  return <Icon48LightModeSun style={inlineStyle} />
case 'link1':
  return <Icon48Link1 style={inlineStyle} />
case 'link':
  return <Icon48Link style={inlineStyle} />
case 'list':
  return <Icon48list style={inlineStyle} />
case 'listofbags':
  return <Icon48ListOfBags style={inlineStyle} />
case 'listmodels':
  return <Icon48Listmodels style={inlineStyle} />
case 'livevideo1':
  return <Icon48LiveVideo1 style={inlineStyle} />
case 'livevideo':
  return <Icon48LiveVideo style={inlineStyle} />
case 'location':
  return <Icon48Location style={inlineStyle} />
case 'logout':
  return <Icon48LogOut style={inlineStyle} />
case 'magiclink':
  return <Icon48MagicLink style={inlineStyle} />
case 'manageusers':
  return <Icon48ManageUsers style={inlineStyle} />
case 'mappins':
  return <Icon48MapPins style={inlineStyle} />
case 'maps':
  return <Icon48Maps style={inlineStyle} />
case 'membership':
  return <Icon48Membership style={inlineStyle} />
case 'menu':
  return <Icon48Menu style={inlineStyle} />
case 'menus':
  return <Icon48MenuS style={inlineStyle} />
case 'menusright':
  return <Icon48MenuSright style={inlineStyle} />
case 'menuleft':
  return <Icon48Menuleft style={inlineStyle} />
case 'minus':
  return <Icon48Minus style={inlineStyle} />
case 'minweight':
  return <Icon48MinWeight style={inlineStyle} />
case 'more':
  return <Icon48More style={inlineStyle} />
case 'move':
  return <Icon48Move style={inlineStyle} />
case 'newtab':
  return <Icon48NewTab style={inlineStyle} />
case 'newtrey':
  return <Icon48NewTrey style={inlineStyle} />
case 'next':
  return <Icon48Next style={inlineStyle} />
case 'notesimage':
  return <Icon48Notesimage style={inlineStyle} />
case 'notesimageandtext':
  return <Icon48NotesimageAndText style={inlineStyle} />
case 'notestext':
  return <Icon48notestext style={inlineStyle} />
case 'notifications':
  return <Icon48Notifications style={inlineStyle} />
case 'onlinestore':
  return <Icon48Onlinestore style={inlineStyle} />
case 'openbook':
  return <Icon48OpenBook style={inlineStyle} />
case 'pheart':
  return <Icon48Pheart style={inlineStyle} />
case 'paperdiploma':
  return <Icon48PaperDiploma style={inlineStyle} />
case 'partiallist':
  return <Icon48PartialList style={inlineStyle} />
case 'password':
  return <Icon48Password style={inlineStyle} />
case 'paste':
  return <Icon48Paste style={inlineStyle} />
case 'pay1':
  return <Icon48Pay1 style={inlineStyle} />
case 'paybycard':
  return <Icon48Paybycard style={inlineStyle} />
case 'paybyinvoice':
  return <Icon48Paybyinvoice style={inlineStyle} />
case 'paybytransfer':
  return <Icon48Paybytransfer style={inlineStyle} />
case 'pending':
  return <Icon48Pending style={inlineStyle} />
case 'plus':
  return <Icon48Plus style={inlineStyle} />
case 'pricecontroller':
  return <Icon48Pricecontroller style={inlineStyle} />
case 'print':
  return <Icon48Print style={inlineStyle} />
case 'privacy':
  return <Icon48Privacy style={inlineStyle} />
case 'processstep':
  return <Icon48ProcessStep style={inlineStyle} />
case 'processstep2':
  return <Icon48ProcessStep2 style={inlineStyle} />
case 'productmagazine':
  return <Icon48ProductMagazine style={inlineStyle} />
case 'productinshop':
  return <Icon48Productinshop style={inlineStyle} />
case 'projects':
  return <Icon48Projects style={inlineStyle} />
case 'promo':
  return <Icon48Promo style={inlineStyle} />
case 'radioactive':
  return <Icon48Radioactive style={inlineStyle} />
case 'radioinactive':
  return <Icon48Radioinactive style={inlineStyle} />
case 'radioselected':
  return <Icon48Radioselected style={inlineStyle} />
case 'radiounsel':
  return <Icon48Radiounsel style={inlineStyle} />
case 'reactions':
  return <Icon48Reactions style={inlineStyle} />
case 'refresh':
  return <Icon48Refresh style={inlineStyle} />
case 'removeelement':
  return <Icon48RemoveElement style={inlineStyle} />
case 'reorder':
  return <Icon48Reorder style={inlineStyle} />
case 'resellpartners':
  return <Icon48ResellPartners style={inlineStyle} />
case 'resetpass':
  return <Icon48ResetPass style={inlineStyle} />
case 'revert':
  return <Icon48Revert style={inlineStyle} />
case 'reviews':
  return <Icon48Reviews style={inlineStyle} />
case 'right':
  return <Icon48Right style={inlineStyle} />
case 'rooms':
  return <Icon48Rooms style={inlineStyle} />
case 'sacicolectori':
  return <Icon48SaciColectori style={inlineStyle} />
case 'save':
  return <Icon48Save style={inlineStyle} />
case 'scale':
  return <Icon48Scale style={inlineStyle} />
case 'scanqr':
  return <Icon48ScanQR style={inlineStyle} />
case 'scrollico':
  return <Icon48Scrollico style={inlineStyle} />
case 'search':
  return <Icon48Search style={inlineStyle} />
case 'sendemail':
  return <Icon48Sendemail style={inlineStyle} />
case 'services':
  return <Icon48Services style={inlineStyle} />
case 'settings':
  return <Icon48Settings style={inlineStyle} />
case 'share':
  return <Icon48Share style={inlineStyle} />
case 'shoppingbag':
  return <Icon48ShoppingBag style={inlineStyle} />
case 'signin':
  return <Icon48SignIn style={inlineStyle} />
case 'smallcart':
  return <Icon48SmallCart style={inlineStyle} />
case 'sorin':
  return <Icon48Sorin style={inlineStyle} />
case 'sort':
  return <Icon48Sort style={inlineStyle} />
case 'speaker':
  return <Icon48Speaker style={inlineStyle} />
case 'stats':
  return <Icon48Stats style={inlineStyle} />
case 'stock':
  return <Icon48Stock style={inlineStyle} />
case 'subtract':
  return <Icon48Subtract style={inlineStyle} />
case 'support':
  return <Icon48Support style={inlineStyle} />
case 'tabs':
  return <Icon48Tabs style={inlineStyle} />
case 'tasklist':
  return <Icon48TaskList style={inlineStyle} />
case 'template':
  return <Icon48Template style={inlineStyle} />
case 'ticket':
  return <Icon48Tickets style={inlineStyle} />
case 'tickets':
  return <Icon48Tickets style={inlineStyle} />
case 'ticketevents':
  return <Icon48Ticketevents style={inlineStyle} />
case 'ticketmanager':
  return <Icon48Ticketmanager style={inlineStyle} />
case 'timer':
  return <Icon48Timer style={inlineStyle} />
case 'top':
  return <Icon48Top style={inlineStyle} />
case 'touchid':
  return <Icon48TouchID style={inlineStyle} />
case 'truck':
  return <Icon48Truck style={inlineStyle} />
case 'tweak':
  return <Icon48Tweak style={inlineStyle} />
case 'undo':
  return <Icon48Undo style={inlineStyle} />
case 'uniprestdiscountpachete':
  return <Icon48UniprestDiscountPachete style={inlineStyle} />
case 'uniprestgriladiscount':
  return <Icon48UniprestGrilaDiscount style={inlineStyle} />
case 'uniprestnetenete':
  return <Icon48UniprestNeteNete style={inlineStyle} />
case 'uniprestvolumediscount':
  return <Icon48UniprestVolumeDiscount style={inlineStyle} />
case 'uniprest':
  return <Icon48Uniprest style={inlineStyle} />
case 'uniprestcompanoproducts':
  return <Icon48UniprestCompanoProducts style={inlineStyle} />
case 'uniprestglobalproducts':
  return <Icon48UniprestGlobalProducts style={inlineStyle} />
case 'uniprestplus':
  return <Icon48UniprestPlus style={inlineStyle} />
case 'unipreststaff':
  return <Icon48UniprestStaff style={inlineStyle} />
case 'up':
  return <Icon48Up style={inlineStyle} />
case 'upload':
  return <Icon48Upload style={inlineStyle} />
case 'useratcomp':
  return <Icon48Useratcomp style={inlineStyle} />
case 'useratdesk':
  return <Icon48Useratdesk style={inlineStyle} />
case 'user':
  return <Icon48User style={inlineStyle} />
case 'userrequests':
  return <Icon48UserRequests style={inlineStyle} />
case 'valuables':
  return <Icon48Valuables style={inlineStyle} />
case 'video':
  return <Icon48Video style={inlineStyle} />
case 'visibilityhidden':
  return <Icon48VisibilityHidden style={inlineStyle} />
case 'visibility':
  return <Icon48Visibility style={inlineStyle} />
case 'visibilityon':
  return <Icon48VisibilityOn style={inlineStyle} />
case 'warehouse':
  return <Icon48Warehouse style={inlineStyle} />
case 'warning':
  return <Icon48Warning style={inlineStyle} />
case 'webpage':
  return <Icon48WebPage style={inlineStyle} />
case 'website':
  return <Icon48Website style={inlineStyle} />
case 'weight':
  return <Icon48Weight style={inlineStyle} />
// ===== end of  generated code=====

            // case 'add':
            //     return <Add style={inlineStyle} />
            case 'addclear':
                return <AddClear style={inlineStyle} />
            // case 'addround':
            //     return <AddRound style={inlineStyle} />
            case 'addticket':
                return <AddTicket style={inlineStyle} />
            // case 'address2':
            //     return <Address2 style={inlineStyle} />
            // case 'address3':
            //     return <Address3 style={inlineStyle} />
            // case 'addtolist':
            //     return <AddToList style={inlineStyle} />
            // case 'alert':
            //     return <Alert style={inlineStyle} />
            // case 'apps':
            //     return <Apps style={inlineStyle} />
            case 'arrowdown':
                return <ArrowDown style={inlineStyle} />
            // case 'arrowleft':
            //     return <ArrowLeft style={inlineStyle} />
            case 'arrowleftclear':
                return <ArrowLeftClear style={inlineStyle} />
            // case 'arrowright':
            //     return <ArrowRight style={inlineStyle} />
            case 'arrowrightclear':
                return <ArrowRightClear style={inlineStyle} />
            case 'arrowup':
                return <ArrowUp style={inlineStyle} />
            // case 'atm':
            //     return <ATM style={inlineStyle} />
            // case 'attach':
            //     return <Attach style={inlineStyle} />
            // case 'audit':
            //     return <Audit style={inlineStyle} />
            // case 'badge':
            //     return <Badge style={inlineStyle} />
            case 'badgewhite':
                return <BadgeWhite style={inlineStyle} />
            // case 'bag':
            //     return <Bag style={inlineStyle} />
            // case 'bagdistribution':
            //     return <BagDistribution style={inlineStyle} />
            // case 'baghandover':
            //     return <BagHandover style={inlineStyle} />
            // case 'bagreceive':
            //     return <BagReceive style={inlineStyle} />
            // case 'bagreception':
            //     return <BagReception style={inlineStyle} />
            // case 'bank':
            //     return <Bank style={inlineStyle} />
            case 'bell':
                return <Bell style={inlineStyle} />
            // case 'billingprofile':
            //     return <BillingProfile style={inlineStyle} />
            // case 'block':
            //     return <Block style={inlineStyle} />
            // case 'bmcontacts':
            //     return <BMcontacts style={inlineStyle} />
            // case 'bminvite':
            //     return <BMinvite style={inlineStyle} />
            case 'bmpoints':
                return <BMpoints style={inlineStyle} />
            // case 'bmstage':
            //     return <BMstage style={inlineStyle} />
            // case 'bmvoucher':
            //     return <BMvoucher style={inlineStyle} />
            // case 'box':
            //     return <Box style={inlineStyle} />
            // case 'books':
            //     return <Books style={inlineStyle} />
            case 'briefcase':
                return <Briefcase style={inlineStyle} />
            case 'briefcaseclear':
                return <BriefcaseClear style={inlineStyle} />
            // case 'business':
            //     return <Business style={inlineStyle} />
            case 'businessclear':
                return <BusinessClear style={inlineStyle} />
            // case 'calendar':
            //     return <Calendar style={inlineStyle} />
            // case 'call':
            //     return <Call style={inlineStyle} />
            case 'callclear':
                return <CallClear style={inlineStyle} />
    
            // case 'camera':
            //     return <Camera style={inlineStyle} />
            // case 'cancel':
            //     return <Cancel style={inlineStyle} />
            // case 'career':
            //     return <Career style={inlineStyle} />
            // case 'cartadd':
            //     return <CartAdd style={inlineStyle} />
            // case 'cartempty':
            //     return <CartEmpty style={inlineStyle} />
            // case 'cartfull':
            //     return <CartFull style={inlineStyle} />
            // case 'casete':
            //     return <Casete style={inlineStyle} />
            // case 'cash':
            //     return <Cash style={inlineStyle} />
            // case 'chat':
            //     return <Chat style={inlineStyle} />
            // case 'chats':
            //     return <Chats style={inlineStyle} />
            // case 'checklist':
            //     return <CheckList style={inlineStyle} />
            case 'chevrondown':
                return <ChevronDown style={inlineStyle} />
            case 'chevronup':
                return <ChevronUp style={inlineStyle} />
            // case 'copy':
            //     return <Copy style={inlineStyle} />
            case 'createtask':
                return <CreateTask style={inlineStyle} />
            // case 'cup':
            //     return <Cup style={inlineStyle} />
            // case 'certificate':
            //     return <Certificate style={inlineStyle} />
            // case 'darkmodemoon':
            //     return <DarkModeMoon style={inlineStyle} />
            // case 'dashboard':
            //     return <Dashboard style={inlineStyle} />
            // case 'dashboardometer':
            //     return <DashboardOmeter style={inlineStyle} />
            // case 'deals':
            //     return <Deals style={inlineStyle} />
            // case 'deals2':
            //     return <Deals2 style={inlineStyle} />
            case 'defaultuserimage':
                return <DefaultUserImage style={inlineStyle} />
            // case 'delete':
            //     return <Delete style={inlineStyle} />
            case 'deleteclear':
                return <DeleteClear style={inlineStyle} />
            // case 'delivery':
            //     return <Delivery style={inlineStyle} />
            // case 'details':
            //     return <Details style={inlineStyle} />
            // case 'documents':
            //     return <Documents style={inlineStyle} />
            case 'documentsclear':
                return <DocumentsClear style={inlineStyle} />
            // case 'documents2':
            //     return <Documents2 style={inlineStyle} />
            // case 'documents3':
            //     return <Documents3 style={inlineStyle} />
            case 'documents3clear':
                return <Documents3clear style={inlineStyle} />
            // case 'done':
            //     return <Done style={inlineStyle} />
            case 'doneclear':
                return <DoneClear style={inlineStyle} />
            case 'donecheck':
                return <DoneCheck style={inlineStyle} />
            // case 'download':
            //     return <Download style={inlineStyle} />
            case 'deletegrey':
                return <DeleteGrey style={inlineStyle} />
            // case 'edit':
            //     return <Edit style={inlineStyle} />
            case 'editwhite':
                return <EditWhite style={inlineStyle} />
            // case 'email':
            //     return <Email style={inlineStyle} />
            case 'emailclear':
                return <EmailClear style={inlineStyle} />
            // case 'equipment':
            //     return <Equipment style={inlineStyle} />
            // case 'expand':
            //     return <Expand style={inlineStyle} />
            case 'education':
                return <Education style={inlineStyle} />
            case 'noelementsilustration':
              return <NoElementsIlustration style={inlineStyle} />
            case 'bigwhitebmlogo': 
              return <BigWhiteBMLogo style={inlineStyle} />
            // case 'filter':
            //     return <Filter style={inlineStyle} />
            // case 'filteron':
            //     return <FilterOn style={inlineStyle} />
            // case 'flag':
            //     return <Flag style={inlineStyle} />
            // case 'heart':
            //     return <Heart style={inlineStyle} />
            case 'goldcoin':
                return <GoldCoin style={inlineStyle} />
            case 'history':
                return <History style={inlineStyle} />
            case 'home':
                return <Home style={inlineStyle} />
            // case 'id':
            //     return <ID style={inlineStyle} />
            case 'ideea':
                return <Ideea style={inlineStyle} />
            case 'interests':
                return <Interests style={inlineStyle} />
            // case 'inviteuser':
            //     return <InviteUser style={inlineStyle} />
            // case 'invoice':
            //     return <Invoice style={inlineStyle} />
            // case 'invoicedollar':
            //     return <InvoiceDollar style={inlineStyle} />
            // case 'jobs1':
            //     return <Jobs1 style={inlineStyle} />
            // case 'jobs2':
            //     return <Jobs2 style={inlineStyle} />
            // case 'kanban':
            //     return <KanBan style={inlineStyle} />
            case 'languagero':
                return <LanguageRO style={inlineStyle} />
            // case 'leader':
            //     return <Leader style={inlineStyle} />
            // case 'lightmodesun':
            //     return <LightModeSun style={inlineStyle} />
            // case 'link1':
            //     return <Link1 style={inlineStyle} />
            case 'link2':
                return <Link2 style={inlineStyle} />
            // case 'list':
            //     return <List style={inlineStyle} />
            // case 'listmodels':
            //     return <ListModels style={inlineStyle} />
            // case 'listofbags':
            //     return <ListOfBags style={inlineStyle} />
            // case 'livevideo':
            //     return <LiveVideo style={inlineStyle} />
            // case 'location':
            //     return <Location style={inlineStyle} />
            case 'logoutclear':
                return <LogOutClear style={inlineStyle} />
            case 'like2':
                return <Like2 style={inlineStyle} />
            case 'lock':
                return <Lock style={inlineStyle} />
            // case 'logout':
            //     return <LogOut style={inlineStyle} />
            // case 'magiclink':
            //     return <MagicLink style={inlineStyle} />
            // case 'manageusers':
            //     return <ManageUsers style={inlineStyle} />
            // case 'maps':
            //     return <Maps style={inlineStyle} />
            case 'match':
                return <Match style={inlineStyle} />
            // case 'menu':
            //     return <Menu style={inlineStyle} />
            // case 'minweight':
            //     return <MinWeight style={inlineStyle} />
            case 'morehorizontal':
                return <MoreHorizontal style={inlineStyle} />
            case 'morehorizontalclear':
                return <MoreHorizontalClear style={inlineStyle} />
            // case 'move':
            //     return <Move style={inlineStyle} />
            // case 'newtab':
            //     return <NewTab style={inlineStyle} />
            // case 'newtrey':
            //     return <NewTrey style={inlineStyle} />
            // case 'notesimage':
            //     return <NotesImage style={inlineStyle} />
            // case 'notesimageandtext':
            //     return <NotesImageAndText style={inlineStyle} />
            // case 'notestext':
            //     return <NotesText style={inlineStyle} />
            // case 'notifications':
            //     return <Notifications style={inlineStyle} />
            // case 'onlinestore':
            //     return <OnlineStore style={inlineStyle} />
            // case 'partiallist':
            //     return <PartialList style={inlineStyle} />
            // case 'password':
            //     return <Password style={inlineStyle} />
            // case 'paste':
            //     return <Paste style={inlineStyle} />
            // case 'pay1':
            //     return <Pay1 style={inlineStyle} />
            // case 'paybycard':
            //     return <PayByCard style={inlineStyle} />
            // case 'paybyinvoice':
            //     return <PayByInvoice style={inlineStyle} />
            // case 'paybytransfer':
            //     return <PayByTransfer style={inlineStyle} />
            case 'play':
                return <Play style={inlineStyle} />
            // case 'print':
            //     return <Print style={inlineStyle} />
            // case 'privacy':
            //     return <Privacy style={inlineStyle} />
            case 'privacyclear':
                return <PrivacyClear style={inlineStyle} />
            // case 'processstep':
            //     return <ProcessStep style={inlineStyle} />
            // case 'processstep2':
            //     return <ProcessStep2 style={inlineStyle} />
            // case 'productmagazine':
            //     return <ProductMagazine style={inlineStyle} />
            // case 'projects':
            //     return <Projects style={inlineStyle} />
            // case 'promo':
            //     return <Promo style={inlineStyle} />
            case 'quote':
                return <Quote style={inlineStyle} />
            case 'reload':
                return <Reload style={inlineStyle} />
            // case 'rooms':
            //     return <Rooms style={inlineStyle} />
            // case 'sacicolectori':
            //     return <SaciColectori style={inlineStyle} />
            case 'savedisk':
                return <SaveDisk style={inlineStyle} />
            // case 'scanqr':
            //     return <ScanQR style={inlineStyle} />
            // case 'scrollico':
            //     return <ScrollIco style={inlineStyle} />
            // case 'search':
            //     return <Search style={inlineStyle} />
            case 'searchclear':
                return <SearchClear style={inlineStyle} />
            case 'secure':
                return <Secure style={inlineStyle} />
            // case 'sendemail':
            //     return <SendEmail style={inlineStyle} />
            // case 'services':
            //     return <Services style={inlineStyle} />
            // case 'settings':
            //     return <Settings style={inlineStyle} />
            // case 'share':
            //     return <Share style={inlineStyle} />
            // case 'shoppingbag':
            //     return <ShoppingBag style={inlineStyle} />
            // case 'signin':
            //     return <SignIn style={inlineStyle} />
            case 'sms':
                return <Sms style={inlineStyle} />
            // case 'sort':
            //     return <Sort style={inlineStyle} />
            // case 'stats':
            //     return <Stats style={inlineStyle} />
            // case 'stock':
            //     return <Stock style={inlineStyle} />
            // case 'subtract':
            //     return <Subtract style={inlineStyle} />
            // case 'support':
            //     return <Support style={inlineStyle} />
            // case 'tabs':
            //     return <Tabs style={inlineStyle} />
            // case 'tasklist':
            //     return <TaskList style={inlineStyle} />
            // case 'ticketevents':
            //     return <TicketEvents style={inlineStyle} />
            // case 'ticketmanager':
            //     return <TicketManager style={inlineStyle} />
            // case 'tickets':
            //     return <Tickets style={inlineStyle} />
            // case 'ticket':
            //         return <Tickets style={inlineStyle} />
            // case 'timer':
            //     return <Timer style={inlineStyle} />
            // case 'tweak':
            //     return <Tweak style={inlineStyle} />
            // case 'undo':
            //     return <Undo style={inlineStyle} />
            // case 'uniprest':
            //     return <Uniprest style={inlineStyle} />
            // case 'uniprestcompanoproducts':
            //     return <UniprestCompanoProducts style={inlineStyle} />
            // case 'uniprestdiscountpachete':
            //     return <UniprestDiscountPachete style={inlineStyle} />
            // case 'uniprestglobalproducts':
            //     return <UniprestGlobalProducts style={inlineStyle} />
            // case 'uniprestgriladiscount':
            //     return <UniprestGrilaDiscount style={inlineStyle} />
            // case 'uniprestnetenete':
            //     return <UniprestNeteNete style={inlineStyle} />
            // case 'uniprestplus':
            //     return <UniprestPlus style={inlineStyle} />
            // case 'unipreststaff':
            //     return <UniprestStaff style={inlineStyle} />
            // case 'uniprestvolumediscount':
            //     return <UniprestVolumeDiscount style={inlineStyle} />
            // case 'upload':
            //     return <Upload style={inlineStyle} />
            // case 'user':
            //     return <User style={inlineStyle} />
            case 'userwhite':
                return <UserWhite style={inlineStyle} />
            case 'useratcomputer':
                return <UserAtComputer style={inlineStyle} />
            // case 'useratdesk':
            //     return <UserAtDesk style={inlineStyle} />
            case 'userattable':
                return <UserAtTable style={inlineStyle} />
            // case 'userrequests':
            //     return <UserRequests style={inlineStyle} />
            case 'unassignedpurple':
                return <UnassignedPurple style={inlineStyle} />
            // case 'valuables':
            //     return <Valuables style={inlineStyle} />
            // case 'video':
            //     return <Video style={inlineStyle} />
            case 'videoclear':
                return <VideoClear style={inlineStyle} />
            case 'viewlarge':
                return <ViewLarge style={inlineStyle} />
            case 'viewmedium':
                return <ViewMedium style={inlineStyle} />
            case 'viewsmall':
                return <ViewSmall style={inlineStyle} />
            // case 'visibility':
            //     return <Visibility style={inlineStyle} />
            // case 'visibilityhidden':
            //     return <VisibilityHidden style={inlineStyle} />
            // case 'visibilityon':
            //     return <VisibilityOn style={inlineStyle} />
            // case 'warehouse':
            //     return <Warehouse style={inlineStyle} />
            // case 'warning':
            //     return <Warning style={inlineStyle} />
            // case 'webpage':
            //     return <WebPage style={inlineStyle} />
            // case 'weight':
            //     return <Weight style={inlineStyle} />
            case 'whisper':
                return <Whisper style={inlineStyle} />
            case 'check':
                return <Check style={inlineStyle} />
            case 'buyticket':
                return <BuyTicket style={inlineStyle} />
            case 'friends':
                return <Friends style={inlineStyle} />
            case 'addphoto':
                return <AddPhoto style={inlineStyle} />
            case 'adduser':
                return <AddUser1 style={inlineStyle} />
            case 'adduserclear':
                return <AddUser1Clear style={inlineStyle} />
            case 'emailwhite':
                return <EmailWhite style={inlineStyle} />
            case 'downloadwhite':
                return <DownloadWhite style={inlineStyle} />
            case 'yellow':
                return <Yellow style={inlineStyle} />
            case 'listbm':
                return <ListBm style={inlineStyle} />
            case 'documents3white':
                return <Documents3White style={inlineStyle} />
            case 'idwhite':
                return <IdWhite style={inlineStyle} />

            case 'mastercard':          return <MasterCard style={inlineStyle} />

            case 'billingcard':         return <BillingCard style={inlineStyle} />
            case 'billingcrypto':       return <BillingCrypto style={inlineStyle} />
            case 'billingtransfer':     return <BillingTransfer style={inlineStyle} />

            case 'billingcardnew':         return <BillingCardNew style={inlineStyle} />
            case 'billingcryptonew':       return <BillingCryptoNew style={inlineStyle} />
            case 'billingtransfernew':     return <BillingTransferNew style={inlineStyle} />
          
            case 'inputbusiness':       return <InputBusiness style={inlineStyle} />
            case 'inputmanageusers':    return <InputManageUsers style={inlineStyle} />
            case 'addresspin':          return <AddressPin style={inlineStyle} />
            case 'passwordinput':       return <PasswordInput style={inlineStyle} />

            case 'fullscreen':          return <Fullscreen style={inlineStyle} />
            case 'fullscreenexit':      return <FullscreenExit style={inlineStyle} />
            // case 'reactions': return <Reactions style={inlineStyle} />
            case 'bmlogoclear': return <BMlogoclear style={inlineStyle}/>
            case 'bmlogo': return <BMlogo style={inlineStyle}/>
            case 'facebook': return <Facebook style={inlineStyle}/>
            case 'linkedin': return <LinkedIn style={inlineStyle}/>
            case 'instagram': return <Instagram style={inlineStyle}/>
            case 'google': return <Google style={inlineStyle}/>
            case 'morealert': return <MoreAlert style={inlineStyle} />
            case 'star': return <Star style={inlineStyle} />
            // case 'speaker': return <Speaker style={inlineStyle} />
            case 'speakerclear': return <SpeakerClear style={inlineStyle} />
            case 'room': return <Room style={inlineStyle} />
            case 'stage': return <Stage style={inlineStyle} />
            case 'infringmentinfo': return <InfringmentInfo style={inlineStyle} />
            case 'billingprofilewhite': return <BillingProfileWhite style={inlineStyle} />

            default:                    return blankIcon
        }
    }

    return <>{className ? <div className={className}>{findSvgByName()}</div> : findSvgByName()}</>
}
