import { combineReducers } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
import authReducer from "./modules/auth";
import shopReducer from "./modules/shop";
import dataViewReducer from "./modules/dataView";
import pageReducer from "./modules/page";
import bmReducer from "./modules/bm";
import userReducer from "./modules/user";
import backDataReducer from "./modules/backData";
import menuReducer from "./modules/menu";
import formsReduces from "./modules/form";

// import menuReducer from "./modules/menu";
// import authReducer from "./modules/auth";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import websocket from "./modules/websocket";
import chatReducer from "./modules/chat";
import selectedMemberReducer from "./modules/selectedMember";
import { persistKey } from "staticData/general";

export const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem(`persist:${persistKey}`);
    storage.removeItem('authToken')
    storage.removeItem('refreshToken')
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const appReducer = combineReducers({
  auth: authReducer,
  shop: shopReducer,
  page: pageReducer,
  toastr: toastrReducer,
  user: userReducer,
  dataView: dataViewReducer,
  backData: backDataReducer,
  bm: bmReducer,
  ws:websocket,
  chat:chatReducer,
  menu:menuReducer,
  selectedMember: selectedMemberReducer,
  form: formsReduces,
});

export default rootReducer;
