import { toastr } from "react-redux-toastr";
import SvgIcon from "components/SvgIcon/SvgIcon";

export const showMagicToaster = ({
  title,
  subtitle,
  type = "success",
  timeOut = 3000
}) => {
  const icon =
    type === "success" ? (
      <SvgIcon name="Done" />
    ) : type === "error" ? (
      <SvgIcon name="alerttrinagle" />
    ) : type === "warning" ? (
      <SvgIcon name="alert" />
    ) : (
      <SvgIcon name="right1" />
    );

  const toastrOptions = {
    timeOut, // by setting to 0 it will prevent the auto close
    icon,
    // icon: (<SvgIcon name="Done" />), // You can add any component you want but note that the width and height are 70px ;)
    // onShowComplete: () => console.log('SHOW: animation is done'),
    // onHideComplete: () => console.log('HIDE: animation is done'),
    // onCloseButtonClick: () => console.log('Close button was clicked'),
    // onToastrClick: () => console.log('Toastr was clicked'),
    showCloseButton: true, // false by default
    closeOnToastrClick: true, // false by default, this will close the toastr when user clicks on it
    // this option will give you a func 'remove' as props
    component: (
      <div
        className="color-text-light-01"
        style={{ display: "flex", alignItems: "center" }}
      >
        {title && <div className="ptype-5-strong mr2">{title}</div>}
        {subtitle && <div className="ptype-5">{subtitle}</div>}
      </div>
    )
  };
  if (type === "success") toastr.success("", "", toastrOptions);
  if (type === "error") toastr.error("", "", toastrOptions);
  if (type === "warning") toastr.warning("", "", toastrOptions);
  if (type === "info") toastr.info("", "", toastrOptions);
};
