/* eslint-disable import/no-anonymous-default-export */

export const SET_EVENT_TICKETS = "bm/SET_EVENT_TICKETS";
export const SET_EVENT_DETAILS = "bm/SET_EVENT_DETAILS";
export const CHECKOUT_UPDATE_TICKET = "bm/CHECKOUT_UPDATE_TICKET";
export const SET_APPLIED_VOUCHERS = "bm/SET_APPLIED_VOUCHERS";
export const SET_ORDER_DETAILS = "bm/SET_ORDER_DETAILS";
export const SET_USER_PROFILE = "bm/SET_USER_PROFILE";
export const SET_AGENDA_KEY = "bm/SET_AGENDA_KEY";
export const SET_ORDERS = "bm/SET_ORDERS";
export const SET_INVOICES = "bm/SET_INVOICES";
export const SET_INVOICE_DETAILS = "bm/SET_INVOICE_DETAILS";
export const SET_TICKET_DETAILS = "bm/SET_TICKET_DETAILS";
export const SET_SPEAKERS = "bm/SET_SPEAKERS"
export const SET_SPEAKER_PROFILE = "bm/SET_SPEAKER_PROFILE"
export const SET_CONNECTIONS = "bm/SET_CONNECTIONS"
export const SET_MEMBERS = "bm/SET_MEMBERS"
export const SET_REQUESTS = "bm/SET_REQUESTS"
export const SET_CONVERSATIONS = "bm/SET_CONVERSATIONS"
export const SET_SPONSORS = "bm/SET_SPONSORS"
export const SET_SESSION_TEMPLATES = "bm/SET_SESSION_TEMPLATES"
export const SET_EVENT_GENERAL_STATS = "bm/SET_EVENT_GENERAL_STATS"
export const SET_REPLAY_ACCESS = "bm/SET_REPLAY_ACCESS";

const initialState = {
  setEventTickets: [],
  selectedEventDetails: undefined,
  replayAccess: undefined,
  selectedEventStats: undefined,
  setAppliedVouchers: [],
  // setOrderDetails: undefined, //moved to shop.order
  eventAgenda: {
    searchString: '', 
    selectedDay: null,
    selectedTypes: null, 
  },
  orders: [],
  invoices: [],
  invoiceDetails: {},
  userProfile: {},
  ticketDetails: {},
  sponsors: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AGENDA_KEY:
      return {
        ...state,
        eventAgenda: {
          ...state?.eventAgenda,
          [action.payload.key] : action.payload.value,
        },
      };
    case SET_EVENT_TICKETS:
      return {
        ...state,
        setEventTickets: action.payload,
      };
    case SET_EVENT_DETAILS:
      return {
        ...state,
        selectedEventDetails: action.payload,
      };
    case SET_REPLAY_ACCESS:
      return {
        ...state,
        replayAccess: action.payload,
      };
    case CHECKOUT_UPDATE_TICKET:
      return {
        ...state,
        setEventTickets: state.setEventTickets.map(item => 
          item.SK !== action.payload.id ? item : {
            ...item,
            quantity: action.payload.quantity 
              ?? ((item?.quantity ?? 0) + action.payload.delta)
          }
          ),
      };
    case SET_APPLIED_VOUCHERS:
      return {
        ...state,
        setAppliedVouchers: action.payload,
      };
    // case SET_ORDER_DETAILS:
    //   return {
    //     ...state,
    //     setOrderDetails: action.payload,
    //   };
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      };
    case SET_ORDERS:
      return {
        ...state,
        orders: [...action.payload]
      };
    case SET_INVOICES:
      return {
        ...state,
        invoices: [...action.payload]
      }
      case SET_INVOICE_DETAILS:
        return {
          ...state,
          invoiceDetails: {...action.payload}
        }
    case SET_TICKET_DETAILS:
      return {
        ...state,
        ticketDetails: {...action.payload}
      }
    case SET_SPEAKERS:
      return {
        ...state,
        speakers: [...action.payload]
      }
    case SET_SPEAKER_PROFILE:
      return {
        ...state,
        speakerProfile: {...action.payload}
      }
    case SET_CONNECTIONS:
      return {
        ...state,
        connections: [...action.payload]
      }
    case SET_MEMBERS:
      return {
        ...state,
        members: [...action.payload]
      }
    case SET_REQUESTS:
      return {
        ...state,
        requests: {...action.payload}
      }
    case SET_CONVERSATIONS:
      return {
        ...state,
        conversations: [...action.payload]
      }
    case SET_SPONSORS: 
      return {
        ...state,
        sponsors: [...action.payload]
      }
    case SET_SESSION_TEMPLATES: 
      return {
        ...state,
        sessionTemplates: [...action.payload]
      }
    case SET_EVENT_GENERAL_STATS:
      return {
        ...state,
        selectedEventStats: action?.payload
      }
    default:
      return state;
  }
}

export const setEventGeneralStats = (data) => ({
  type: SET_EVENT_GENERAL_STATS,
  payload: data
})

export const setEventTickets = (data) => ({
  type: SET_EVENT_TICKETS,
  payload: data,
})
export const setEventAgendaKey = (item) => {
  const firstKeyPair = Object.entries(item)[0]
  return {
  type: SET_AGENDA_KEY,
  payload: {
    key: firstKeyPair[0],
    value: firstKeyPair[1],
  },
}}

export const setSelectedEventDetails = (data) => ({
  type: SET_EVENT_DETAILS,
  payload: data,
})

export const setReplayAccess = (data) => ({
  type: SET_REPLAY_ACCESS,
  payload: data,
})


export const increaseTicket = (id) => ({
  type: CHECKOUT_UPDATE_TICKET,
  payload: {
    id,
    delta: 1,
  }
})
export const decreseTicket = (id) => ({
  type: CHECKOUT_UPDATE_TICKET,
  payload: {
    id,
    delta: -1,
  }
})
export const setTicketQuantity = (id, quantity) => ({
  type: CHECKOUT_UPDATE_TICKET,
  payload: {
    id,
    quantity,
  }
})
export const setAppliedVouchers = (data) => ({
  type: SET_APPLIED_VOUCHERS,
  payload: data,
})
// export const setOrderDetails = (data) => ({
//   type: SET_ORDER_DETAILS,
//   payload: data,
// })

export const setTicketDetails = (data) => ({
  type: SET_TICKET_DETAILS,
  payload: data,
})

export const setBmUserProfile = (data) => ({
  type: SET_USER_PROFILE,
  payload: data,
})

export const setOrders = (data) => ({
  type: SET_ORDERS,
  payload: data,
})

export const setInvoices = (data) => ({
  type: SET_INVOICES,
  payload: data,
})

export const setInvoiceDetails = (data) => ({
  type: SET_INVOICE_DETAILS,
  payload: data,
})

export const setSpeakers = (data) => ({
  type: SET_SPEAKERS,
  payload: data,
})

export const setSpeakerProfile = (data) => ({
  type: SET_SPEAKER_PROFILE,
  payload: data,
})

export const setConnections = (data) => ({
  type: SET_CONNECTIONS,
  payload: data,
})

export const setMembers = (data) => ({
  type: SET_MEMBERS,
  payload: data,
})

export const setRequests = (data) => ({
  type: SET_REQUESTS,
  payload: data,
})

export const setConversations = (data) => ({
  type: SET_CONVERSATIONS,
  payload: data,
})

export const setSponsors = (data) => ({
  type: SET_SPONSORS,
  payload: data,
})

export const setSessionTemplates = (data) => ({
  type: SET_SESSION_TEMPLATES,
  payload: data,
})