
export const leftMenu = {
  topItems: [
    {
      name: "Home",
      svg: "home",
      url: "/dashboard",
    },
    {
      name: "Events",
      svg: "tickets",
      url: "/events/list",
    },
    {
      name: "Event Details",
      svg: "tickets",
      url: "/events/EVT-event001/details",
    },
    {
      name:"Event Agenda",
      svg:"list",
      url:"/events/EVT-event001/agenda",
    },
    {
      name:"Event Live",
      svg:"list",
      url:"/events/EVT-event001/stream/SES-session10001",
    },
    {
      name:"Event Related",
      svg:"list",
      url:"/events/EVT-event001/related",
    },
    {
      name:"Event watch",
      svg:"list",
      url:"/events/EVT-event001/related/REL-0001",
    },
    {
      name:"Invoices",
      svg:"invoice-dollar",
      url:"/bm/invoices",
    },
    {
      name:"Orders",
      svg:"cart-empty",
      url:"/bm/orders/list",
    },
    {
      name:"Profile",
      svg:"bm-voucher",
      url:"/bm/profile/my-profile",
    },
  ],
  bottomItems:[
    {
      name:undefined,
      svg: "settings",
      url: "/bm/links",
    }
  ]
}