import { lazy } from "react";
import { Redirect } from "react-router";

export const bmDevRoutes = [
  {
    exact: true,
    path: "/dev/form",
    component: lazy(() => import("views/dev/Form")),
  },
  {
    exact: true,
    path: "/dev/buttons",
    component: lazy(() => import("views/dev/BmButtons")),
  },
  {
    exact: true,
    path: "/dev/buttons2",
    component: lazy(() => import("views/dev/BmButtons2")),
  },
  {
    exact: true,
    path: "/dev/funkytags",
    component: lazy(() => import("views/dev/FunkyTagsShowcase")),
  },
  {
    exact: true,
    path: "/dev/typography",
    component: lazy(() => import("views/dev/TypographyShowcase")),
  },
  {
    exact: true,
    path: "/dev/apicalls",
    component: lazy(() => import("views/dev/ApiCalls")),
  },
  {
    exact: false,
    path: "/bm/brandminds",
    component: () => <Redirect to="/bm/brandminds" />,
  },
  // =========== examples =================
  {
    exact: false,
    path: "/dev/ex/modalForm",
    component: lazy(() => import("views/dev/examples/modalForm")),
  },
  {
    exact: false,
    path: "/dev/ex/DateTimePickers",
    component: lazy(() => import("views/dev/examples/DateTimePickers")),
  },
  {
    exact: false,
    path: "/dev/ex/wizzardform",
    component: lazy(() => import("views/dev/examples/WizzardForm")),
  },
  {
    exact: false,
    path: "/dev/ex/icons",
    component: lazy(() => import("views/dev/examples/IconsPage")),
  },
  {
    exact: false,
    path: "/dev/ex/filepicker",
    component: lazy(() => import("views/dev/examples/FilePickerPage")),
  },
  {
    exact: false,
    path: "/dev/ex/RichTextInputPage",
    component: lazy(() => import("views/dev/examples/RichTextInputPage")),
  },
  {
    exact: false,
    path: "/dev/ex/tabbedform",
    component: lazy(() => import("views/dev/examples/TabbedForm")),
  },
  {
    exact: false,
    path: "/dev/ex/adminform",
    component: lazy(() => import("views/dev/examples/AdminFormPage")),
  },
  {
    exact: false,
    path: "/dev/ex/cards",
    component: lazy(() => import("views/dev/examples/CardsPage")),
  },
  {
    exact: false,
    path: "/dev/ex/filters",
    component: lazy(() => import("views/dev/examples/FiltersEample")),
  },
  // =========== individual playgrounds =================
  {
    exact: false,
    path: "/dev/sorin",
    component: lazy(() => import("views/dev/Sorin")),
  },
  {
    exact: false,
    path: "/dev/sorin1",
    component: lazy(() => import("views/dev/Sorin")),
  },
  {
    exact: false,
    path: "/dev/sorin2",
    component: lazy(() => import("views/dev/Sorin")),
  },
  {
    exact: false,
    path: "/dev/sorin3",
    component: lazy(() => import("views/dev/Sorin")),
  },
];
