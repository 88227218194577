import { lazy } from "react";
import { Redirect } from "react-router";

export const bmUserRoutes = [
  {
    exact: false,
    path: "/user/dashboard",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/dashboard/DashboardPage")),
  },
  {
    exact: false,
    path: "/user/events",
    component: lazy(() => import("../views/dev/gyula/BrandMinds/pages/dashboard/DashboardPage")),
  },
 
  {
    exact: false,
    path: "/user/",
    component: () => <Redirect to="/user/dashboard" />,
  },
];
