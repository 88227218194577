import { createSelector } from 'reselect'
import moment from 'moment'
import { crc32 } from "utils/cr32"

const rooms = (state) => state.chat?.rooms
const participants = (state) => state.chat?.participants
const messages = (state) => state.chat?.messages
const selectedRoom = (state) => state?.chat?.selectedRoom
const userPk = (state) => state?.user?.profile?.PK
const myConnections = (state) => state?.bm?.connections
const defaultValues = (state) => state?.page?.modalData

const _formatCDate = (cDate) => {
    let isToday = moment(cDate)?.isSame(moment(), "day")
    let isYesterday = moment(cDate)?.isSame(moment().subtract(1,'d'), "day")
    let isPastWeek = moment(cDate)?.isAfter(moment().subtract(1,'w'), "day")

    let formatedCDate = moment(cDate)?.format("DD.MM.yyyy")
    if (isToday) formatedCDate = moment(cDate)?.format("HH:mm")
    else if (isYesterday) formatedCDate = "Yesterday"
    else if (isPastWeek) formatedCDate = moment(cDate)?.format("dddd")

    return formatedCDate
}


export const chatParticipantsList = createSelector(
    rooms, participants, messages, userPk, selectedRoom,
    (rooms, participants, messages, userPk, selectedRoomFromSelector) => {
    

    // const dates = ["2022-03-08T12:08:24.360Z", "2022-03-07T12:08:24.360Z", "2022-03-04T12:08:24.360Z", "2022-03-01T12:08:24.360Z", "2022-02-04T12:08:24.360Z"]
    // dates.map(d => console.log(`${d} : ${_formatCDate(d)}`))

    let roomsList = Object.entries(rooms).map(([roomId, item], index) => {
        const otherParticipants = participants[roomId]?.filter(e => e.id !== userPk)
        const lastMessage = messages[roomId]?.slice(-1)[0]
        //if last message is mine, unread = 0 
        let unreadNumber, unread
        if (lastMessage?.userId !== userPk){
            const me = participants[roomId]?.find(e => e.id === userPk)
            const indexOfLast = messages[roomId]?.findIndex(e => e?.SK === me?.lastRead?.SK)
            if (messages[roomId]?.length > 0) {
                unreadNumber = messages[roomId]?.length - indexOfLast - 1
                unread = unreadNumber
            }
            if (unread > 10) unread = "10 +"
            if (unread <= 0) unread = undefined
            // if (isNaN(unread) && unread !== undefined) debugger
        }   
        let name = item?.name
        if (otherParticipants?.length === 1 && !name) name = `${otherParticipants[0]?.firstName} ${otherParticipants[0].lastName}`
        if (!name) name = `No Name Chat ${index}`
        // if(roomId === "CHR-0000aaaa") debugger

        return {
            ...item,
            lastMessageText: lastMessage?.text,
            name, 
            isGroup: otherParticipants?.length > 1,
            cDate: _formatCDate(lastMessage?.SK?.split("#")[1]),
            unread,
            unreadNumber
        }

    })
    const roomListMap = {};
    roomsList.forEach(room => roomListMap[room.PK] = room);

    const noSelectedRoom = JSON.stringify(selectedRoomFromSelector) === JSON.stringify({})

    return {
        roomsList: roomsList.sort((a, b) => a?.cDate > b?.cDate ? -1 : 1),
        messages,
        roomListMap,
        selectedRoom: !noSelectedRoom ? selectedRoomFromSelector : roomsList?.[0]?.PK
    }
})

export const chatMessagesList = createSelector(
    participants, messages, selectedRoom, rooms, userPk,
    (participants, messages, selectedRoomFromSelector, rooms, userPk, ) => {

    const usersDict = {}

    const noSelectedRoom = JSON.stringify(selectedRoomFromSelector) === JSON.stringify({})
    const computeSelectedRoom = !noSelectedRoom ? selectedRoomFromSelector : rooms?.[0]?.PK 
    const MY_USERNAME_COLOR = "#FFDA66";
    const chatColors = ["#FA4D56", "#FF8389", "#FFB3B8", "#24A148", "#42BE65", "#6FDC8C", "#4589FF", "#78A9FF", "#A6C8FF", "#A56EFF", "#BE95FF", "#D4BBFF", "#1192E8", "#33B1FF", "#82CFFF", "#009D9A", "#08BDBA", "#3DDBD9", "#EE5396", "#FF7EB6", "#FFAFD2"]
    const getUserColor = (userId, myId) => userId === myId ? MY_USERNAME_COLOR : chatColors[(crc32(userId) % chatColors.length)]

    const messageList = messages?.[computeSelectedRoom]?.filter(e => e)?.map(item => {
        if (!usersDict[item?.userId]) {
            const user = participants[computeSelectedRoom]?.find(e => e.id === item?.userId)
            usersDict[item?.userId] = user ? `${user?.firstName} ${user.lastName}` : item?.userId
        }

        const cDate = item?.SK?.split("#")[1]
        return {  /// her we format the chat message for the list
            userId: item?.userId,
            name: item?.userId === userPk ? "Me" : usersDict[item?.userId],
            usernameColor: getUserColor(item?.userId, userPk),
            text: item.text,
            cDate,
            SK: item.SK,
            mine: item?.userId === userPk,
            info: false
        }
    })

    let messageListWithInfo = []
    messageList?.forEach((e, i) => {

        if (!moment(messageList?.[i-1]?.cDate)?.isSame(moment(e?.cDate), "day"))
            messageListWithInfo.push({info: true, text: _formatCDate(e?.cDate)})

        messageListWithInfo.push(e)
    })

    const me = participants[computeSelectedRoom]?.find(e => e.id === userPk)

    const selectedRoomFull = rooms?.[computeSelectedRoom]
    
    return {
        selectedRoom: computeSelectedRoom,
        messageList: messageListWithInfo,
        lastRead: me?.lastRead,
        isAdmin: me?.admin,
        isActive: me?.active,
        reason: me?.reason, // LEAVE | REMOVE
        selectedRoomFull,
    }
})

export const chatModalData = createSelector(
    rooms, participants, messages, selectedRoom, userPk, myConnections,
    (rooms, participants, messages, selectedRoom, userPk, myConnections, ) => {

    const roomParticipants = participants[selectedRoom]
    const me = roomParticipants?.find(e => e.id === userPk)

    let participantsList = []
    roomParticipants?.forEach(e => {
        if (e.id !== userPk) {
            const he = myConnections?.find(myConnection => myConnection.PK === e.id)
            let participantToAdd = {
                ...he,
                PK: e?.id,
                avatar: he?.avatar,
                admin: e.admin,
                active: e?.active,
            }
            participantsList.push(participantToAdd)
        }
    })
    
    // let connectionsList = myConnections?.map(item => {
    //     const he = roomParticipants?.find(e => e.id === item?.PK && e.id !== userPk)
    //     if (he)
    //         return { 
    //             ...item,
    //             admin: he?.admin,
    //             active: he?.active,
    //             }
    // })


    let connectionsOutsideRoom = []
    if (me?.admin) {
        connectionsOutsideRoom = myConnections?.filter(connection => {
            if (!roomParticipants.find(participant => participant.id === connection.PK))
                return true
            return false
        })
    }

    return {
        connectionsList: [...participantsList,...connectionsOutsideRoom],
        isAdmin: me?.admin,
        isActive: me?.active,
        selectedRoom: selectedRoom,
    }
})

export const newChatModalData = createSelector(
    defaultValues, myConnections,
    (defaultValues, myConnections, ) => {

    let connectionsList = myConnections?.map(item => {
        const he = defaultValues?.participants?.find(e => e.id === item?.PK)
        return { 
            ...item,
            isActive: he?.active,
            }
    })

    return {
        connectionsList,
        defaultValues,
    }
})

