// const ADD_TO_CART = "shop/ADD_TO_CART"
import { apiAction } from "store/middleware/api";
import { setApiResponse } from "./auth";
import { dynamoAction } from "store/middleware/api";

export const SET_VALIDATION_RULES = "backData/SET_VALIDATION_RULES";
export const SET_DICTIONARY = "backData/SET_DICTIONARY";
export const SET_PRODUCT_CATEGORY = "backData/SET_PRODUCT_CATEGORY";
export const SET_ENUM = "backData/SET_ENUM";
export const SET_LOGIN_CALLS = "backData/SET_LOGIN_CALLS";
export const UPDATE_LOGIN_CALLS = "backData/UPDATE_LOGIN_CALLS";
export const SET_LIST = "backData/SET_LIST";
export const SET_PAGE_SPECIFIC_DATA = "backData/SET_PAGE_SPECIFIC_DATA";
export const LOAD_ALL_OPTIONS = "backData/LOAD_ALL_OPTIONS";
//new action: loadd all options

const initialState = {
  validationRules: [],
  ddOptions: {},
  dictionary: {},
  enums: {},
  productCategory: [],
  lists: {}, //simmilar with dictionary/ENUMs but arbitrary data structure 
  loginCalls: 0, // used to redirect after all the login calls are done
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_VALIDATION_RULES:
      return {
        ...state,
        validationRules: action.payload,
      };
    case SET_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategory: action.payload,
      };
    case SET_LOGIN_CALLS:
      return {
        ...state,
        loginCalls: action.payload,
      };
    case UPDATE_LOGIN_CALLS:
      return {
        ...state,
        loginCalls: state.loginCalls + action.payload,
      };
    case SET_DICTIONARY:
      return {
        ...state,
        dictionary: { ...state.dictionary, ...action.payload },
      };
    case SET_PAGE_SPECIFIC_DATA:
      return {
        ...state,
        pageSpecific: { ...state.dictionary, ...action.payload },
      };
    case SET_ENUM:
      return {
        ...state,
        enums: { ...state.enums, ...action.payload },
      };
    case SET_LIST:
      return {
        ...state,
        lists: { ...state.lists, ...action.payload },
      };
    case LOAD_ALL_OPTIONS:
      return {
        ...state,
        ddOptions: { ...state.ddOptions, ...action.payload },
      };
    default:
      return state;
  }
}

export const loadValidationRules = () =>
  apiAction({
    endPoint: "/public/validation-rules",
    onSuccess: (data, dispatch) => {
      console.log("API data", data);
      dispatch(setApiResponse(data));
      return {
        type: SET_VALIDATION_RULES,
        payload: data,
      };
    },
    onFailure: () => {
      console.log("error occured custom");
      return {
        type: "a",
      };
    },
  });

export const setBackList = (id, data) => ({
  type: SET_LIST,
  payload: {[id] : data}
})
export const setLoginCalls = (data) => ({
  type: SET_LOGIN_CALLS,
  payload: data
})
export const incrementLoginCall = () => ({
  type: UPDATE_LOGIN_CALLS,
  payload: 1
})
export const decrementLoginCall = () => ({
  type: UPDATE_LOGIN_CALLS,
  payload: -1
})

export const loadPageSpecificData = ({endPoint, method= "GET", data, id}) =>
  apiAction({
    endPoint, method, data,
    onSuccess: (data, dispatch) => {

      let payload = {};
      payload[id] = data;
      return {
        type: SET_PAGE_SPECIFIC_DATA,
        payload,
      };
    },
    onFailure: (data, dispatch) => {

      console.log("error occured custom");
      return {
        type: "a",
      };
    },
  });
export const loadDictionary = (id) =>
  apiAction({
    endPoint: "/dictionary/type/" + id,
    onSuccess: (data, dispatch) => {
      // dispatch(setApiResponse(data));
      // dispatch(incrementLoginCall());
      let payload = {};
      payload[id] = data;
      return {
        type: SET_DICTIONARY,
        payload,
      };
    },
    onFailure: (data, dispatch) => {
      // dispatch(incrementLoginCall());
      console.log("error occured custom");
      return {
        type: "a",
      };
    },
  });
export const loadGeneralSearch = (iType) =>
  apiAction({
    endPoint: "/general/search",
    method: "POST",
    data: { "iType": iType, "from": 0, "size": 400,},
    onSuccess: (data, dispatch) => {
      return {
        type: SET_DICTIONARY,
        payload : {[iType] : data?.Items},
      };
    }
  })
  
export const loadDictionaryAws = (id) =>
  apiAction({
    endPoint: "/public/dictionary",
    method: 'POST',
    data: {
      dictionaryType:id
    },
    onSuccess: (data, dispatch) => {
      // dispatch(setApiResponse(data));
      // dispatch(incrementLoginCall());
      let payload = {};
      payload[id] = data?.items?.map(e => ({...e, id: e.PK})) ?? [];
      return {
        type: SET_DICTIONARY,
        payload,
      };
    },
    onFailure: (data, dispatch) => {
      // dispatch(incrementLoginCall());
      console.log("error occured custom");
      return {
        type: "a",
      };
    },
  });

  export const getAllRoles = (toList) =>
  apiAction({
    endPoint: "/group/alloptions/",
    onSuccess: (data, dispatch) => {
      console.log("API data", data);
      let payload = {};
      payload["ALL_ROLES"] = data;
      return {
        type: SET_DICTIONARY,
        payload,
      };
    },
    onFailure: (data, dispatch) => {
      dispatch(incrementLoginCall());
      console.log("error occured custom");
      return {
        type: "a",
      };
    },
  });
  // export const getAllUniLocations = (toList) =>
  // apiAction({
  //   endPoint: "/organization/workplace/search",
  //   onSuccess: (data, dispatch) => {
  //     console.log("API data", data);
  //     let payload = {};
  //     payload["ALL_UNI_LOCATIONS"] = data;
  //     return {
  //       type: SET_DICTIONARY,
  //       payload,
  //     };
  //   },
  //   onFailure: (data, dispatch) => {
  //     console.log("error occured custom");
  //     return {
  //       type: "a",
  //     };
  //   },
  // });
export const loadProductCategories = () =>
  apiAction({
    endPoint: "/product-category/all",
    onSuccess: (data, dispatch) => {
      console.log("API data", data);
      dispatch(setApiResponse(data));
      dispatch(incrementLoginCall());
      
      return {
        type: SET_PRODUCT_CATEGORY,
        payload: data,
      };
    },
    onFailure: (data, dispatch) => {
      dispatch(incrementLoginCall());
      console.log("error occured custom");
      return {
        type: "a",
      };
    },
  });

export const loadEnums = (shortType) =>
  // enums?type=eu.mgs.synergic.model.product.ProductStockStatus
  apiAction({
    endPoint: "/enums?type=eu.mgs.synergic.model." + shortType,
    onSuccess: (data, dispatch) => {
      console.log("API data", data);
      dispatch(setApiResponse(data));
      dispatch(incrementLoginCall());
      let payload = {};
      payload[shortType] = data;
      return {
        type: SET_ENUM,
        payload,
      };
    },
    onFailure: (data, dispatch) => {
      dispatch(incrementLoginCall());
      console.log("error occured custom");
      return {
        type: "a",
      };
    },
  });

export const loadAllDdOptions = () =>
  dynamoAction({
    method: "QUERY",
    parameters: {
      TableName: "TempDevStack-MainTable-74SE74RNUI3D",
      KeyConditionExpression: "#pk = :pkvalue",
      IndexName: "SK-PK",
      ExpressionAttributeNames: {
        "#pk": "SK",
      },
      ExpressionAttributeValues: {
        ":pkvalue": { S: "DDOPTIONS" },
      },
    },
    onSuccess: (data, dispatch) => {
      return {
        payload: data?.items?.map(item => ({[item?.name] : item?.options})),
        type: LOAD_ALL_OPTIONS,
        // lastEvalKey: data.lastEvalKey,
      };
    },
    onFailure: (err) => {
      // showMagicToaster({title: err})
      console.log(err);
      return {
        type: "a",
      };
    },
  });
