
import uniqueID from "utils/uniqueId";

export const header = {
  logo:"/images/brand-minds/BMLogo.svg",
  actions:[
    {
      id:uniqueID(),
      svg: "bm-contacts",
      url: "/bm/page",
    },
    {
      id:uniqueID(),
      svg: "chats",
      url: "/bm/page",
    },
    {
      id:uniqueID(),
      svg: "bell",
      url: "/bm/page",
    },
  ],
  options:[
    {
      id:uniqueID(),
      value:"1",
      label:"Company Name A Srl"
    },
    {
      id:uniqueID(),
      value:"2",
      label:"Company Name B Srl"
    },
    {
      id:uniqueID(),
      value:"3",
      label:"Company Name C Srl dsjgpojpgosioghpsriogh"
    },
  ],
  user:{
    src:"/images/brand-minds/default-user.png",
    alt:"user name"
  }
}