import React from 'react'
import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"

//Style
import './BmBigModalV2.scss'

//Data
import { setPageModal } from "store/modules/page"

function BmBigModalV2(props) {
  const { title, subtitle, closeAction, name,sysModal, open, fullScreen=true, showClose=true, noMargin=false} = props
  const dispatch = useDispatch()
  const pageModal = useSelector((state) => state?.page?.modal)
  const modalRoot = sysModal
  ? document.getElementById("root")
  :fullScreen
    ? document.querySelectorAll(".full-screen-modal-placeholder")[0]
    : document.querySelectorAll(".main-modal-placeholder")[0]
 

  useEffect(() => {
    if (pageModal === name || pageModal?.name === name)
      modalRoot.classList.add("modal-open");
      modalRoot?.scrollTo(0,0);

    var modalBodyElem = document.querySelector(".modal-body");
    if (!modalBodyElem?.querySelectorAll(".modal-footer")?.length)
      modalBodyElem?.classList?.add("no-footer")
  }, [pageModal])
  
  
  if (!(pageModal === name || pageModal?.name === name || open)) return null

  const onClose = () => {
    dispatch(setPageModal())
    if (closeAction) closeAction()
    modalRoot.classList.remove("modal-open");
  }

  return  createPortal(<>
    <div className="bmbigmodalv2-wrapper">      
      <div className={`content ${noMargin ? 'no-margin' : ''}`}>
        {subtitle && <div className="ptype1 text-center">{subtitle ?? "[Subtitle]"}</div>}
        {title && <div className="etype-5 text-center">{title ?? "[Title]"}</div>}
        {showClose &&
          <button className={"modal-close"} onClick={() => onClose()}>
            {close}
          </button>
        }
        <div className={`modal-body ${name}`}>{props.children}</div>
      </div>
    </div>
  </>, modalRoot )
}

export default BmBigModalV2;


const close = 
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#333333"/>
<path d="M35 21L21 35" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M35 35L21 21" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

