import { Redirect } from "react-router"
import { useUserAuthenticated, useUserInfo } from "utils/useUserInfo"

function AuthGuard(props) {
  // const {noGuest} = props
  const { isAdmin, isModerator } = useUserInfo()
  const pathName = window.location.pathname

  if (pathName.includes("/admin")) {
    if (!isAdmin) return <Redirect to="/" />
  }
  return props.children
}

export default AuthGuard


export function ClientGuard(props) {
  const isAuth = useUserAuthenticated()
  if (!isAuth) 
    return <Redirect to="/login" />


  return props.children
}