import React from 'react'

const Pill = ({ pill, large, text,rightIcon, noicon, className:propsClassName, bgColor }) => {

    let className=`pill ${large ? 'large' : ''} ${propsClassName ?? ''}`
    if (bgColor) className += ` no-static-bg bg-${bgColor}`
    return (
        <span {...{className}}>
            {!noicon && !rightIcon && icon}
            {pill && <span className={`txt ${rightIcon ? "mr1" : ''}`}>{pill}</span>}
            {text && <span className={`txt ${rightIcon ? "mr1" : ''}`}>{text}</span>}
            {rightIcon && icon}
        </span>
    )
}
export default Pill


const icon = <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.03274 7.58965L5.22936 4.99493L6.48828 2.29525C6.60131 2.04865 6.65086 1.7777 6.63242 1.50706C6.61398 1.23643 6.52813 0.974702 6.38268 0.745723C6.23801 0.517503 6.03807 0.329499 5.80138 0.19914C5.5647 0.06878 5.29893 0.000283635 5.02872 0L1.60739 0C0.719958 0 0 0.72397 0 1.61732V12L2.51806 9.98944H5.62387C6.51088 9.98944 7.23147 9.26484 7.23147 8.37191C7.23201 8.0986 7.16366 7.82956 7.03274 7.58965V7.58965ZM5.28427 8.15565H1.8226V1.834H4.69187L3.22175 4.99493L5.28427 8.15565Z" fill="black"/>
</svg>