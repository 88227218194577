import {  BrowserRouter  } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import routes, { renderRoutes } from 'routes/routes';
// import "./App.css";
import React, { useEffect } from "react";
import ThemeContext from "./theme/themeProvider";
import { theme } from "./theme/theme";
import ReduxToastr from 'react-redux-toastr'
import AWS from 'aws-sdk'
import { Amplify } from "aws-amplify";
import * as yup from "yup";
import { useSelector } from 'react-redux';
import { selectFormErros } from 'store/selectors/form/formErrors.selector';
import "styleBM/index.scss";
import { useDispatch } from 'store';
import { setPageEdit } from 'store/modules/page';
import ConfirmActionModal from 'components/_modals/ConfirmActionModal';
import { wsConnect } from 'store/modules/websocket';
import { setIsAdmin } from 'store/modules/auth';
import { isMobile } from 'react-device-detect';
import MobilePage from 'views/mobile/MobilePage';

// const history = createBrowserHistory({forceRefresh:true});

AWS.config.region = process.env.REACT_APP_USER_REGION; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
});

// Called when an identity provider has a token for a logged in user
export function userLoggedIn(providerName, token) {
  const creds = AWS.config.credentials
  creds.params.Logins = creds.params.Logins || {};
  creds.params.Logins[providerName] = token;
  //console.log(creds.params.Logins)
  // Expire credentials to refresh them on the next request
  creds.expired = true;
}
export function userLogOut() {
  const creds = AWS.config.credentials
  creds.params.Logins = {};
  creds.expired = true;
}

Amplify.configure({
  Auth: {
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_USER_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID
  }
});

function App() {
  const {yupLocale} = useSelector(state => selectFormErros(state))
  const authenticatedState = useSelector(state => state.auth?.authenticated)
  const userPk = useSelector(state => state.auth?.userData.PK)
  const dispatch = useDispatch() //temp auto login
  useEffect(() => {
    yup.setLocale(yupLocale);
}, [yupLocale])

  useEffect(() => {
    const adminPath = window.location.pathname?.indexOf("admin") > -1
    dispatch(setIsAdmin(adminPath ? true : false))
    
    // if (!(window.location.pathname?.includes('guest') || window.location.pathname?.includes('frame')))
    //   dispatch(wsConnect())
  },[])

  const url = window.location.pathname
  useEffect(() => {
    if (url.includes("form")) dispatch(setPageEdit(false))
  }
  ,[url])

  useEffect(() => {
    if (userPk) {
      dispatch(wsConnect())
    } 
  }
  ,[userPk])

  if (isMobile)
    return (<MobilePage />)
  else
    return (
      <ThemeContext.Provider value={theme}>
        <BrowserRouter  >
          {renderRoutes(routes)}
          <ReduxToastr
            timeOut={10000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-right"
            getState={(state) => state.toastr} // This is the default
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            // progressBar
            closeOnToastrClick={true}/>
          <ConfirmActionModal/>
        </BrowserRouter >
      </ThemeContext.Provider>
    );
}

export default App;
