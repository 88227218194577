import { Link } from 'react-router-dom';
import SvgIcon from 'componentsBM/SvgIcon';
import Pill from 'views/dev/gyula/BrandMinds/components/Pill';
import { useDispatch, useSelector } from 'react-redux';
import { setPageModal } from 'store/modules/page';
import Spinner from "componentsBM/Spinner";

import "./Btn.scss"

export const InnerBtn = props => {

  const { leftIcon, rightIcon, onlyIcon, coins, label } = props

  return (
    <div className={'inner'}>
      {leftIcon && <span className={'icon icon-left'}><SvgIcon name={leftIcon} /></span>}
      {label && <span className={'label'}>{label}</span>}
      {coins && <Pill pill={coins} />}
      {rightIcon && <span className={'icon icon-right'}><SvgIcon name={rightIcon} /></span>}

      {onlyIcon && <span className={'icon'}><SvgIcon name={onlyIcon} /></span>}
    </div>
  )
}

export const Btn = props => {
  const {
    href, onClick,
    small, large,
    primary = true, secondary,
    low, high,
    leftIcon, rightIcon, onlyIcon,
    className,
    noMargins,
    w100,
    disabled,
    special,
    showModal, dispatch,
    w, //button width in increments of 4px
    dldUrl, url, svg, label,
    loadingId } = props;
  const dispatchFunction = useDispatch()
  const loadingState = useSelector(state => state.page?.loadingIndicators?.[loadingId ?? 'none'])

  let btnClassName = 'bm-btn'
  if (small) btnClassName += ' small'
  if (large) btnClassName += ' large'

  if (primary && !secondary && !special) btnClassName += ' primary'
  if (secondary) btnClassName += ' secondary'

  if (low) btnClassName += ' low'
  if (high) btnClassName += ' high'


  if (leftIcon) btnClassName += ' left-icon'
  if (rightIcon) btnClassName += ' right-icon'
  if (onlyIcon) btnClassName += ' only-icon'

  if (className) btnClassName += ` ${className}`
  if (noMargins) btnClassName += 'no-margins'
  if (w100) btnClassName += ' w-100'

  if (special === "alert") btnClassName += ' special special-alert'
  if (special === "warning") btnClassName += ' special special-warning'
  if (special === "success") btnClassName += ' special special-success'
  if (special === "yellow") btnClassName += ' special special-yellow'

  if (disabled) btnClassName += ' disabled'



  // const btnClassName = `btn btn-${variant?variant:'plain'} ${className??''} ${disabled?'disabled':''}`
  let computedOnClick = undefined;
  if (dispatch) computedOnClick = () => dispatchFunction(dispatch)
  if (dldUrl) {
    const downloadFromUrl = (url, name) => {
      // const link = document.createElement("a");
      // link.target = "_blank"
      // link.download = name;
      // link.href = url;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    }
    computedOnClick = () => downloadFromUrl(dldUrl, 'Test');
  }


  if (onClick) computedOnClick = onClick;
  if (showModal) computedOnClick = () => dispatchFunction(setPageModal(showModal));

  let style = {}
  if (w) style.width = `${4*w}px`

  let buttonProps = {
    disabled,
    style,
    className: btnClassName,
    onClick: (disabled || loadingState)
      ? undefined
      : e => { computedOnClick && computedOnClick(); e.stopPropagation() }
  };

  const content = <>
    {svg && <SvgIcon name={svg} />}
    {label}
  </>

  const loadingIndicator = loadingState && 
    <div className='loading-indicator'>
      <Spinner color="white"/>
    </div>

    if (dldUrl && !disabled && !loadingState) { //a for downloading stuff
      buttonProps.href = dldUrl
      return <a {...buttonProps} target={"_blank"}>
        <InnerBtn {...props} />
        {loadingIndicator}
      </a>
      // return <a className={baseClassName} href={dldUrl}><div  {...buttonProps}><InnerBtn {...props} /></div></a>
    } else if ((url || href) && !disabled && !loadingState) { //Link to user react router history
      buttonProps.to = (url || href)
      // buttonProps.onClick = onClick
      return <Link {...buttonProps}>
        <InnerBtn {...props} />
        {loadingIndicator}
      </Link>
    } else { //plain button
      // buttonProps.onClick = onClick
      return <button {...buttonProps} >
        <InnerBtn {...props} />
        {loadingIndicator}
        </button>
    }


  // return (
  //   <>
  //     {href &&
  //       <Link className={btnClassName} to={href}>
  //         <InnerBtn {...props} />
  //       </Link>
  //     }
  //     {!href &&
  //       <button  {...buttonProps}>
  //         <InnerBtn {...props} />
  //       </button>
  //     }
  //   </>
  // )
}
export default Btn;