import { useDispatch } from 'react-redux';

// Components
import BmBigModalV2 from 'componentsBM/BmBigModalV2';
import SimpleCard from 'componentsBM/SimpleCard';
import Btn from 'componentsBM/Btn';

// Style
import "./DeleteMyAccountModal.scss"

// Data
import { setPageModal } from 'store/modules/page';
import { deleteMyAccount } from 'store/apiCalls/apiActions';

function DeleteMyAccountModal() {

  const dispatch = useDispatch()

  return (
    <BmBigModalV2 name="deleteMyAccount" subtitle="Account deletion" title="Warning! You are about to delete your account">
      <SimpleCard>
          <div className="etype-3 mb6">Deleting your BRAND MINDS account is PERMANENT!</div>
          <SimpleCard grey80bg tight>
              <div className="ptype-1-strong mb4">Very important information</div>
              <div className="ptype-1 mb4">It makes us very sad to see you leave.</div>
              <div className="ptype-1 mb4">Before taking this step, we would love a shot at upgrading your experience. Please take into consideration that 92% of the incoming requests raised by like-minded people are solved with a 5star rating. Our team is ready to address your questions at: <a href="mailto:contact@brandminds.com">contact@brandminds.com</a>.</div>
              <div className="ptype-1 mb4">By proceeding, you will delete your BRAND MINDS Membership Application account. This action is irreversible. As a result, you will lose all your connections, personal profile details, billing profiles, rewards, and in-app appreciations.</div>
              <div className="ptype-1">Please note that your orders, invoices, and tickets will continue to exist as per the requirements of the law.</div>
          </SimpleCard>
      </SimpleCard>

      <div className='modal-footer'>
        <Btn special="alert" low {...{label:"Confirm PERMANENT account deletion", onClick:() => dispatch(deleteMyAccount())}} />
        <Btn secondary low {...{label:"Back to safety", onClick:() => dispatch(setPageModal())}} />
      </div>
    </BmBigModalV2>
  )
}

export default DeleteMyAccountModal