import { currentVersion } from "currentVersion"

export const devSectionMenuMock = {
  title: "DEV ZONE",
  id: 'dev',
  svg: 'settings',
  label: 'Dev',
  btnLabel: 'Dev',
  hero: {
    title: "Development page links",
    // img: "https://brandmindsawsbestack-assetsbuckete5c0e90f-dbw5in6gd3pf.s3.eu-central-1.amazonaws.com/real-event/brand-minds-2022-main-stage.png",
    // subtitle: "Event.Dates, Event.VenueLocation",
    description:
      `Here you'l find a collection of links that take you to specific pages in the app - V-${currentVersion}`,
  },
  entries: [
    {
      label: "Home",
      btnLabel: "Home",
      svg: "home",
      href: "/dashboard",
    },
    {
      label: "Events",
      btnLabel: "Events",
      svg: "tickets",
      href: "/events/list",
    },
    {
      label: "Event Details",
      btnLabel: "Event Details",
      svg: "tickets",
      href: "/events/EVT-event001/details",
    },
    {
      label:"Event Agenda",
      btnLabel:"Event Agenda",
      svg:"list",
      href:"/events/EVT-event001/agenda",
    },
    {
      label:"Event Live",
      btnLabel:"Event Live",
      svg:"list",
      href:"/events/EVT-event001/stream/SES-session10001",
    },
    {
      label:"Event Related",
      btnLabel:"Event Related",
      svg:"list",
      href:"/events/EVT-event001/related",
    },
    {
      label:"Event watch",
      btnLabel:"Event watch",
      svg:"list",
      href:"/events/EVT-event001/related/REL-0001",
    },
    {
      label:"Invoices",
      btnLabel:"Invoices",
      svg:"invoice-dollar",
      href:"/bm/invoices",
    },
    {
      label:"Orders",
      btnLabel:"Orders",
      svg:"cart-empty",
      href:"/bm/orders/list",
    },
    {
      label:"Profile",
      btnLabel:"Profile",
      svg:"bm-voucher",
      href:"/bm/profile/my-new-profile",
    },
    {
      label:"Api calls",
      btnLabel:"API",
      svg:"bm-voucher",
      href:"/dev/apicalls",
    },
    {
      label: "EXAMPLE PAGES",
      btnLabel: "Progress",
      collapse: true,
      children: [
        {
          label: "Icons",
          btnLabel: "Icons",
          href:"/dev/ex/icons",
        },
        {
          label: "Modal Form",
          btnLabel: "Modal Form",
          href: "/dev/ex/modalForm",
        },
        {
          label: "Wizzard Form",
          btnLabel: "Wizzard Form",
          href: "/dev/ex/wizzardform",
        },
        {
          label: "DateTimePickers",
          btnLabel: "DateTimePickers",
          href: "/dev/ex/DateTimePickers",
        },
        {
          label: "filepicker",
          btnLabel: "filepicker",
          href: "/dev/ex/filepicker",
        },
        {
          label: "RichTextInputPage",
          btnLabel: "RichTextInputPage",
          href: "/dev/ex/RichTextInputPage",
        },
        {
          label: "filters",
          btnLabel: "filters",
          href: "/dev/ex/filters",
        },
        {
          label: "tabbedform",
          btnLabel: "tabbedform",
          href: "/dev/ex/tabbedform",
        },
        {
          label: "Admin Form Page",
          btnLabel: "Admin Form",
          href: "/dev/ex/adminform",
        },
        {
          label: "typography",
          btnLabel: "typography",
          href: "/dev/typography",
        },
        {
          label: "funkytags",
          btnLabel: "funkytags",
          href: "/dev/funkytags",
        },
        {
          label: "buttons2",
          btnLabel: "buttons2",
          href: "/dev/buttons2",
        },
        {
          label: "form",
          btnLabel: "form",
          href: "/dev/form",
        },
        {
          label: "Cards",
          btnLabel: "Cards",
          href: "/dev/ex/cards",
        },
      ]
    },
  ]
}


export const sideMenuMindTools = {
  title: "MIND TOOLS",
  id: 'mind',
  label: 'Mind Tools',
  btnLabel: 'Mind Tools',
  svg: 'books',
  hero: {
    img: "https://brandmindsawsbestack-assetsbuckete5c0e90f-dbw5in6gd3pf.s3.eu-central-1.amazonaws.com/thumb/mind-tools-menu.png",
    description: "Dive deep into more than 100 Mind Tools to level up your Strategy, Marketing and Leadership skills.",
  },
  entries: [
    {
      label: "YOUR PROGRESS",
      btnLabel: "Progress",
      children: [
        {
          label: "Completed",
          btnLabel: "Completed",
          href: "#main",
        },
        {
          label: "Ongoing",
          btnLabel: "Ongoing",
          href: "#main",
        },
      ]
    },
    {
      label: "LEARNING CENTER",
      btnLabel: "Learning",
      children: [
        {
          label: "Daily Bits",
          btnLabel: "Daily Bits",
          href: "#main",
        },
        {
          label: "Mind Tools",
          btnLabel: "Mind Tools",
          href: "#main",
        },
        {
          label: "Classes",
          btnLabel: "Classes",
          href: "#main",
        },
      ]
    },
  ]
}

export const sideMenuNetworking = {
  title: "NETWORKING",
  id: 'networking', 
  label: 'Networking',
  btnLabel: 'Networking',
  svg: 'inputmanageusers',
  hero: {
    img: "https://brandmindsawsbestack-assetsbuckete5c0e90f-dbw5in6gd3pf.s3.eu-central-1.amazonaws.com/real-event/brand-minds-2022-main-stage.png",
    description: "Join over 20.000 Senior Managers and C-suite Executives in the largest community of global thinkers.",
  },
  entries: [
    {
      label: "MY NETWORK",
      btnLabel: "My Network",
      children: [
        {
          label: "Connections",
          btnLabel: "Connections",
          href: "/network/myconnections/list",
        },
        {
          label: "Requests",
          btnLabel: "Requests",
          href: "/network/request/list",
        },
        {
          label: "Followed Speakers",
          btnLabel: "Followed Speakers",
          href: "/network/myspeakers/list",
        },
        {
          label: "Conversations",
          btnLabel: "Conversations",
          href: "/network/messages",
        },
      ]
    },
    {
      label: "EXPLORE THE COMMUNITY",
      btnLabel: "Community",
      children: [
        {
          label: "Members",
          btnLabel: "Members",
          href: "/network/member/list",
        },
        {
          label: "Speakers",
          btnLabel: "Speakers",
          href: "/network/speakers/list",
        },
      ]
    },
  ]
}
export const sideMenuHome = {
  title: "HOME",
  id: 'home',
  label: 'Home',
  btnLabel: 'Home',
  svg: 'home',
  href: "/dashboard",
}

export const sideMenuAccount = {
  title: "ACCOUNT",
  id: 'account',
  label: 'Account',
  btnLabel: 'Account',
  svg: 'ticketmanager',
  entries: [
    {
      label: "YOUR AREA",
      btnLabel: "Your Area",
      children: [
        {
          label: "Your Account",
          btnLabel: "Your Account",
          href: "/bm/my-account",
        },
        {
          label: "Your Profile",
          btnLabel: "Your Profile",
          href:"/bm/profile/my-new-profile",
        },
        {
          label: "Wallet",
          btnLabel: "Wallet",
          href:"/bm/my-account/my-wallet",
        },
        {
          label: "Notes",
          btnLabel: "Notes",
          href:"/bm/notes",
        },
        {
          label: "Privacy Settings",
          btnLabel: "Privacy Settings",
          href:"/bm/privacy",
        },
      ]
    },
    {
      label: "BILLING ACCOUNT",
      btnLabel: "Billing",
      children: [
        {
          label: "My Tickets",
          btnLabel: "Tickets",
          href: "/bm/tickets/list",
        },
        {
          label: "Transferred Tickets",
          btnLabel: "Tickets",
          href: "/bm/transferred-tickets/list",
        },
        {
          label: "Orders",
          btnLabel: "Orders",
          href:"/bm/orders/list",
        },
        {
          label: "Invoices",
          btnLabel: "Invoices",
          href:"/bm/invoices/list",
        },
        {
          label: "Billing Profiles",
          btnLabel: "Billing Profiles",
          href:"/bm/my-account/billing-profiles",
        },
      ]
    },
  ]
}

export const sideMenuOpenMock = {
  title: "EVENTS",
  id: 'events',
  label: 'Events',
  btnLabel: 'Events',
  svg: 'stage',
  hero: {
    title: "Event.Name (main attraction)",
    img: "https://brandmindsawsbestack-assetsbuckete5c0e90f-dbw5in6gd3pf.s3.eu-central-1.amazonaws.com/real-event/brand-minds-2022-main-stage.png",
    subtitle: "Event.Dates, Event.VenueLocation",
    description:
      "Event.Short Descripion Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    actions: {
      primary: {
        label: "Buy tickets",
        high:true,
      },
      secondary: {
        secondary: true,
        label: "View Agenda"
      }
    }
  },
  entries: [
    {
      label: "UPCOMING EVENTS",
      btnLabel: "UPCOMING EVENTS",
      // href: "#main",
      children: [
        {
          label: "Brand Minds 2023, London",
          btnLabel: "Brand Minds 2023, London",
          // href: "#main",
          collapse: true,
          children: [
            {
              label: "Sorin's page",
              btnLabel: "Sorin's page",
              href: "/dev/sorin",
            },
            {
              label: "Sessions",
              btnLabel: "Sessions",
              href: "/dev/sorin1",
            },
            {
              label: "Speakers",
              btnLabel: "Speakers",
              href: "/dev/sorin2",
            },
          ]
        },
        {
          label: "Level 2 direct link",
          btnLabel: "Level 2 direct link",
          href: "/dev/sorin",
        },
        {
          label: "Menu Big Item",
          btnLabel: "Menu Big Item",
          collapse: true,
          children: [
            {
              label: "Agenda",
              btnLabel: "Agenda",
              href: "#main",
            },
            {
              label: "Sessions",
              btnLabel: "Sessions",
              href: "#main",
            },
            {
              label: "Speakers",
              btnLabel: "Speakers",
              href: "#main",
            },
          ]
        },
      ]
    },
    {
      label: "Some Direct Link",
      btnLabel: "Some Direct Link",
      href: "/dev/sorin",
    },
    {
      label: "PAST EVENTS",
      btnLabel: "PAST EVENTS",
      href: "#main",
      children: [
        {
          label: "Brand Minds 2023, London",
          btnLabel: "Brand Minds 2023, London",
          href: "#main",
          collapse: true,
          children: [
            {
              label: "Agenda",
              btnLabel: "Agenda",
              href: "#main",
            },
            {
              label: "Sessions",
              btnLabel: "Sessions",
              href: "#main",
            },
            {
              label: "Speakers",
              btnLabel: "Speakers",
              href: "#main",
            },
          ]
        },
        {
          label: "Menu Big Item",
          btnLabel: "Menu Big Item",
          href: "#main",

        },
      ]
    }
  ]
}
