import { createSelector } from 'reselect'
import DashboardPageSelector from 'views/dev/gyula/BrandMinds/pages/dashboard/DashboardPage.selector.js'
import moment from 'moment';
import { devSectionMenuMock, sideMenuAccount, sideMenuHome, sideMenuMindTools, sideMenuNetworking, sideMenuOpenMock } from "./menuMock"
import { adminMenuData } from './adminMenuData';
import { checkUserRole } from 'utils/useUserInfo';

// const dashboardPage = DashboardPageSelector(state)
const menuActiveId = state => state.menu?.menuActiveId
const mainMenuButtons = state => state.menu?.mainMenuButtons
const menuSidePanelOpen = state => state.menu?.menuSidePanelOpen;
const profile = state => state.user?.profile;

const isAdminSection = window.location.pathname?.includes('/admin')
const isDev = window.location?.hostname?.includes("localhost") || (process.env.REACT_APP_STAGE === "DEV")

export const sideMenuSelector = createSelector(
  DashboardPageSelector, menuActiveId, menuSidePanelOpen, mainMenuButtons, profile,
  (dashboardPage, menuActiveId, menuSidePanelOpen, mainMenuButtonsState, profile) => {
    const eventsMenuSection = {
      title: "EVENTS",
      id: 'events',
      label: 'Events',
      btnLabel: 'Events',
      svg: 'stage',
      hero: generateHeroSection(dashboardPage.happeningNow?.[0]),
      entries: sections.filter(section => dashboardPage[section.name]?.length > 0).map(section => generateMenuEntry(section, dashboardPage)),
    }
    
    const {isAdmin, isModerator} = checkUserRole({profile})
    let userMenuData = [
      sideMenuHome,
      eventsMenuSection,
      // sideMenuOpenMock,
      //sideMenuMindTools,
      sideMenuNetworking,
      sideMenuAccount,
    ]
    if (isDev)
      userMenuData.push(devSectionMenuMock)

    const menuData = isAdminSection ? adminMenuData : userMenuData
    
    let mainMenuButtons = []
    if (mainMenuButtonsState?.type === 1) mainMenuButtons = {type: 1, entries: menuData} 
    else mainMenuButtons = mainMenuButtonsState
    const sidePanelData = menuSidePanelOpen && menuData.find(e => e.id === menuActiveId)
  return {
    menuActiveId, 
    mainMenuButtons,
    menuData,
    sidePanelData,
}
  }
)

export default sideMenuSelector;

const sections = [
  {
    name : "happeningSoon",
    label: "HAPPENING SOON"
  },
  {
    name : "upcomingEvents",
    label: "UPCOMING EVENTS"
  },
  {
    name : "happeningNow",
    label: "HAPPENING NOW"
  },
  {
    name : "justMissedEvents",
    label: "JUST MISSED EVENTS"
  },
  {
    name : "previousEvents",
    label: "PREVIOUS EVENTS"
  },
]

const generateHeroSection = event => ({
  title: event?.title,
  img: event?.upPast_M,
  subtitle: event?.title && `${moment(event?.startDate).format('DD MMM YYYY')}, ${event?.venueDetails.name}`,
  //  "Event.Dates, Event.VenueLocation",
  description: event?.description,
  actions: event?.actions,

})
const generateMenuEntry = (section, dashboardPage) => {
  let events = dashboardPage[section.name]
  const pastEvent = section?.name === "previousEvents"

  return  {
    label: section?.label,
    btnLabel: section?.label,
    // href: "#main",
    children: events?.map(event => ({
      label: event?.title,
      btnLabel: event?.title,
      collapse: true,
      children: event?.replayEnabled ? singleEventMenuEntries(event).filter(m => m?.btnLabel !== "Agenda") : (pastEvent ? singleEventMenuEntries(event).filter(m => m?.btnLabel !== "Tickets") : singleEventMenuEntries(event)),
    }))
  }
}

const singleEventMenuEntries = event => ([
  {
    svg:"dashboard",
    label: "Discover the event",
    btnLabel: "Discover",
    href: `/events/${event?.PK}/details`,
  },
  {
    svg:"cart empty",
    label: "Buy Tickets",
    btnLabel: "Tickets",
    href: `/events/${event?.PK}/tickets`,
  },
  {
    svg:"calendar",
    label: "Agenda",
    btnLabel: "Agenda",
    href: `/events/${event?.PK}/agenda`,
  },
  {
    svg:"resell partners",
    label: "Speakers",
    btnLabel: "Speakers",
    href: `/events/${event?.PK}/speakers`,
  },
  {
    svg:"ticket events",
    label: "Partners",
    btnLabel: "Partners",
    href: `/events/${event?.PK}/partners`,
  },
  {
    svg:"location",
    label: "Venue Details",
    btnLabel: "Venue",
    href: `/events/${event?.PK}/venue`,
  },
])
