import SvgIcon from "componentsBM/SvgIcon"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, NavLink, useHistory } from "react-router-dom"
import { mainMenuItemClick, setMenuButtonActive, setMainMenuButtons } from "store/justActions/menuInteractions"
import sideMenuSelector from "./pageSelector"

function MainButtons() {
    const dispatch = useDispatch()
    const history = useHistory()
    const {menuActiveId, mainMenuButtons, menuData} = useSelector(state => sideMenuSelector(state))
    
    useEffect(() => {
        if(!menuActiveId) dispatch(setMenuButtonActive(menuData?.[0]?.id))
        if(!mainMenuButtons) dispatch(setMainMenuButtons({type:1}))
    }, [])

    const level1ButtonClick = item => {
        dispatch(mainMenuItemClick(item))
        if (item?.href) history.push(item.href)
    }
    const backButton = <button
        onClick={() => dispatch(setMainMenuButtons({type:1}))}
        className={`back-button`}
        ><SvgIcon name="menu left"/> Main<br/>Menu
    </button>

    const level1Button = (item, i) => <button key={i} 
    onClick={() => level1ButtonClick(item)}
    className={item?.id === menuActiveId ? 'active' : ''}
    >
        {item?.svg && <SvgIcon name={item?.svg}/>}
        {item?.btnLabel}
    </button>

    const level3Button = (item, i) => <NavLink key={i}
    to = {item?.href}
    className={`level3`} activeClassName="active"
    >
        {item?.svg && <SvgIcon name={item?.svg}/>}
        {item?.btnLabel}
    </NavLink>

return (
        <nav className="menu-main-buttons">
            {mainMenuButtons?.type === 3 && backButton}
            {mainMenuButtons?.entries?.map((item, i) => 
                mainMenuButtons?.type === 3
                    ? level3Button(item, i)
                    : level1Button(item, i)
            )}
        </nav>
    )
}

export default MainButtons


// const buttonParser = (data, level) => ({
//     level,
//     entries: data.map(item => ({
//         id: item.id,
//         label: item.label,
// }))})