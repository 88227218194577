import {userLoggedIn} from 'App'
import { Auth } from "aws-amplify";
import { toastr } from "react-redux-toastr";
// import { getAllProducts } from "./menu";
export const LOGIN_USER = "LOGIN_USER";
export const SET_USER = "SET_USER";
export const SET_ONBOARDING = "auth/SET_ONBOARDING";
export const SET_API_RESPONSE = "auth/SET_API_RESPONSE";
export const SET_AUTHENTICATED = "auth/SET_AUTHENTICATED";
export const REFRESH_TOKENS = "auth/REFRESH_TOKENS";
export const SET_IS_ADMIN = "auth/SET_IS_ADMIN";

const initialState = {
    authenticated: false,
    token: "",
    idToken: "",
    email: "",
    userData: {},
    apiResponse: null,
    isAdmin: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:
            return {
                authenticated: true,
                token: action.payload.AccessToken,
                idToken: action.payload.IdToken,
                refreshToken: action.payload.refreshToken,
                email: action.payload.email,
                userData: action.payload.userData,
            };
        case REFRESH_TOKENS:
             
            return {
                token: action.payload.authToken,
                idToken: action.payload.authToken,
                refreshToken: action.payload.refreshToken,
            };
        case SET_USER:
            return { 
                ...state, 
                userData: action.payload 
            };
        case SET_AUTHENTICATED:
            return { 
                ...state, 
                authenticated: action.payload 
            };
        case SET_API_RESPONSE:
            return { 
                ...state, 
                apiResponse: action.payload 
            };
        case SET_IS_ADMIN:
            return { 
                ...state, 
                isAdmin: action.payload 
            };

    default:
      return state;
  }
}

export const loginUser = (username, password) => {
    return dispatch => {
        // console.log("login action dispatched", username, password);
        // signIn (cognito)
        Auth.signIn(username, password)
            .then(data => {
                // success -- dispatch AUTH_USER
                // console.log("actions.login():Auth.signIn() data:", data);
                const tokenData = {
                    AccessToken: data.signInUserSession.accessToken.jwtToken,
                    IdToken: data.signInUserSession.idToken.jwtToken,
                    email: data.attributes.email,
                };
                const providerName = 'cognito-idp.' + process.env.REACT_APP_USER_REGION + '.amazonaws.com/' + process.env.REACT_APP_USER_POOL_ID
                userLoggedIn(providerName, tokenData.IdToken)
                // localStorage.setItem("user", JSON.stringify(tokenData));
                // const creds = AWS.config.credentials
                // creds.params.Logins = creds.params.Logins || {};
                // creds.params.Logins[providerName] = tokenData.idToken;
                // console.log({creds});            
                // // Expire credentials to refresh them on the next request
                // creds.expired = true;
                dispatch({
                    type: LOGIN_USER,
                    payload: tokenData
                })
            })
            .catch(err => {
                console.error("actions.login():Auth.signIn() err:", err);
                toastr.error([err.code]);
                // error -- invoke authError which dispatches AUTH_ERROR
                // dispatch(authError(err));
            });
    };
};

export const resetPassword = email => {
  return async dispatch => {
    await Auth.forgotPassword(email);
  };
};
export const resetPasswordConfirm = (username, code, password) => {
  return async dispatch => {
    await Auth.forgotPasswordSubmit(username, code, password);
    dispatch(loginUser(username, password));
    // history.push('/')
  };
};
/// DEPRE - CA - TE - D MOFO
// export const loginUser = (username, password) =>
//     apiAction({
//         endPoint: "/user/getToken",
//         data: {
//             id: username,
//             password: password
//         },
//         onSuccess: (data, dispatch) => {
//             localStorage.setItem("user", JSON.stringify(data));
//             // history.push('/dashboard');
//             dispatch(initPage());
//             dispatch(getLabels());
//             dispatch(getManagers());
//             dispatch(getChildrenCompanies());
//             dispatch(getStores());
//             dispatch(getPosList());
//             return {
//                 type: LOGIN_USER,
//                 payload: data
//             };
//         },
//         onFailure: () => {
// console.log("error occured");
//             return {
//                 type: "a"
//             };
//         }
//     });

// export const resetPassword = email =>
//     apiAction({
//         endPoint: "/user/reset",
//         method: "POST",
//         data: {email: email},
//         onSuccess: data => {
//             // console.log("user data", data);
//             // localStorage.setItem("userData", JSON.stringify(data));
//             // // data['custom:onboarding'] ? history.push('/onboarding') : history.push('/dashboard')
//             localStorage.clear('user');
//             return {
//                 type: LOGOUT_USER,
//                 payload: data
//             };
//         },
//         onFailure: () => {
//             console.log("error occured");
//             return {
//                 type: "a"
//             };
//         }
//     });

    

export const logoutUser = () => {
  return {
    type: "LOGOUT_USER"
  };
};


export const clearApiResponse = () => {
    return {
        type: SET_API_RESPONSE,
        payload: null,
    };
};

export const setApiResponse = (data) => {
    return {
        type: SET_API_RESPONSE,
        payload: data,
    };
};

export const setIsAdmin = (data) => {
    return {
        type: SET_IS_ADMIN,
        payload: data,
    };
}

