
const SET_KEY = "menu/SET_KEY";
export const SET_LEFT_MENU_DATA = "menu/SET_LEFT_MENU_DATA";


const initialState = {
  menuActiveId: 'dev',
  menuSidePanel: undefined,
  menuSidePanelOpen: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_KEY:
      return {
        ...state,
        [action.payload?.key]: action.payload?.value,
      };

    case SET_LEFT_MENU_DATA:
      return {
        ...state,
        menuActiveId: action.payload?.menuActiveId,
        menuSidePanel: action.payload?.menuSidePanel,
        menuSidePanelOpen: action.payload?.menuSidePanelOpen,
      };
    default:
      return state;
  }
}


