/* eslint-disable import/no-anonymous-default-export */
import { apiAction, reDispatch } from "store/middleware/api";

export const ADD_CRT_LIST_ITEMS = "page/ADD_CRT_LIST_ITEMS";
export const SET_COLLAPSED_MENU = "page/SET_COLLAPSED_MENU";
export const SET_CRT_LIST_ITEMS = "page/SET_CRT_LIST_ITEMS";
export const SET_LEFT_MENU_ITEMS = "page/SET_LEFT_MENU_ITEMS";
export const SET_PAGE_MODAL_DATA = "page/SET_PAGE_MODAL_DATA";
export const SET_PAGE_MODAL = "page/SET_PAGE_MODAL";
export const SET_PAGE_EDIT = "page/SET_PAGE_EDIT";
export const SET_WIZZARD_STEP = "page/SET_WIZZARD_STEP";
export const RESET_CRT_LIST = "page/RESET_CRT_LIST";
export const SET_CRT_LIST_SEARCH_STRING = "page/SET_CRT_LIST_SEARCH_STRING";
export const SET_CRT_FORM_ITEM = "page/SET_CRT_FORM_ITEM";
export const SET_SCROLL_POSITION = "page/SET_SCROLL_POSITION";
export const SET_ACTION_TO_CONFIRM = "page/SET_ACTION_TO_CONFIRM";
export const SET_TEMP_PRODUCT_FIX = "page/SET_TEMP_PRODUCT_FIX";
export const SET_AUTOCOMPLETE_SUGGESTIONS = "page/SET_AUTOCOMPLETE_SUGGESTIONS";
export const SET_ALL_FILED_ERRORS = "page/SET_ALL_FILED_ERRORS";

export const SET_CUSTOM = "PAGE/SET_CUSTOM";
export const CLEAR_CUSTOM = "PAGE/CLEAR_CUSTOM";
export const SET_PAGE_KEY = "PAGE/SET_PAGE_KEY";
export const SET_LEFT_MENU_DATA = "PAGE/SET_LEFT_MENU_DATA";
export const SET_LOADING_INDICATOR = "page/SET_LOADING_INDICATOR";
export const SET_AS_MEMBER = "page/SET_AS_MEMBER";

const initialcrtList = {
  items: [],
  lastItemHint: null,
  searchString: "",
}
const initialState = {
  collapsedMenu: false,
  isEdit: false,
  crtFormItem: undefined,
  actionToConfirm: undefined,
  modal: undefined,
  modalData: undefined,
  crtList: initialcrtList,
  wizzardStep: 1,
  tempProductFix: {},
  loadingIndicators: {},
  fieldErrors: {},
  custom: {},
  menuActiveId: 'dev',
  menuSidePanel: undefined,
  menuSidePanelOpen: false,
  submitForm: () => console.info("empty submit"),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_KEY:
      return {
        ...state,
        [action.payload?.key]: action.payload?.value,
      };
    case SET_LOADING_INDICATOR:
      return {
        ...state,
        loadingIndicators: { ...state.loadingIndicators, ...action.payload },
      };
    case 'ws/SET_WS_ACTION_COMPLETE':
      return {
        ...state,
        loadingIndicators: { ...state.loadingIndicators, [action?.payload?.action]: false},
      };
    case SET_AS_MEMBER:
      return {
        ...state,
        asMember: action.payload ,
      };
    case SET_TEMP_PRODUCT_FIX:
      return {
        ...state,
        tempProductFix: action.payload,
      };
    case SET_LEFT_MENU_DATA:
      return {
        ...state,
        menuActiveId: action.payload?.menuActiveId,
        menuSidePanel: action.payload?.menuSidePanel,
        menuSidePanelOpen: action.payload?.menuSidePanelOpen,
      };
    case SET_ACTION_TO_CONFIRM:
      return {
        ...state,
        actionToConfirm: action.payload,
        modal: action.payload ? "SYS-confirm-action" : undefined
      };
    case SET_CRT_FORM_ITEM:
      return {
        ...state,
        crtFormItem: action.payload.combine 
          ? {...state.crtFormItem, ...action.payload.data}
          : action.payload.data,
      };
    
    case SET_PAGE_MODAL_DATA:
      return {
        ...state,
        modalData: action.payload,
      };
    case SET_PAGE_EDIT:
      return {
        ...state,
        isEdit: action.payload ?? false,
      };
    case SET_PAGE_MODAL:
      return {
        ...state,
        modal: action.payload,
      };

    case SET_LEFT_MENU_ITEMS:
      return {
        ...state,
        leftMenuItems: action.payload,
      };
    case SET_CRT_LIST_SEARCH_STRING:
      return {
        ...state,
        crtList: {...state.crtList,
          searchString: action.payload,
        }
        };
    case SET_CRT_LIST_ITEMS:
      return {
        ...state,
        crtList: {...state.crtList,
          items: action.payload.items,
          lastItemHint: action.payload.lastItemHint,
        }
      };
    case ADD_CRT_LIST_ITEMS:
      return {
        ...state,
        crtList: {...state.crtList,
          items: [...state.crtList.items, ...action.payload.items],
          lastItemHint: action.payload.lastItemHint,
        }
      };
    case RESET_CRT_LIST:
      return {
        ...state,
        crtList: initialcrtList,
      };
    case SET_COLLAPSED_MENU:
      return {
        ...state,
        collapsedMenu: action.payload,
      };
    case SET_SCROLL_POSITION:
      return {
        ...state,
        setScrollPosition: action.payload,
      };
    case SET_AUTOCOMPLETE_SUGGESTIONS:
      return {
        ...state,
        setAutocompleteSuggestions: action.payload,
      };
    case SET_ALL_FILED_ERRORS:
      return {
        ...state,
        fieldErrors: action.payload,
      };
    case SET_CUSTOM:
      return {
        ...state,
        custom: action.payload
      }
      case CLEAR_CUSTOM:
      return {
        ...state,
        custom: {}
      }
    default:
      return state;
  }
}

export const setLoadingIndicator = (id, state=true) => ({
  type: SET_LOADING_INDICATOR,
  payload: {[id] : state},
})
export const clearLoadingIndicator = (id) => ({
  type: SET_LOADING_INDICATOR,
  payload: {[id] : undefined},
})

export const setAsMember = (data) => ({
  type: SET_AS_MEMBER,
  payload: data,
})
export const setCustomObject = (data) => ({
  type: SET_CUSTOM,
  payload: data,
});
export const clearCustom = () => ({
  type: CLEAR_CUSTOM,
  payload: {}
});

export const setTempProductFix = (data) => ({
  type: SET_TEMP_PRODUCT_FIX,
  payload: data,
})
export const setActionToConfirm = (data) => ({
  type: SET_ACTION_TO_CONFIRM,
  payload: data,
})

export const setPageModal = (data) => ({
  type: SET_PAGE_MODAL,
  payload: data,
})
export const setPageModalData = (data) => ({
  type: SET_PAGE_MODAL_DATA,
  payload: data,
})
export const setLeftMenuItems = (data) => ({
  type: SET_LEFT_MENU_ITEMS,
  payload: data,
})
export const setWizzardStep = (data) => ({
  type: SET_PAGE_KEY,
  payload: {
    key: 'wizzardStep',
    value: data,
  },
})

export const resetCrtList = (data) => ({
  type: RESET_CRT_LIST,
  payload: data,
})

export const setCrtList = (data) => ({
  type: SET_CRT_LIST_ITEMS,
  payload: data,
})


export const setCrtListSearchString = (data) => ({
  type: SET_CRT_LIST_SEARCH_STRING,
  payload: data,
})


export const setCrtFormItem = (data, combine) => ({
  type: SET_CRT_FORM_ITEM,
  payload: {data, combine},
})

export const setPageEdit = (data) => ({
  type: SET_PAGE_EDIT,
  payload: data,
})

export const setCollapsedMenu = (flag) => ({
  type: SET_COLLAPSED_MENU,
  payload: flag,
})

export const setAllFieldErrors = (data) => ({
  type: SET_ALL_FILED_ERRORS,
  payload: data,
});

export const loadCrtListItems = (itemsType) => reDispatch (dispatch =>  {
  if (itemsType === "mockCompanies") dispatch(setCrtList(mockCompanies))
  if (itemsType === "mockProducts") dispatch(setCrtList(mockProducts))
  if (itemsType === "mockUsers") dispatch(setCrtList(mockUsers))
  if (itemsType === "companies") dispatch(searchListStuff({type : 'organization/webshop-client'}))
  return {type:"a"}
})

const img = "https://uniprest.compano.com/Data/Environments/000001/Attachment/Bijlage/Overzicht%20Product%20classifications/light%2087gr.jpg"

const mockUsers = {items:[
  {id: 1,  name: "Andrei George",  subtitle: "Manager Comapnie", },
  {id: 2,  name: "Andrei Iorga",  subtitle: "Manager Comapnie", },
  {id: 3,  name: "Andrei Alexandru",  subtitle: "Manager Comapnie", },
  {id: 11, name: "Georgescu George", subtitle: "Manager Comapnie", },
  {id: 12, name: "Georgescu Iorga", subtitle: "Manager Comapnie", },
  {id: 13, name: "Georgescu Alexandru", subtitle: "Manager Comapnie", },
]}

const mockCompanies = {items:[
  {id: 1,  img, name: "Company name 1",  type:"Large", cui: "RO 111001", tags: ["TagA", "Custom1", "Tag-B"]},
  {id: 2,  img, name: "Company name 2",  type:"Large", cui: "RO 222002", tags: ["TagA", "Custom2", "Tag-B"]},
  {id: 3,  img, name: "Company name 3",  type:"Large", cui: "RO 333003", tags: ["TagA", "Custom3", "Tag-B"]},
  {id: 11, img, name: "Company name 11", type:"Reseller", cui: "RO A11001", tags: ["TagA", "Custom1", "Tag-B"]},
  {id: 12, img, name: "Company name 12", type:"Reseller", cui: "RO A22002", tags: ["TagA", "Custom2", "Tag-B"]},
  {id: 13, img, name: "Company name 13", type:"Reseller", cui: "RO A33003", tags: ["TagA", "Custom3", "Tag-B"]},
]}
const mockProducts = {items:[
  {id: 1,  img, name: "Product Name 1",  type:"Moment Adezivi", cui: "RO 111001", tags: ["TagA", "Custom1", "Tag-B"]},
  {id: 2,  img, name: "Product Name 2",  type:"Moment Adezivi", cui: "RO 222002", tags: ["TagA", "Custom2", "Tag-B"]},
  {id: 3,  img, name: "Product Name 3",  type:"Moment Adezivi", cui: "RO 333003", tags: ["TagA", "Custom3", "Tag-B"]},
  {id: 11, img, name: "Product Name 11", type:"Velux", cui: "RO A11001", tags: ["TagA", "Custom1", "Tag-B"]},
  {id: 12, img, name: "Product Name 12", type:"Velux", cui: "RO A22002", tags: ["TagA", "Custom2", "Tag-B"]},
  {id: 13, img, name: "Product Name 13", type:"Velux", cui: "RO A33003", tags: ["TagA", "Custom3", "Tag-B"]},
]}



export const searchListStuff = ({type = "product", sortAsc = true, sortField = "id", firstRow = 0, pageSize = 25, searchTerm, reload}) => {
  // let state = store.getState();
  let state = undefined;

  return apiAction({
    endPoint: `/${type}/search`,
    method: "POST",
    data: {
      firstRow,
      pageSize,
    },
    onSuccess: (data, dispatch) => {
      // dispatch( setApiResponse(data))
      return {
        type: reload ? SET_CRT_LIST_ITEMS : SET_CRT_LIST_ITEMS,
        payload: {
          items: data,
          lastItemHint: Number(state?.crtList?.lastItemHint) + data.length,
        },
      };
    },
    onFailure: () => {
      console.log("error occured custom");
      return {
        type: "a"
      };
    }
  });
}

export const setScrollPostion = (data) => ({
  type: SET_SCROLL_POSITION,
  payload: data,
})

export const setAutocompleteSuggestions = (data) => ({
  type: SET_AUTOCOMPLETE_SUGGESTIONS,
  payload: data,
})
