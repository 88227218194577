import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActionToConfirm, setPageModal } from "store/modules/page";
import ThemeContext from "../../theme/themeProvider";
import SvgIcon from "../SvgIcon/SvgIcon";
// import "./index.scss";

export default function MagicButton(props) {
  const {
    label, active,
    strong, //for outline makes the text bold
    clear, //makes it transparent
    selected,
    onClick,
    icon,
    svg,
    className,
    h = 6,
    w=0, disabled=false,
    outline,
    px = 2,
    m = 0,
    mt,
    mb,
    badge,
    ml,
    mr,
    pl, pr, pt, pb,
    popRight, popLeft, popBottom,//used for toolTip text
    type = "primary",
    wrapText,
    tl,
    p,
    noFlex, //@TODO: redo logic so we don't need this
    url, //used if you want to go to a link
    dldUrl,
    showModal,
    wc, //with confirmation modal
  } = props;

  let history = useHistory();
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext);
  const baseSpacing = theme.baseSpacing ?? 0.5; // if it's not in theme

  const computedLabel = label ?? (icon ? label : ""); // escaped if there is no label or icon provided

  const paddingLeft = (pl || px || p) ? `${(pl ?? px ?? p) * baseSpacing}rem` : undefined
  const paddingRight = (pr || px || p) ? `${(pl ?? px ?? p) * baseSpacing}rem` : undefined

  let containerStyle = {
    // padding: `${p * baseSpacing}rem`,
    marginTop:  mt === "a" ? `auto` : `${(mt ?? m) * baseSpacing}rem`,
    marginBottom:  mb === "a" ? `auto` : `${(mb ?? m) * baseSpacing}rem`,
    marginLeft: ml === "a" ? `auto` : `${(ml ?? m) * baseSpacing}rem`,
    marginRight:  mr === "a" ? `auto` : `${(mr ?? m) * baseSpacing}rem`,
    paddingTop: `${(pt ) * baseSpacing}rem`,
    paddingBottop: `${(pb ) * baseSpacing}rem`,
    paddingLeft,
    paddingRight,
    height: `${h * baseSpacing}rem`,
    width: computedLabel ? "auto" : `${h * baseSpacing}rem`,
    // flex: '0 0',
  };


  let computedContainerClass = "magic-button-container";
  computedContainerClass += " " + type

  if (!type) computedContainerClass += " buttonContainerMainCTA";
  if (type === "grey-blue") computedContainerClass += " grey blue";
  if (type === "clear-green") computedContainerClass += " simple active-green";
  if (type === "white") computedContainerClass += " white";
  if (type === "black") computedContainerClass += " black";
  if (type === "redAlert") computedContainerClass += " redAlert";

  // if (type === "secondary") computedContainerClass += " buttonContainerSecondary";
  // if (type === "grey") computedContainerClass += " buttonContainerGrey";
  // if (type === "primary") computedContainerClass += " buttonContainerMainCTA";
  // if (type === "green") computedContainerClass += " buttonContainerGreen";
  // if (type === "simple") computedContainerClass += " buttonSimple";

  if (disabled) computedContainerClass += " disabled";
  if (outline) computedContainerClass += " outline";
  if (strong) computedContainerClass += " strong";
  if (clear) computedContainerClass += " clear";

  if (popRight) computedContainerClass += " button-tool-tip-right";
  if (popLeft) computedContainerClass += " button-tool-tip-left";
  if (popBottom) computedContainerClass += " button-tool-tip-bottom";

  if (selected || window.location.pathname === url || active)
    computedContainerClass += " selected";

  // if (!computedLabel) containerStyle.flex = `0 0 ${h * baseSpacing}rem`;
  // if (computedLabel) containerStyle.flex = `1`;
  if (!wrapText ) containerStyle.whiteSpace = "break-spaces";
  if (tl) containerStyle.textAlign = "left";
  if (w) containerStyle.width = `${w * baseSpacing}rem`;

  if (w == 100) {
    containerStyle.flex='1 0'
    containerStyle.width='100%'

  }
  const imgStyle = {
    display: "flex",
    flex: `0 0 ${h * baseSpacing}rem`,
    height: `${h * baseSpacing}rem`,
    width: `${h * baseSpacing}rem`
  };
  if (noFlex) containerStyle.flex = undefined;
  let computedOnClick = undefined;

  if (url && window.location.pathname !== url && !active)
    computedOnClick = () => history.push(url);
    if (dldUrl) {
      const downloadFromUrl = (url, name) => {
        const link = document.createElement("a");
        link.download = name;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      computedOnClick = () => downloadFromUrl(dldUrl, 'Test');
    }
  if (onClick) computedOnClick = onClick;
  if (showModal) computedOnClick = () => dispatch(setPageModal(showModal));
  //this has to be the last one
  if (wc) computedOnClick = () => dispatch(setActionToConfirm(() => computedOnClick()))

  let buttonProps = {
    tooltip: popLeft ?? popRight ?? popBottom,
    style: containerStyle,
    className: `${computedContainerClass} ${className ?? ""}`,
    onClick: !disabled
      ? e => {computedOnClick(); e.stopPropagation()}
      : undefined
    };
  return (
    <button {...buttonProps}>
      {icon && <img style={imgStyle} src={icon} alt="icon" />}
      {svg && <SvgIcon name={svg} />}
      {computedLabel && <div className="label">{computedLabel}</div>}
      {badge && <div className="badge">{badge}</div> }
    </button>
  );
}
