import bmOrdersTableData from 'views/dev/gyula/BrandMinds/tables/ordersListTable/bmOrdersTableData';
import bmTicketsTableData from 'views/dev/gyula/BrandMinds/tables/ticketListTable/ticketTableData';
import bmInvoicesTableData from 'views/dev/gyula/BrandMinds/tables/invoicesListTable/invoicesTableData'
import bmInvitationsTableData from 'views/dev/gyula/BrandMinds/tables/invitationsListTable/invitationsTableData';
// import orderTableData from "mockData/orderTableData"
// import facturesTableData from "mockData/facturesTableData"
import { setDataItems } from "../dataView"
import { SET_ITEMS } from "../dataView"
import { getMyConnections } from 'store/apiCalls/networkApiCalls';

const { default: store } = require("store")
const { reDispatch,dynamoAction } = require("store/middleware/api")
const { searchStuff, loadDynamoItemsBySK, searchBmStuff } = require("../dataView.search")

const directMatchApi = [
  "uniprest/group", // Lista de roluri - 2.2
  "uniprest/employee", // Angajati Uniprest - 2.3
  "product/goods/discount/fix", // Preturi nete nete - 4.4
  "product/goods/discount/volume", // Discounturi de volum - 4.5
  "product/goods/discount/package", // Pachete - 4.6
  "product-question", // Intrebari si raspunsuri - 4.9
  "product-rating", // Recenzii - 4.10
  "product/goods/discount/grid", // Grila de discounturi - 4.3
  "product-document", // Documente technice - 4.11
  "goods-badge", // Badges - 4.8
  "organization/webshop-client", // Lista de companii - 3.1
  "uniprest/user", // Lista de utilizatori - 2.1
  "organization/warehouse", // Depozite Uniprest - 4.12
  "stock", // Detalii depozite - 4.12.1
  "product/goods", // Lista de produse Compano 4.2
  "basket", // Lista de comenzi 5.1
  "basket/my-basket", //guest - my orders
  "invoice/my-invoice", //client: my invoices
  "client-invoice/my", //client: my invoices
  "client-invoice", // Lista de facturi - 5.3
  "location-delivery", // Localitati si detalii de livrare - 4.13.1
  "organization/webshop-client/limit", // Detalii factura - /admin/order/invoice/form
  "user", // Lista de utilizatori table's form
  
]

const directMatchDynamo = [
  "GUEST_ADD_LIST", // Liste Predefinite - 6.1
  "GUEST_ARTICLE_V2", // Stiri - 7.1
  "LOCATION_RESPONSE", // Locatii Uniprest - 7.5
  "JOB_RESPONSE", // Joburi disponibile - 7.2
  "CONTACT_RESPONSE", // Formular de contact - 7.4
  "SIGNUP_RESPONSE", // Cont Uniprest - Solicitari - 3.2
  "APLICANT_RESPONSE", // Aplicanti - 7.3
]

export const loadViewData = (props) => reDispatch((dispatch) => {
  const viewId = store.getState()?.dataView?.viewId
  // API - TOTAL table data from API: 20
  if (directMatchApi.includes(viewId)) dispatch(searchStuff({ ...props, type: viewId }))

  if (viewId === "bm-users-my-tickets") dispatch(searchBmStuff({ ...props, iType : "TICKET", type: "OWNER"}))
  if (viewId === "bm-users-tranferred-tickets") dispatch(searchBmStuff({ ...props, iType : "TICKET", type: "TRANSFFERED" }))
  if (viewId === "MEMBERS_PAGE") dispatch(searchBmStuff({ ...props, pageSize:10, iType : "USER_PROFILE" }))
  if (viewId === "MY_CONNECTIONS_PAGE") dispatch(getMyConnections({ dataView : true }))
  if (viewId === "SPEAKERS_PAGE") dispatch(searchBmStuff({ ...props, pageSize:200, iType : "SPEAKER" }))
  if (viewId === "PUSH_NOTIFICATION") dispatch(searchBmStuff({ ...props, pageSize:200, iType : "PUSH_NOTIFICATION" }))
  if (viewId === "ADMIN_USERS_PAGE") dispatch(searchBmStuff({ ...props, pageSize:200, iType : "ADMIN_USERS", admin: true }))
  //if (viewId === "ADMIN_ORDERS_PAGE") dispatch(searchBmStuff({ ...props, pageSize:200, iType : "ORDER" }))
  if (viewId === "ADMIN_TICKETS_PAGE") dispatch(searchBmStuff({ ...props, pageSize:200, iType : "TICKET" }))
  if (viewId === "ADMIN_EVENTS_PAGE") dispatch(searchBmStuff({ ...props, pageSize:200, iType : "EVENTS", admin: true }))
  if (viewId === "ADMIN_LIVE_PAGE") dispatch(searchBmStuff({ ...props, pageSize:200, iType : "EVENTS", admin: true, })) //demo workaround
  // if (viewId === "ADMIN_LIVE_PAGE") dispatch(searchBmStuff({ ...props, pageSize:200, iType : "EVENTS", admin: true, type: "LIVE" }))
  if (viewId === "ADMIN_SESSIONS_PAGE") dispatch(searchBmStuff({ ...props, pageSize:200, iType : "SESSION_TEMPLATE" }))
  if (viewId === "ADMIN_PARTNERS_PAGE") dispatch(searchBmStuff({ ...props, pageSize:200, iType : "SPONSOR_PROFILE" }))
  // DYNAMO - TOTAL table data from Dynamo: 7
  if (directMatchDynamo.includes(viewId)) dispatch(loadDynamoItemsBySK({ SK: viewId }))

  
  // MOCK - TOTAL table mock data: 4
  if (viewId === 'mock-bmCompanyListData') dispatch(setDataItems(bmOrdersTableData.contents));
  if (viewId === 'mock-bmTicketListData') dispatch(setDataItems(bmTicketsTableData.contents));
  if (viewId === 'mock-bmInvoicesListData') dispatch(setDataItems(bmInvoicesTableData.contents));
  if (viewId === 'mock-bmInvitationsListData') dispatch(setDataItems(bmInvitationsTableData.contents))

  return { type: "a" }
})

export const loadNewsData = () => dynamoAction({
  method: "QUERY",

  parameters: {
    TableName: "TempDevStack-MainTable-74SE74RNUI3D",
    KeyConditionExpression: "#pk = :pkvalue",
    ExpressionAttributeNames: {
      "#pk": "PK"
    },
    ExpressionAttributeValues: {
      ":pkvalue": { S: "ARTICLE" }
    }
  },

  onSuccess: (data, dispatch) => {
    return {
      type: SET_ITEMS,
      payload: data
    }
  },
    onFailure: (err) => {
    console.log(err)
    return {
      type: "a"
    }
}})
